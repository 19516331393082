import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";

export type CommentPaperProps = {
  children?: React.ReactNode;
  title: string;
};

export const Page = (props: CommentPaperProps) => {
  return (
    <>
      <Helmet>
        <title>{`${props.title} | WeTain`}</title>
      </Helmet>

      <Box>{props.children}</Box>
    </>
  );
};
