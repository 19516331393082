import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {DndProvider} from "react-dnd";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {HTML5Backend} from "react-dnd-html5-backend";

import {
    Box,
    Grid,
} from "@mui/material";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction';
import EmployeeDetails from "../../../pages/EmployeeDetailsPopover";
import {EmployeeData} from "../../../services/types";
import {populateChildren} from "../../../helper/organigram";
import Node from "../../../components/organigram/Node";
import {HierarchicalOrganigrammProps, MapState} from "./types";

export default function HierarchicalOrganigram(props: HierarchicalOrganigrammProps) {

    const [mapState, setMapState] = React.useState<MapState>({
        scale: 1,
        translation: { x: 0, y: 0 }
    })
    const checkSetMapState = (val: {scale: number, translation: { x: number; y: number; }}) => {
        if(val.scale > 0.3){
            setMapState(val)
        }
    }

    const [detailsOpen, setDetailsOpen] = React.useState(false);

    const [detailsEmployeeData, setDetailsEmployeeData] = React.useState<EmployeeData>(
        props.hierarchy[Object.keys(props.hierarchy)[0]]
    );

    const [org, setOrg] = React.useState(
        populateChildren("company", props.hierarchy)
    );

    React.useEffect(() => {
        setOrg(populateChildren("company", props.hierarchy));
    }, [props.hierarchy])

    return (
        <div>
            <MapInteractionCSS
                value={mapState}
                onChange={(value: MapState) => checkSetMapState(value)}
            >
                <Box bgcolor="background" padding={4} height="80vh">
                    <DndProvider backend={HTML5Backend}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                        }}>
                            <Grid item>
                                <Node
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    /* @ts-ignore*/
                                    o={org}
                                    setMapState={setMapState}
                                    mapState={mapState}
                                    setDetailsEmployeeData={setDetailsEmployeeData}
                                    setDetailsOpen={setDetailsOpen}
                                    showTriggerIcons={props.showTriggerIcons}
                                />
                            </Grid>
                        </div>
                    </DndProvider>
                </Box>
            </MapInteractionCSS>
            <EmployeeDetails
                isOpen={detailsOpen}
                setModalWindow={setDetailsOpen}
                employeeData={detailsEmployeeData}
                setData={setDetailsEmployeeData}
                handleSetMustStay={props.handleSetMustStay(detailsEmployeeData)}
            />
        </div>
    );
}
