import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {MaiLContent} from "../../../services/types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

type ActionMailPopUp = {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    mailContent:  MaiLContent,
    setTaskStatus: (value: "open" | "pending" | "done") => void;
}

export default function ActionMailPopUp(props: ActionMailPopUp) {

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography gutterBottom display={"inline"} fontSize={16}>
                        Mail To:
                    </Typography>
                    <Typography gutterBottom display={"inline"} fontSize={18} paddingLeft={2}>
                        {props.mailContent.recipient}
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom display={"inline"} fontSize={12}>
                        Subject:
                    </Typography>
                    <Typography gutterBottom display={"inline"} fontSize={14} paddingLeft={2}>
                        {props.mailContent.subject}
                    </Typography>
                </DialogContent>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Hello {props.mailContent.recipient},
                    </Typography>
                    <Typography gutterBottom>
                        {props.mailContent.body}
                    </Typography>
                    <Typography>
                        Best,
                    </Typography>
                    <Typography gutterBottom>
                        Me
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={
                        () => {
                            handleClose();
                            props.setTaskStatus("pending")
                        }
                    }>
                        Send
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}