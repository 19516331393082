import {Grid, Typography} from "@mui/material";
import {EmployeeData} from "../../../services/types";
import BarChartNegative from "../../../components/chart/BarChartNegative";
import {createBarChartNegativeSeries} from "../../../helper/insights";
import {ApexOptions} from "apexcharts";

export type InsightProps = {
    employeeData: EmployeeData,
}

export default function Insights(props: InsightProps) {

    const spine_chart_options: ApexOptions = {
        // colors: ["#0323f8"],
        dataLabels: {
            enabled: false,
        },
        title: {
            text:"Impact on Forecast for " + props.employeeData.name,
            margin: 0
        },
        xaxis: {
            categories: [
                "compensation absolute - (33.365)",
                "1 - compensation/(avg(peer)) - (1.23)",
                "holidays this year - (33)",
                "days since last holiday - (54)",
                "Last Feedback score (88)",
                "Feedback/avg(peer-feedback) - (1,12)",
            ],
            title: {
                text: 'Percent'
            },
            labels: {
                formatter: function (val: string) {
                    return Math.round(Number(val)) + "%"
                }
            }
        },
        yaxis: {
            labels: {
                formatter(val: number): string | string[] {
                    const res = []
                    let lastBreak = 0;
                    for(let i = 0; i < val.toString().length; i++){
                        if(i % 10 == 0){
                            res.push(val.toString().slice(lastBreak, i))
                            lastBreak = i
                        }
                    }
                    res.push(val.toString().slice(lastBreak, val.toString().length))
                    return res
                }
            }
        }
    };

    const series = createBarChartNegativeSeries([0.4, 0.65, 0.76, -0.88, -1.5, -2.1])

    return(
        <Grid item xs={12} md={12} lg={12}>
            <Typography
                variant="h4"
                gutterBottom
                style={{
                    color: "#000000",
                    marginLeft: 20,
                    fontSize: 17,
                    fontWeight: 700,
                }}
            >
                Recommended Actions
            </Typography>
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ float: "left", padding: 2, width: "95%" }}
            >
                <BarChartNegative
                    title="Historical score"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    options={spine_chart_options}
                    height={500}
                    width={680}
                    series={series}
                />
            </Grid>
        </Grid>
    )
}