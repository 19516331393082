import React from "react";
import {setDataProcessingType} from "../redux/actions/dataProcessingTypeAction";
import {useDispatch} from "react-redux";
import {Grid, Typography} from "@mui/material";
import Switch from "@mui/material/Switch";

export type DataProcessingTypeSwitchProps = {
    dataProcessingType: "trigger" | "ai",
    setDataProcessingType:  React.Dispatch<React.SetStateAction<"ai" | "trigger">>;
}

export default function (props: DataProcessingTypeSwitchProps) {

    const dispatch = useDispatch();

    const dataProcessingTitle = () => {
        if(props.dataProcessingType == "ai"){
            return "AI";
        }
        return "Trigger";
    }

    const handleDataProcessingTypeSwitch = () => {
        let newMode: "ai" | "trigger";
        if(props.dataProcessingType == "ai"){
            newMode = "trigger"
        } else {
            newMode = "ai";
        }

        dispatch(setDataProcessingType({type: newMode}))
        props.setDataProcessingType(newMode)

    }

    return (
        <Grid container>
            <Grid item xs={3}>
                <Switch defaultChecked onClick={handleDataProcessingTypeSwitch}/>
            </Grid>
            <Grid item xs={9}>
                <Typography
                    fontSize={14}
                    sx={{
                        marginTop: 1.3,
                        marginLeft: 2
                    }}
                >{dataProcessingTitle()}</Typography>
            </Grid>
        </Grid>
    )
}