import { EmployeeData } from "../services/types";
import EmployeeTable from "../sections/EmployeeTable";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FilterOptions, {filterOptionCards} from "./FilterOptions";
import { styled } from "@mui/material/styles";
import { UserListToolbar } from "../sections/dashboard/employee";
import {useDispatch, useSelector} from "react-redux";
import {
  SearchResultViewReduxState
} from "../redux/reducers";
import { MainTableState } from "../redux/reducers/mainTableReducer";
import LabeledSwitch from "../components/LabeledSwitch";
import {SearchResultViewState} from "../redux/reducers/searchResultViewReducer";
import {setSearchResultView} from "../redux/actions/searchResultViewActions";
import ResultCardsView from "../sections/dashboard/employee/ResultCardsView";
import amplitude from "../helper/amplitude";
import BasicDropDownMenu from "../components/BasicDropDownMenu";
import {FilterOption} from "../sections/dashboard/filterOptions/types";
import {getFilterOptions, getFilterOptionsType} from "../helper/filter";

export type EmployeePageProps = {
  employeeSelector?: (employee: EmployeeData[]) => EmployeeData[];
  name: string;
  tableState: MainTableState
};

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

export default function TableView(props: EmployeePageProps) {

  const filterOptions: FilterOption[] = getFilterOptions(
      getFilterOptionsType(props.name)
  );

  const [filterOptionsOpen, setFilterOptionsOpen] = React.useState(false);

  const [tableView, setTableView] = React.useState<boolean>(
      useSelector<SearchResultViewReduxState, SearchResultViewState>(
      (state: SearchResultViewReduxState) => state.searchResultView
      ).tableView
  )

  const handleSwitchView = () => {
    amplitude().track(`Set Table View To ${!tableView}`)
    setTableView(!tableView);
    useDispatch()(setSearchResultView({tableView: !tableView}));
  }

  const [filterOptionsCards, setFilterOptionCards] = React.useState<Array<JSX.Element | undefined>>();

  const [hierarchyLevel, setHierarchyLevel] = React.useState<
      string
  >("employees");

  const handleFilterClick = () => {
    amplitude().track("Opened Filter Option Popover")
    setFilterOptionsOpen(!filterOptionsOpen);
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const filterOptionsCardsState = filterOptions.map((filterOption) => {
      return <Grid item xs={12} md={4} mb={0}>
        {filterOptionCards
            .find((x) => x.key == filterOption.key)
            ?.component({
              employeeData: props.tableState.employees,
            })}
      </Grid>
    })!
    setFilterOptionCards(filterOptionsCardsState);
  })

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Container style={{paddingTop: 20}}>
          <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
          >
            <Grid container spacing={2}>
              {filterOptionsCards}
            </Grid>
          </Stack>
        </Container>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{
                color: "#0323f8",
                marginLeft: 20,
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              {props.name}
            </Typography>
            <RootStyle
              sx={{
                ...{
                  color: "primary.main",
                  bgcolor: "primary.lighter",
                },
              }}
            >
              <BasicDropDownMenu
                  name={"level"}
                  menuItems={["employees", "departments", "sub departments"]}
                  setSelectedValueFunction={setHierarchyLevel}
                  sx={{width: 200}}
              />
              <Button
                variant="text"
                sx={{ minWidth: 120 }}
                onClick={handleFilterClick}
              >
                {"Filter (" + filterOptions.length + ")"}
              </Button>
              {
                props.employeeSelector ?
                    <LabeledSwitch
                        label={tableView ? "Card View" : "Table View"}
                        onClick={handleSwitchView}
                        defaultValue={tableView}
                    /> : null
              }

            </RootStyle>
            {/* <EnhancedTableToolbar numSelected={selected.length} />
                <BasicSelect
                    setSelectedValueFunction={setQueryKey}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    menuItems={{
                        name: "Name",
                        department: "Department",
                        role: "Role",
                        reportingLine: "Reporting Line",
                    }}
                />*/}
            <UserListToolbar filterOptionsConfigs={filterOptions} />
          </Stack>
          {tableView ? (props.tableState.employees.length > 0 && <EmployeeTable
              employeeSelector={props.employeeSelector}
              filterOptions={filterOptions}
              hierarchyLevel={hierarchyLevel as "employees" | "departments" | "sub departments"}
          />) : (
              <ResultCardsView
                  employeeSelector={props.employeeSelector}
                  filterOptions={filterOptions}
              />
          )}
        </Container>
        <FilterOptions
          isOpen={filterOptionsOpen}
          setModalWindow={setFilterOptionsOpen}
          filterOptionConfigs={filterOptions}
          callerName={props.name}
        />
      </Paper>
    </Box>
  );
}