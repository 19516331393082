import {useSelector} from "react-redux";
import {
    FilterOptionsReduxState,
    FilterOptionsTypeReduxState,
    MustStayFilterOptionsReduxState,
} from "../redux/reducers";
import {FilterOptionsState, MustStayFilterOptionsState} from "../redux/reducers/filterOptionsReducer";
import {FilterOption} from "../sections/dashboard/filterOptions/types";
import {FilterOptionsTypeState} from "../redux/reducers/filterOptionsTypeReducer";
import {setFilterOptions, setMustStayFilterOptions} from "../redux/actions/filterOptionActions";
import {AnyAction, Dispatch} from "redux";

export const getFilterOptions = (filterOptionType?: (string | undefined)): FilterOption[] => {
    if(filterOptionType === undefined){
        filterOptionType = useSelector<FilterOptionsTypeReduxState, FilterOptionsTypeState>(
            (state: FilterOptionsTypeReduxState) => state.filterOptionsType
        ).filterOptionsType
    }

    if(filterOptionType == "main"){
        return useSelector<FilterOptionsReduxState, FilterOptionsState>(
            (state: FilterOptionsReduxState) => state.filterOptions
        ).data;
    }
    if(filterOptionType == "mustStay"){
        return useSelector<MustStayFilterOptionsReduxState, MustStayFilterOptionsState>(
            (state: MustStayFilterOptionsReduxState) => state.mustStayFilterOptions
        ).data;
    }

    return [];

}

export const setReduxFilterOptions = (
    filterOptions: FilterOption[],
    filterOptionType: ("mustStay" | "main"),
    dispatch: Dispatch<AnyAction>
) => {

    if(filterOptionType == "main"){
        dispatch(setFilterOptions(filterOptions))
    }
    if(filterOptionType == "mustStay"){
        dispatch(setMustStayFilterOptions(filterOptions))
    }
}

export const getFilterOptionsType = (name: string): ("main" | "mustStay") => {
    let setValue: ("main" | "mustStay");
    if(name == "All Employees") {
        setValue = "main";
    } else {
        setValue = "mustStay";
    }
    return setValue;
}