import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const inviteEndpoint = "/invite";

export type InviteData = {
    id: string;
    email: string;
    status: "open" | "accepted" | "closed";
    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;
};

export type InvitePostBody = {
    project_id: string;
    target_email: string;
};

export const getInvites = async (): Promise<InviteData[]> => {
    const res = await http.get(`${apiEndpoint}${inviteEndpoint}`);
    return res.data["projects"];
};

export const createInvite = async (
    invitePostBody: InvitePostBody
): Promise<InviteData> => {
    const res = await http.post(
        `${apiEndpoint}${inviteEndpoint}`,
        invitePostBody
    );
    return res.data;
};