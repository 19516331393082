import * as React from "react";
import amplitude from "../../../helper/amplitude";
import {SortingOrder} from "../../../interfaces/interface.sortingOrder";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import EnhancedTableHead, {PossibleTableHeadColumns} from "../employee/EnhancedTableHead";
import {HeadCell} from "../types";
import {applySort, getComparator} from "../../../helper/employeeTable";
import {TriggerOptionsState} from "../../../redux/reducers/triggerOptionsReducer";
import {TriggerCardData} from "./Overview";
import {useSelector} from "react-redux";
import {TriggerOptionsReduxState} from "../../../redux/reducers";
import {icons} from "../../../components/TriggerIcon";
import {
    getActionBackgroundColorByStatus,
    getBorderColorByScore
} from "../../../helper/colors";



const headCells: HeadCell[] = [
    // {
    //     id: "kpi",
    //     disablePadding: true,
    //     label: "KPI",
    //     numeric: false,
    // },
    // { id: "icon", disablePadding: true, label: "Icon", numeric: false },
    { id: "name", disablePadding: true, label: "name", numeric: false },
    { id: "value", disablePadding: true, label: "actual", numeric: false },
    { id: "trigger", disablePadding: true, label: "triggers at", numeric: false },
    // { id: "delta", disablePadding: true, label: "delta", numeric: false },
    // { id: "impact", disablePadding: true, label: "Impact", numeric: false },
];

type ActionTableProps = {
    triggers: TriggerCardData[],
    // setTriggers: (arg0: TriggerCardData) => void,
    onClick: (arg0: string) => void,
}

export default function TriggerTable(props: ActionTableProps){

    const triggerOptionsState = useSelector<TriggerOptionsReduxState, TriggerOptionsState>(
        (state: TriggerOptionsReduxState) => state.triggerOptions
    )

    let triggers =
        props.triggers.map(
            trigger => {
                const icon = icons.filter(icon => icon.dataKey == trigger.key)[0];
                return {
                    ...trigger,
                    icon: icon.icon,
                    trigger: triggerOptionsState[trigger.key].red,
                    delta: trigger.value - triggerOptionsState[trigger.key].red,
                }
            }
        );

    const [order, setOrder] = React.useState<SortingOrder>("asc");

    const [orderBy, setOrderBy] =
        React.useState<PossibleTableHeadColumns>("icon");

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: PossibleTableHeadColumns
    ) => {
        amplitude().track(`Set Employee Table Order by ${property}`);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        triggers = applySort(
            triggers,
            getComparator(order, orderBy),
        ) as unknown as TriggerCardData;
    }, [order, orderBy])

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-labelledby="actionTable">
                    <EnhancedTableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <div style={{margin: 1}}/>
                    <TableBody>
                        {
                            triggers.map(trigger => {
                                return (
                                    <TableRow
                                        hover={true}
                                        tabIndex={-1}
                                        role="checkbox"
                                        sx={{backgroundColor: getActionBackgroundColorByStatus(
                                            trigger.delta > 0 ? "open" : "done")
                                        }}
                                    >
                                        <TableCell align="left">{
                                            <trigger.icon sx={{
                                                color: (
                                                    trigger.delta > 0 ?
                                                        getBorderColorByScore(0) :
                                                        getBorderColorByScore(100)
                                                )
                                            }}/>
                                        }</TableCell>
                                        <TableCell align="left">{trigger.name}</TableCell>
                                        <TableCell align="left">{trigger.value} {trigger.metric}</TableCell>
                                        <TableCell align="left">{trigger.trigger} {trigger.metric}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}