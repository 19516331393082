import NumericFilterOptionCard from "../../../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import { RangeSliderProps } from "../../../components/filter/RangeSlider";
import React from "react";
import {FilterOptionCardProps} from "./types";

export default function LossFilterOptionCard(props: FilterOptionCardProps) {
  const rangeSliderProps: RangeSliderProps = {
    marks: [
      {
        value: 20,
        label: "20",
      },
      {
        value: 40,
        label: "40",
      },
      {
        value: 60,
        label: "60",
      },
    ],
    employeeData: props.employeeData,
    tableKey: "projectedMonetaryLoss"
  };
  return (
    <NumericFilterOptionCard
      key="lossFilterOptionCard"
      name="Projected Loss"
      tableKey={"projectedMonetaryLoss"}
      rangeSliderProps={rangeSliderProps}
      employeeData={props.employeeData}
    />
  );
}
