import {SET_COMMENTS} from "../constants/actionTypes";
import {Comments} from "../../services/commentDataService";

export type CommentAction = {
    type: string;
    payload: Comments;
};

export const setComments = (
    comments: Comments
): CommentAction => {
    return {
        type: SET_COMMENTS,
        payload: comments ,
    };
};
