// icons
import { Icon } from "@iconify/react";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export interface Iconifyprop {
  icon: string;
  sx: object;
}

const Iconify: React.FC<Iconifyprop> = (props) => {
  return <Box component={Icon} icon={props.icon} sx={props.sx} />;
};

export default Iconify;
