import {Avatar, Grid} from "@mui/material";
import {Comment} from "../../../services/commentDataService";

export default function CommentEntry(comment: Comment){
    return (
        <Grid container wrap="nowrap" spacing={2} sx={{ background: "#f9f9fa" }}>
            <Grid item>
                <Avatar alt="Remy Sharp" src={comment.avatarUrl} />
            </Grid>
            <Grid justifyContent="left" item xs zeroMinWidth>
                <h4 style={{ margin: 0, textAlign: "left" }}>{comment.author}</h4>
                <p style={{ textAlign: "left" }}>
                    {comment.content}
                </p>
                <p style={{ textAlign: "left", color: "gray" }}>
                    {comment.postedOn}
                </p>
            </Grid>
        </Grid>
    )
}