import {EmployeeData, MonetaryLossData} from "../services/types";
import {faker} from "@faker-js/faker";
import {mean, meanBy, min, sample, sumBy} from "lodash";
import {numberFormatter} from "./charts";
import {ActionData} from "./types";
import {getTriggerStatus} from "../services/employeeDataService";
import {initialState, TriggerOptionsState} from "../redux/reducers/triggerOptionsReducer";

export const getHistChartData = (props: {
  length: number;
  min: number;
  max: number;
}): number[] => {
  const arr = [];
  let i: number;
  for (i = props.length; i >= 1; i--) {
    arr.push(faker.datatype.number({ min: props.min, max: props.max }));
  }
  return arr;
};

export const getHistoricalMean = (employee: EmployeeData) => {
  const arr = [
    employee.compensationScoreHistory,
    employee.engagementScoreHistory,
    employee.peerGroupScoreHistory,
    employee.feedbackScoreHistory,
    employee.personalDevelopmentScoreHistory,
    employee.socialsScoreHistory,
  ];
  const result = [];
  for (let i = 0; i <= arr.length; i++) {
    let indexArr: number[] = [];
    arr.forEach((hist) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      indexArr.push(hist.at(i)!);
    });
    result.push(mean(indexArr));
    indexArr = [];
  }
  return result;
};

export const getMinMax = (): { min: number; max: number } => {
  const minimum = faker.datatype.number({ min: 10, max: 100 });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const maximum = min([minimum + 10, 100])!;
  return { min: minimum, max: maximum };
};

export const getDistributionByDepartment = (employeeData: EmployeeData[]): EmployeeData[] => {
  const uniqueDepartments = [...new Set(employeeData.map(emp => emp.department))]
  let distributedEmployeeData: EmployeeData[] = [];
  const distributions = {
    "IT": {
      "green": 3,
      "yellow": 4,
      "red": 30,
      "mustStay": 7
    },
    "Sales": {
      "green": 2,
      "yellow": 5,
      "red": 30,
      "mustStay": 7
    },
    "Marketing": {
      "green": 5,
      "yellow": 10,
      "red": 15,
      "mustStay": 7
    },
    "HR": {
      "green": 20,
      "yellow": 2,
      "red": 0,
      "mustStay": 7
    },
    "Finance": {
      "green": 5,
      "yellow": 10,
      "red": 15,
      "mustStay": 7
    },
  }
  uniqueDepartments.forEach(dep => {
    const depEmps = employeeData.filter(emp => emp.department == dep);
    distributedEmployeeData = distributedEmployeeData.concat(
        getDistribution({
          employeeData: depEmps,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...distributions[dep]
        })
    )
  })
  return distributedEmployeeData;
}

export const getDistribution = (props: {
  employeeData: EmployeeData[];
  mustStay: number;
  red: number;
  green: number;
  yellow: number;
}): EmployeeData[] => {
  const wanted: EmployeeData[] = [];
  const addMustStay = (employee: EmployeeData): EmployeeData | undefined => {
    if (employee.mustStay) {
      employee.mustStayInfo = `${employee.name}'s knowledge is very important for the team!`
      if (props.mustStay > 0) {
        props.mustStay -= 1;
        return employee;
      }
      return;
    }
    return employee;
  };
  const mockEmployeeTriggersToStatus = (emp: EmployeeData, minStatus: number, maxStatus: number): EmployeeData => {
    let status = getTriggerStatus(emp, initialState)
    while(((status > minStatus) && (status <= maxStatus))){
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const key = sample(Object.keys(initialState))!
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      emp[key] = initialState[key as keyof TriggerOptionsState].red + 1

      status = getTriggerStatus(emp, initialState)
    }
    return emp
  }
  const addStatus = (employee: EmployeeData): EmployeeData | undefined => {
    if (employee.status > 75) {
      if (props.green > 0) {
        employee = mockEmployeeTriggersToStatus(employee, 75, 100)
        props.green -= 1;
        return employee;
      }
      return;
    }
    if (employee.status <= 50) {
      if (props.red > 0) {
        employee = mockEmployeeTriggersToStatus(employee, 0, 50)
        props.red -= 1;
        return employee;
      }
      return;
    }
    if (props.yellow > 0) {
      employee = mockEmployeeTriggersToStatus(employee, 50, 75)
      props.yellow -= 1;
      return employee;
    }
    return;
  };

  props.employeeData.forEach((item) => {
    if (addMustStay(item)) {
      const candidate = addStatus(item);
      if (candidate) {
        wanted.push(candidate);
      }
    }
  });
  wanted.forEach((item, index) => {
    item.avatarUrl = `/static/mock-images/avatars/avatar_${index % 24}.jpg`;
  });
  return wanted;
};

export const removeNameAddOns = (name: string): string => {
  const remove = [
      "Dr. ",
    "Mr. ",
    "Mrs. ",
    "Ms. ",
    " PhD",
    " I",
    " II",
      " III",
      " IV",
      " V",
      " VI",
      " VII",
      " VIII",
      " IX",
      " X",
    " DVM",
    "Miss ",
    " Jr.",
    " DDS",
    " Sr.",
  ]
  remove.forEach(candidate => {
    name = name.replace(candidate, "")
  })
  if(name.at(name.length - 1) == "I"){
    name = name.slice(0, name.length - 2)
  }
  return name
}

export const getStatusScore = (employee: EmployeeData): number => {
  return (
    (employee.compensationScore +
      employee.personalDevelopmentScore +
      employee.engagementScore +
      employee.feedbackScore +
      employee.socialsScore +
      employee.peerGroupScore) /
    6
  );
};

export const getStatusFromScore = (score: number) => {
  if (score > 75) {
    return "active";
  }
  if (score < 50) {
    return "inactive";
  }
  return "paused";
};

const getActionCost = (cost: number) => {
  if(cost > 300 ){
    return cost;
  }
  return faker.datatype.number({min: 305, max: 500})
}

export const getRecommendedActions = (employeeData: EmployeeData): Array<ActionData> => {
  let scores = [
    {"title": "compensationScore", "score": employeeData.compensationScore},
    {"title": "engagementScore", "score": employeeData.engagementScore},
    {"title": "personalDevelopmentScore", "score": employeeData.personalDevelopmentScore},
    {"title": "feedbackScore", "score": employeeData.feedbackScore},
    {"title": "socialScore", "score": employeeData.socialsScore},
    {"title": "peerGroupScore", "score": employeeData.peerGroupScore}
  ]
  scores = scores.sort((a, b) => a.score - b.score).slice(0, 6)
  // const actionTitles: {[key: string]: string} = {
  //   compensationScore: `Increase salary by ${numberFormatter(employeeData.monetaryLossData.replacement * 0.01).split(".")[0]}$/month`,
  //   engagementScore: `Propose a meeting to discuss the absence at team events`,
  //   personalDevelopmentScore: `Assign ${employeeData.name} to a new project, and discuss a good fit`,
  //   feedbackScore: `Propose a meeting to discuss ${employeeData.name}'s leaders behaviour`,
  //   socialScore: `Ask for the social circumstances`,
  //   peerGroupScore: `Check the distribution of ownership and appreciation within ${employeeData.name}'s peer group`,
  // }
  const newActionTitles: {[key: string]: string[]} = {
    compensationScore: [
        `${employeeData.name}'s salary is 34% less than the average of her peers. Please make sure to consider a salary increase for the next compensation round unless it is due to poor performance.`,
        `The average market compensation for Tax Accountants with 4 years of experience is 22% higher than ${employeeData.name}'s salary. Please speak to her HR manager to check if a raise is possible or desired.`,
        `${employeeData.name}'s commission bonus dropped 62% compared to last year. Please discuss with her manager if her targets are achievable or if the decrease is due to market conditions or personal reasons.`,
    ],
    engagementScore: [
        `We've noticed that ${employeeData.name} is adding on average 14 overtime hours per week. Please discuss with her manager if her workload can be reduced or if she needs trainings.`,
        `We've noticed that ${employeeData.name} has not used 40% of her personal time off last year. Make sure this is not due to her workload and encourage her to take some time off.`,
    ],
    personalDevelopmentScore: [
        `We've noticed that there has been a significant rise in terminations under ${employeeData.name}'s manager. Make sure you find out why this happened and gather feedbacks from the remaining employees.`,
        `We've noticed that ${employeeData.name} has a new manager since April. Please check in with her to he/see if she is happy with her new position.`,
        `Over the last 12 months 72% of ${employeeData.name}'s peers have been promoted to a more senior position. On average Tax Accountants get promoted every 24 months, while Gail has not been promoted for 63 months. Please check if this is due to poor performance or other factors.`,
    ],
    feedbackScore: [
      `Propose a meeting to discuss ${employeeData.name}'s leaders behaviour`,
    ],
    socialScore: [
        `${employeeData.name}'s average job tenure was 19 months in her previous roles. She has now been with us for 13 months. We suggest a meeting with her manager Brittany McDermott to discuss her future perspectives within this company.`,
        `${employeeData.name} is 22 years younger than the average in her team. Please check if she feels comfortable in her work environment and if not, consider assigning her to a new team.`,
    ],
    peerGroupScore: [
        `70% of ${employeeData.name}'s team have left the company in the last year and were replaced with new colleagues. Please check if she feels comfortable in her work environment and if not, consider assigning her to a new team.`,
        `2 employees ${employeeData.name} has worked with over a decade have left the company in the last 6 months. We suggest a meeting with her manager Brittany McDermott to discuss her future perspectives within this company and to make sure this has not impacted ${employeeData.name}'s wellbeing.`,
        `${employeeData.name} is the only woman in a team of 15 men. Please discuss with her if she feels represented or if she needs a female mentor. We suggest to recruit or promote more female candidates into her position in the future.`,
    ]
  }
  const retentionIncrease = 0.3;
  const actionImpact: {[key: string]: number} = {
    compensationScore: (100 - (employeeData.compensationScore))* retentionIncrease,
    engagementScore: (100 - (employeeData.engagementScore))* retentionIncrease,
    personalDevelopmentScore: (100 - (employeeData.personalDevelopmentScore))* retentionIncrease,
    feedbackScore: (100 - (employeeData.feedbackScore))* retentionIncrease,
    socialScore: (100 - (employeeData.socialsScore))* retentionIncrease,
    peerGroupScore: (100 - (employeeData.peerGroupScore))* retentionIncrease,
  }
  const costIncrease = 10;
  const actionCosts: {[key: string]: number} = {
    compensationScore: getActionCost((100 - (employeeData.compensationScore)) * costIncrease),
    // engagementScore: getActionCost((100 - (employeeData.engagementScore))* costIncrease),
    personalDevelopmentScore: getActionCost((100 - (employeeData.personalDevelopmentScore)) * costIncrease),
    // feedbackScore: getActionCost((100 - (employeeData.feedbackScore))* costIncrease),
    // socialScore: getActionCost((100 - (employeeData.socialsScore))* costIncrease),
    // peerGroupScore: getActionCost((100 - (employeeData.peerGroupScore))* costIncrease),
  }
  const mailContent: {[key: string]: string} = {
    compensationScore: `Could you increase ${employeeData.name}'s salary 
      by ${numberFormatter(employeeData.monetaryLossData.replacement * 0.02).split(".")[0]}$/month?`,
    engagementScore: `I wanted to propose a meeting to discuss your absence at team events. \n When would be a good time for you?`,
    personalDevelopmentScore: `Please, assign ${employeeData.name} to a new project, and discuss a good fit. \n
      His personal development is stagnating.`,
    feedbackScore: `I wanted to propose a meeting to discuss the leading style towards ${employeeData.name}. \n
      I have got the feeling there are some potentials to be discovered. \n
      When would be a good time for you?`,
    socialScore: `I wanted to ask for a meeting to discuss ${employeeData.name}'s current social circumstances. \n
      When would be a good time for you?`,
    peerGroupScore: `please check the distribution of ownership and appreciation within ${employeeData.name}'s peer group. \n
      ${employeeData.name} seems to be a little unsatisfied with the current situation.`,
  }
  // const mailTo: {[key: string]: string} = {
  //   compensationScore: employeeData.reportingLine,
  //   engagementScore: employeeData.name,
  //   personalDevelopmentScore: employeeData.name,
  //   feedbackScore: employeeData.reportingLine,
  //   socialScore: employeeData.name,
  //   peerGroupScore: employeeData.reportingLine,
  // }
  const subject: {[key: string]: string} = {
    compensationScore: `${employeeData.name}'s salary increase`,
    engagementScore: `${employeeData.name}'s engagement`,
    personalDevelopmentScore: `${employeeData.name}'s personal development`,
    feedbackScore: `${employeeData.name}'s latest feedback`,
    socialScore: `${employeeData.name}'s social obstacles`,
    peerGroupScore: `${employeeData.name}'s peer group`,
  }

  return scores.map(score => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      title: sample(newActionTitles[score.title])!,
      impact: actionImpact[score.title],
      kpi: score.title,
      costs: actionCosts[score.title],
      mailContent: {
        recipient: employeeData.reportingLine,
        subject: subject[score.title],
        body: mailContent[score.title]
      },
      status: "open"
    }
  })
}

export const musStaySelector = (
  mustStay: boolean
): ((employees: EmployeeData[]) => EmployeeData[]) => {
  return (employees: EmployeeData[]) => {
    const wanted: EmployeeData[] = [];
    employees.forEach((employee: EmployeeData) => {
      if (employee.mustStay === mustStay) {
        wanted.push(employee);
      }
    });
    return wanted;
  };
};

const subDepartments = {
  IT: ["Data Science", "Development", "Support"],
  Sales: ["Inbound", "Outbound"],
  Marketing: ["Branding", "Competition"],
  Finance: ["Bookkeeping", "Financial Reporting", "Tax And Compliance"],
  HR: ["Recruiting", "Payroll", "Learning And Development"],
}

const roles = {
  IT: {
    DataScience: ["Consultant", "Programmer"],
    Development: ["DevOps Expert", "Cloud Architect"],
    Support: ["SAP Specialist", "API Specialist"],
  },
  Sales: {
    Inbound: ["Lead Generation", "Lead Qualification", "CRM Manager"],
    Outbound: ["Sales Manager", "Sales Manager", "Sales Support"],
  },
  Marketing: {
    Branding: ["SEO & Content Marketing", "Social Media"],
    Competition: ["Analyst", "CRM Manager"],
  },
  Finance: {
    Bookkeeping: ["Accountant", "payable & receivable clerk"],
    FinancialReporting: ["Financial Accountant", "Controller"],
    TaxAndCompliance: ["Tax Accountant", "Compliance Manager"],
  },
  HR: {
    Recruiting: ["Recruiter", "HR Assistant"],
    Payroll: ["Payroll clerk", "Payroll Accountant"],
    LearningAndDevelopment: ["Learning coordinator", "Learning and development assistant"],
  }
}

const createHierarchyLevel = (
    levelEmps: EmployeeData[],
    name: string,
    reportingLine: string,
    avatarUrl: string
): EmployeeData => {
  const dep: {[key: string]: string | number | boolean | number[] | MonetaryLossData} = {};
  Object.keys(levelEmps[levelEmps.length - 1]).forEach((key: string) => {
    if (typeof levelEmps[levelEmps.length - 1][key as keyof EmployeeData] === "number") {
      if (key == "projectedMonetaryLoss") {
        dep[key] = sumBy(levelEmps, key)
      } else {
        dep[key] = numberFormatter(
            meanBy(levelEmps, key)
        )
      }
    } else {
      if (Array.isArray(levelEmps[levelEmps.length - 1][key as keyof EmployeeData])) {
        const meanArray: Array<number> = [];
        (levelEmps[0][key as keyof EmployeeData] as Array<number>).forEach((_, index) => {
          meanArray.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              mean(levelEmps.map(emp => emp[key as keyof EmployeeData][index]))
          )
        })

        dep[key] = meanArray;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dep[key] = levelEmps[levelEmps.length - 1][key as keyof EmployeeData]
      }
    }
  })

  dep["name"] = name
  dep["reportingLine"] = reportingLine
  dep["role"] = "";
  dep["avatarUrl"] = avatarUrl;
  const monLoss = {...levelEmps[0].monetaryLossData};
  Object.keys(monLoss).forEach(monLossKey => {
    monLoss[monLossKey as keyof MonetaryLossData] = sumBy(
        levelEmps.map(emp => emp.monetaryLossData),
        monLossKey
    )
  })

  dep["monetaryLossData"] = monLoss;
  return dep as unknown as EmployeeData;
}

const createSubHierarchyLevel = (
    levelEmps: EmployeeData[],
    subKey: keyof EmployeeData
): EmployeeData[] => {
  const subLevels = new Array(...new Set(levelEmps.map(emp => emp[subKey] as string)))
  const subLevelObjects: EmployeeData[] = [] as unknown as EmployeeData[];
  subLevels.forEach(subLevel => {
    const subLevelEmps: EmployeeData[] = levelEmps.filter(levelEmp => levelEmp[subKey] === subLevel)
    if(subLevel.length > 0){
      subLevelObjects.push(
          createHierarchyLevel(
              subLevelEmps,
              subLevel,
              subLevelEmps[subLevelEmps.length - 1].department,
              "/static/mock-images/avatars/group.png"
          )
      )
    }
  })
  return subLevelObjects;
}

const createSubDepHierarchy = (employeeData: EmployeeData[], headOf: string): EmployeeData[] => {
  let depEmps: EmployeeData[] = []
  const uniqueSubDepartments = [...new Set(employeeData.map(emp => emp.subDepartment))]
  uniqueSubDepartments.forEach(subDep => {
    const subDepEmps = employeeData.filter(emp => emp.subDepartment == subDep);
    subDepEmps[0].reportingLine = subDep;
    subDepEmps.slice(1, subDepEmps.length).forEach((emp, index) => {
      if(index < subDepEmps.length / 4){
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        emp.reportingLine = headOf
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        emp.reportingLine = subDepEmps[index % 4].name
      }

    })
    depEmps = subDepEmps.concat(depEmps)
  })
  return depEmps
}

export const createHierarchy = (employeeData: EmployeeData[]): {
  employees: EmployeeData[];
  departments: EmployeeData[];
  subDepartments: EmployeeData[];
} => {
  const uniqueDepartments = [...new Set(employeeData.map(emp => emp.department))]
  let convertedEmployees: Array<EmployeeData> = [];
  const convertedDeps: Array<EmployeeData> = [];
  let convertedSubDeps: Array<EmployeeData> = [];

  uniqueDepartments.forEach(dep => {
    let depEmps = employeeData.filter(emp => emp.department == dep);
    const depNames = depEmps.map(emp => emp.name);
    depEmps.slice(1, depEmps.length).forEach((emp, index) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      emp.reportingLine = depNames[Number(((index % 4)*Number(index/4)).toFixed())]
    })
    // const depEmpByName: { [name: string]: EmployeeData } = {}
    depEmps.slice(1, depEmps.length).forEach(depEmp => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      depEmp.subDepartment = sample(subDepartments[dep])!.toString()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      depEmp.role = sample(roles[dep][depEmp.subDepartment.replaceAll(" ", "")])!
      // depEmpByName[depEmp.name] = depEmp;
    })
    depEmps = depEmps.slice(0,1).concat(
        createSubDepHierarchy(depEmps.slice(1, depEmps.length), depEmps[0].name)
    )

    depEmps[0].reportingLine = "company";
    depEmps[0].role = "Head of " + dep;
    depEmps[0].subDepartment = "";
    convertedEmployees = convertedEmployees.concat(depEmps)
    convertedDeps.push(
        createHierarchyLevel(
            depEmps,
            dep,
            "company",
            "/static/mock-images/avatars/group.png",
        )
    )
    convertedSubDeps = convertedSubDeps.concat(
        createSubHierarchyLevel(depEmps, "subDepartment")
    )
  })
  return {
    employees: convertedEmployees,
    departments: convertedDeps,
    subDepartments: convertedSubDeps
  };
}