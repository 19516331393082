import {Box, List, Typography} from "@mui/material";
import SliderListItem, {SliderListItemProps} from "../../../layouts/dashboard/triggerConfig/SliderListItem";
import * as React from "react";

export type SliderSetProps = {
    title: string,
    triggers: SliderListItemProps[],
}

export default function SliderSet(props: SliderSetProps){

    return(
        <Box sx={{border: 1, borderRadius: 3, padding: 5, marginBottom: 4}}>
            <Typography
                variant="h4"
                gutterBottom
                style={{
                    color: "#0323f8",
                    marginLeft: 20,
                    fontWeight: 600,
                    fontSize: 18,
                }}
            >
                {props.title}
            </Typography>
            <List>
                {
                    props.triggers.map(trigger => {
                        return <SliderListItem {...trigger} valueText={trigger.valueText}/>
                    })
                }
            </List>
        </Box>
    )
}