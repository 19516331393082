import {SET_FILTER_OPTIONS, SET_MUST_STAY_FILTER_OPTIONS} from "../constants/actionTypes";
import { FilterOptionsState } from "../reducers/filterOptionsReducer";
import {FilterOption} from "../../sections/dashboard/filterOptions/types";

export type FilterOptionsAction = {
  type: string;
  payload: FilterOptionsState;
};

export const setFilterOptions = (
  filterOptions: FilterOption[]
): FilterOptionsAction => {
  return {
    type: SET_FILTER_OPTIONS,
    payload: { data: filterOptions },
  };
};

export const setMustStayFilterOptions = (
    filterOptions: FilterOption[]
): FilterOptionsAction => {
  return {
    type: SET_MUST_STAY_FILTER_OPTIONS,
    payload: { data: filterOptions },
  };
};

