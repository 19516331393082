export const createBarChartNegativeSeries = (arr: Array<number>): ApexAxisChartSeries | ApexNonAxisChartSeries => {
    const negative: Array<number | null> = [];
    const positive: Array<number | null> = [];

    arr = arr.sort((a, b) => a -b);

    arr.forEach(num => {
        if(num < 0){
            negative.push(num);
            positive.push(null);
        } else {
            positive.push(num);
            negative.push(null);
        }
    })
    return [
        {
            name: "positive",
            data: positive,
        },
        {
            name: "negative",
            data: negative,
        }
    ]
}