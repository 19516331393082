import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserReduxState} from "../redux/reducers";
import {UserState} from "../redux/reducers/authUserReducer";
import {refreshToken, setAuthToken} from "../services/authService";
import {CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {loginUser} from "../redux/actions/authUserActions";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userState = useSelector<UserReduxState, UserState>((state) => state.authUser);

    const [authenticated, setAuthenticated] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const checkAuth = async () => {
        if (!userState.isAuthenticated) {
            if (userState.refreshToken) {
                try {
                    setLoading(true);
                    await refreshToken();
                    await setAuthToken();
                    dispatch(loginUser(userState));
                    setAuthenticated(true);
                } catch (e) {
                    setAuthenticated(false);
                }
            }
            setAuthenticated(false);
        } else {
            setAuthenticated(true);
        }
        setLoading(false);
    }

    React.useEffect( () => {
        checkAuth();
    })

    if(loading){
        return <CircularProgress/>
    }
    if(authenticated){
        return children
    } else {
        navigate("/login", { replace: true });
        return null
    }
};

export default RequireAuth;