import {Box} from "@mui/material";
import TeamLeadCard from "./TeamLeadCard";
import {TeamLeadCardProps} from "./types";
import {getBackgroundColorByScore, getBorderColorByScore} from "../../../helper/colors";


export default function LeadCard(props: TeamLeadCardProps) {

    if((props.org.type === undefined) || (props.org.type == "TeamLead") ){
        return (<TeamLeadCard {...props}/>)
    }

    return (
        <Box
            // variant="outlined"
            // className={classes.root}
            // style={{backgroundColor}}
            sx={{
                border: 5,
                paddingTop: 1,
                paddingRight: 1,
                paddingLeft: 1,
                backgroundColor:  getBackgroundColorByScore(props.org.status),  // getBorderColorByScore(props.org.status),
                borderRadius: 5,
                borderColor: getBorderColorByScore(props.org.status)
            }}
        >
            <TeamLeadCard {...props} borderColor={getBackgroundColorByScore(props.org.status)}/>
        </Box>
    )
}