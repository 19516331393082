import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const userEndpoint = "/user";

export type UserPostBody = {
    display_name: string;
    email: string;
    password: string
};

export const createUser = async (userPostBody: UserPostBody) => {
    await http.post(`${apiEndpoint}${userEndpoint}`, userPostBody);
};