import { styled } from "@mui/material/styles";
import { Toolbar, OutlinedInput, InputAdornment } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { NumericFilterOptionListConfig } from "../../../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import { StringFilterOptionListConfig } from "../../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import { EmployeeData } from "../../../services/types";
import * as React from "react";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500]} !important`,
  },
}));

type UserListToolbarType = {
  filterOptionsConfigs: Array<
    | StringFilterOptionListConfig
    | NumericFilterOptionListConfig
    | StringQueryFilterOptionConfig
  >;
};

export type StringQueryFilterOptionConfig = {
  queryString: string;
  key: keyof EmployeeData;
};

export default function UserListToolbar(props: UserListToolbarType) {
  const [filterName, setFilterName] = React.useState("");

  const setFilterOptionsConfig = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event;
    props.filterOptionsConfigs.forEach((item, index: number) => {
      if (item.key === "name") {
        props.filterOptionsConfigs.splice(index, 1);
      }
    });
    props.filterOptionsConfigs.push({ key: "name", queryString: value });
    setFilterName(value);
  };

  return (
    <RootStyle>
      <SearchStyle
        value={filterName}
        onChange={setFilterOptionsConfig}
        placeholder="Search user..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: "text.disabled", width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
