import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useDrag} from "react-dnd";
import {Avatar, Card, CardHeader, Typography} from "@mui/material";
import {getBorderColorByScore} from "../../../helper/colors";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {TeamMemberCardProps} from "./types";
import {useStyles} from "./styles";
import TriggerIcons from "../../../components/organigram/TriggerIcons";

export default function TeamMemberCard(props: TeamMemberCardProps) {
    const classes = useStyles();
    const [{ isDragging }, drag] = useDrag({
        item: { name: props.name, type: "account" },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                alert(`You moved ${item.name} to ${dropResult}`);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        type: "account"
    });
    const backgroundColor = getBorderColorByScore(props.status)
    const opacity = isDragging ? 0.4 : 1;
    return (
        <Card
            variant="outlined"
            className={classes.root}
            ref={drag}
            sx={{
                border: 5,
                borderColor: backgroundColor,
            }}
            style={{cursor: "pointer", opacity: opacity}}
        >
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar} src={props.avatarUrl}>
                        <AccountBalanceIcon color="secondary"/>
                    </Avatar>
                }
                title={props.name}
                onClick={() => {
                    if(!["department", "subDepartment"].includes(String(props.type))){
                        props.setEmployeeDetails(props);
                        props.setDetailsOpen(true)
                    }
                }}
                sx={{marginBottom: 0, marginRight: 2.9}}
            />
            {props.role ? <Typography fontSize={12} marginBottom={2} marginLeft={0.5} marginRight={0.5}>{props.role}</Typography> : null}
            {/*numberFormatter(props.status)*/}
            {props.showTriggerIcons ? <TriggerIcons {...props}/> : null}
        </Card>
    );
}
