import {makeStyles} from "@mui/styles";


export const useStyles = makeStyles(() => ({
    root: {
        background: "white",
        display: "inline-block",
        borderRadius: 16
    },
    expand: {
        transform: "rotate(0deg)",
        marginTop: -10,
        marginLeft: "auto",
        // transition: theme.transitions.create("transform", {
        //     duration: theme.transitions.duration.short
        // })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    avatar: {
        backgroundColor: "#ECECF4"
    }
}));