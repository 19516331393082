import { MainTableDoc } from "../../services/types";

export type MainTableAction = {
  type: string;
  payload: MainTableDoc;
};

export const setMainTable = (mainTable: MainTableDoc) => {
  return {
    type: "LOADED_MAIN_TABLE",
    payload: mainTable,
  };
};
