
import {SET_COMMENTS} from "../constants/actionTypes";
import {Comments} from "../../services/commentDataService";
import {CommentAction} from "../actions/commentsActions";


export const initialState: Comments = {};

// ==============================|| PROJECT REDUCER ||============================== //
const commentsReducer = (
    state: Comments = initialState,
    action: CommentAction
): Comments => {
    switch (action.type) {
        case SET_COMMENTS: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default commentsReducer;
