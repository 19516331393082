import {SET_FILTER_OPTIONS_TYPE} from "../constants/actionTypes";
import {FilterOptionsTypeAction} from "../actions/filterOptionsTypeActions";

export type FilterOptionsTypeState = {
    filterOptionsType: "main" | "mustStay"
}

export const initialState: FilterOptionsTypeState = {
    filterOptionsType: "main"
};

// ==============================|| FILTER OPTION TYPE REDUCER ||============================== //
const filterOptionsTypeReducer = (
    state: FilterOptionsTypeState = initialState,
    action: FilterOptionsTypeAction
): FilterOptionsTypeState => {
    switch (action.type) {
        case SET_FILTER_OPTIONS_TYPE: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

export default filterOptionsTypeReducer;