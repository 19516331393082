import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {LabeledSwitchProps} from "./types";

export default function LabeledSwitch(props: LabeledSwitchProps) {
    const control = (
        <Switch defaultChecked={props.defaultValue}/>
    )
    return (
        <FormGroup>
            <FormControlLabel control={control} label={props.label} onClick={props.onClick}/>
        </FormGroup>
    );
}
