import {Grid, Container} from "@mui/material";
import { Page } from "../components/Page";
import {max} from "lodash";
import { numberFormatter } from "../helper/charts";
import { ApexOptions } from "apexcharts";
import { xaxisOptions } from "../services/lineCharts";
import { useSelector } from "react-redux";
import { MainTableReduxState } from "../redux/reducers";
import { MainTableState } from "../redux/reducers/mainTableReducer";
import ScoreCard from "../sections/dashboard/dashboard/ScoreCard";
import StatusCards from "../sections/dashboard/dashboard/StatusCards";

export default function DashboardApp() {
  const employeeData = useSelector<MainTableReduxState, MainTableState>(
    (state: MainTableReduxState) => state.mainTable
  ).employees;
  if (!employeeData.length) {
    return <></>;
  }
  /*
      red: 25,
    green: 55,
    yellow: 20,
  */

  const score_series: ApexAxisChartSeries = [
    {
      name: "WeTain Score",
      data: [53, 58, 62, 55, 66, 70, 73].map(value => {
        return value
      }),
    },
  ];
  const loss_series: ApexAxisChartSeries = [
    {
      name: "Avoidable Loss",
      data: [53, 56, 60, 55, 62, 65, 68].map(
        (num) => (Number(num) * -1 + 100) * 100000
      ),
    },
  ];
  const options: ApexOptions = {
    chart: {
      height: 330,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    markers: {
      size: 0,
    },
    xaxis: xaxisOptions,
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    yaxis: {
      labels: {
        formatter: numberFormatter,
      },
      min: 0,
      logBase: 1,
      tickAmount: 5,
      forceNiceScale: false,
    },
  };

  const score_options = { colors: ["#0323f8"], ...options };
  score_options.yaxis = { ...score_options.yaxis, max: 100 };
  score_options.annotations = {
    yaxis: [
      {
        y: 60,
        borderColor: "#007de3",
        opacity: 1,
        strokeDashArray: 10,
        width: "200%",
        label: {
          borderColor: "#007de3",
          style: {
            color: "#fff",
            background: "#007de3",
          },
          textAnchor: "start",
          text: "Industry average",
          position: "left",
        },
      },
    ],
  };
  const loss_options = { colors: ["#f80369"], ...options };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  loss_options.yaxis = {
    ...loss_options.yaxis,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...{ forceNiceScale: true, max: max(loss_series.at(0).data) },
  };


  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl">
          <StatusCards></StatusCards>
          <Grid container spacing={3}>
            {/*<Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "#0323f8",
                  marginLeft: 20,
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Action Needed
              </Typography>
            </Grid>
            {employeeData.filter(emp => emp.mustStay).slice(0, 4).map((employee) => {
              return <EmployeeCard {...employee} />;
            })}*/}
            <Grid item xs={12} md={6} lg={6}>
              <ScoreCard
                  title={"Avoidable Turnover Costs"}
                  valueTitle={
                    numberFormatter(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        loss_series[0]
                            .data[loss_series[0].data.length - 1]
                            .toString()
                    )
                  }
                  chartSeries={loss_series}
                  chartOptions={loss_options}
                  lineColor={"#f80369"}
                  backgroundTransitionColor={"#f80369"}
                  accentColor={"rgba(248,3,170,0.22)"}
                  maxY={max(loss_series[0].data.map(num => Number(num)))}
                  forecastData={[
                    {
                      lower: {
                        close: 2000000,
                        date: xaxisOptions.categories[xaxisOptions.categories.length - 1]
                      },
                      upper: {
                        close: 4000000,
                        date: xaxisOptions.categories[xaxisOptions.categories.length - 1]
                      }
                    }]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ScoreCard
                  title={"Company Retention Score"}
                  valueTitle={
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    score_series[0]
                      .data[score_series[0].data.length - 1]
                      .toString() + "%"}
                  chartSeries={score_series}
                  chartOptions={score_options}
                  backgroundTransitionColor={'rgba(6,125,194,0.38)'}
                  lineColor={"#0323f8"}
                  forecastData={[
                    {
                      lower: {
                        close: 50,
                        date: xaxisOptions.categories[xaxisOptions.categories.length - 1]
                      },
                      upper: {
                        close: 80,
                        date: xaxisOptions.categories[xaxisOptions.categories.length - 1]
                      }
                    }]}
                  baseline={60}
                  baselineName={"Industry Average"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {/*<AppEmployeesUpdate
              title="Update Employees"
              list={[...Array(4)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
              subheader=""
              other={[]}
            />*/}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
