import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {moneyFormatter} from "../../../helper/charts";

export type MonetaryLossCardProps = {
  series: {
    investedTrainings: number;
    absence: number;
    replacement: number;
  };
};

export default function MonetaryLossCard(props: MonetaryLossCardProps) {
  const series = [
    props.series.investedTrainings,
    props.series.absence,
    props.series.replacement,
  ];
  const options: ApexOptions = {
    legend: {
      position: "bottom",
    },
    labels: ["development costs", "absence costs", "replacement costs"],
    chart: {
      type: "donut",
      height: "100%",
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        customScale: 1,
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: {
              show: true,
            },
            value: {
              show: true,
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: (seriesName) => {
            return seriesName
          },
        },
        formatter: (value) => {
          return moneyFormatter(value)

        },
      },
    },
    colors: ["#f50559", "#fa9ea1", "#fa0000"],
    title: {
      text:
        "Monetary Loss: ~" +
        moneyFormatter(props.series.absence +
          props.series.replacement +
          props.series.investedTrainings
        ),
      //   margin: 40,
      // align: "center"
    },
    theme: {
      monochrome: {
        enabled: false,
        color: "#fa0000",
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="donut" />;
}
