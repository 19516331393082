import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export type PermissionQuery = {
    project_id: string;
}

export type Permission = {
    user_id: string,
    added_by: string,
    added_at: string,
    updated_at: string,
    user_info: object,
    admin: boolean,
    read: boolean,
    write: boolean,
    delete: boolean,
    update: boolean,
}

export const getPermissions = async (
    permissionQueryParams: PermissionQuery
): Promise<Array<Permission>> => {
    const res = await http.get(`${apiEndpoint}/permission`, {
        params: permissionQueryParams,
    });
    return res.data["permissions"];
};