import { createStore } from "redux";
import reducers from "../reducers";

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(
  reducers
  //{},
  //window.__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const persister = "Free";

export { store, persister };
