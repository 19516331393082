import * as React from "react";
import amplitude from "../../../helper/amplitude";
import {SortingOrder} from "../../../interfaces/interface.sortingOrder";
import {Avatar, Button, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import EnhancedTableHead, {PossibleTableHeadColumns} from "../employee/EnhancedTableHead";
import {HeadCell} from "../types";
import {applySort, getComparator} from "../../../helper/employeeTable";
import {Permission} from "../../../services/permissionService";



const headCells: HeadCell[] = [
    { id: "user_id", disablePadding: true, label: "name", numeric: false },
    { id: "added_at", disablePadding: true, label: "added_at", numeric: false },
    { id: "admin", disablePadding: true, label: "admin", numeric: false },
    // { id: "permissions", disablePadding: true, label: "permissions", numeric: false },
];

export type TeamMemberData = {
    id: string;
    name: string;
    created_at: string;
    is_admin: boolean;
    read: boolean;
    write: boolean;
    update: boolean;
    delete: boolean;
}

type TeamTableProps = {
    teamMembers: Permission[],
}

export default function TeamTable(props: TeamTableProps){

    const [order, setOrder] = React.useState<SortingOrder>("asc");

    const [orderBy, setOrderBy] =
        React.useState<PossibleTableHeadColumns>("icon");

    const [teamMembers, setTeamMembers] = React.useState(props.teamMembers);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: PossibleTableHeadColumns
    ) => {
        amplitude().track(`Set Team Table Order by ${property}`);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    React.useEffect(() => {
        setTeamMembers(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            applySort(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                teamMembers,
                getComparator(order, orderBy),
            )
        )
    }, [order, orderBy])

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-labelledby="actionTable">
                    <EnhancedTableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <div style={{margin: 1}}/>
                    <TableBody>
                        {
                            teamMembers.map(teamMember => {
                                return (
                                    <TableRow
                                        hover={true}
                                        tabIndex={-1}
                                        role="checkbox"
                                    >
                                        <TableCell align="left">
                                            <Avatar alt={teamMember.user_id} src={"/static/mock-images/avatars/avatar_default.jpg"} />
                                        </TableCell>
                                        <TableCell align="left">{teamMember.user_id}</TableCell>
                                        <TableCell align="left">{teamMember.added_at}</TableCell>
                                        <TableCell align="left">{teamMember.admin?.toString()}</TableCell>
                                        <TableCell align="left">
                                            <Button sx={{marginLeft: -1}}>permissions</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}