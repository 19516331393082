import {countChildren, HierarchicalEmployeeData, Hierarchy} from "./hierarchy";
import {LeadType, TeamLeadType, TeamMemberType} from "../layouts/dashboard/organigram/types";
import {EmployeeData} from "../services/types";
import {mean, meanBy, sum} from "lodash";
import {triggerTitles} from "../constants/triggerConfig/triggerInfo";

export const populateChildren = (empId: string, hierarchy: Hierarchy): LeadType | TeamMemberType => {
    const emp = hierarchy[empId];
    if(emp.subEmployees.length === 0){
        return {
            product: {
                ...emp
            },
            ...emp
        }
    } else {
        const children: Array<TeamLeadType | TeamMemberType> = emp.subEmployees.map(subId => {
            return populateChildren(subId, hierarchy)
        })
        const collapsed = ["TeamLead", "department", "subDepartment"].includes(String(emp.type));

        return {
            leadingPositionChildRelationship: children.filter(child => (child as TeamLeadType).leadingPositionChildRelationship !== undefined).map(item => item as TeamLeadType),
            account: children.filter(child => (child as TeamLeadType).leadingPositionChildRelationship === undefined).map(item => item as TeamMemberType),
            ...emp,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            collapsed: collapsed,
            type: emp.type
        }
    }
}

export const getAllStatusses = (leadIds: string[], hierarchy: Hierarchy): Array<number> => {
    let result: Array<number> = leadIds.map(leadId => hierarchy[leadId].status);
    leadIds.forEach(leadId => {
        getAllStatusses(hierarchy[leadId].subEmployees, hierarchy).forEach(
                res => {
                    result = result.concat(res)
                }
            )
    });
    return result;
}

export const prepTeams = (subDepEmps: EmployeeData[], hierarchy: Hierarchy): Hierarchy => {
    const subDep = subDepEmps[0].subDepartment;
    const directSubEmployees = subDepEmps.filter(
        emp => (emp.reportingLine == hierarchy[subDepEmps[0].department + "_" +subDep].reportingLine)
            && (emp.subDepartment == subDep)
    )
    const teamIds: Array<string> = [];
    directSubEmployees.map((teamLeadEmp, index) => {

        const teamId = subDep + "_" + index;
        let statusMean = mean(getAllStatusses([teamLeadEmp.id], hierarchy))
        if(isNaN(statusMean)){
            statusMean = teamLeadEmp.status;
        }
        const teamObject = {
            status: statusMean,
            name: "Team " + index,
            numTotalSubEmployees: hierarchy[teamLeadEmp.id].numTotalSubEmployees + 1,
            reportingLine: teamLeadEmp.subDepartment,
            id: teamId,
            subEmployees: [teamLeadEmp.id],
            type: "subDepartment",
        }
        hierarchy[teamId] = teamObject as unknown as HierarchicalEmployeeData;
        hierarchy[teamLeadEmp.id].reportingLine = teamId;
        teamIds.push(teamId);
    })
    hierarchy[subDepEmps[0].department + "_" + subDep].subEmployees = teamIds;
    return hierarchy;
}

export const prepSubDepartments = (
    depEmps: EmployeeData[],
    hierarchy: Hierarchy,
    headOfId: string,
    subDepartments: EmployeeData[],
): Hierarchy => {
    const subDeps = [...new Set(depEmps.map(emp => emp.subDepartment))].filter(
        subDep => (subDep != "") || (subDep === undefined)
    )
    const dep = depEmps[0].department;
    const subDepIds: string[] = [];
    subDeps.forEach(subDep => {
        const subDepId = dep + "_" + subDep;
        subDepIds.push(subDepId);
        const subDepEmps = depEmps.filter(emp => emp.subDepartment == subDep);
        const directSubEmployees = subDepEmps.filter(emp =>
            (emp.reportingLine == hierarchy[headOfId].name) && (emp.subDepartment == subDep)
        )
        const subDepObject = {
            status: mean(getAllStatusses(directSubEmployees.map(emp => emp.id), hierarchy)), // meanBy(subDepEmps, (emp) => emp.status),
            name: subDep,
            numTotalSubEmployees: directSubEmployees.length + sum(directSubEmployees.map(emp => hierarchy[emp.id].numTotalSubEmployees)),
            reportingLine: hierarchy[headOfId].name,
            id: subDepId,
            subEmployees: directSubEmployees.map(emp => emp.id),
            type: "subDepartment"
        }
        Object.keys(triggerTitles).forEach(triggerTitle => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            subDepObject[triggerTitle] = meanBy(
                directSubEmployees, triggerTitle
            )
        })
        hierarchy[subDepId] = {
            ...subDepObject as unknown as HierarchicalEmployeeData,
            ...subDepartments.filter(item => item.name == subDep)[0]
        }
        directSubEmployees.map(emp => {
            hierarchy[emp.id].type = "TeamLead"
        })
    })
    hierarchy[headOfId] = {...hierarchy[headOfId], subEmployees: subDepIds}
    return hierarchy;
}

export const prepDepartments = (
    employeeData: EmployeeData[],
    departments: EmployeeData[],
    subDepartments: EmployeeData[],
    hierarchy: Hierarchy
): Hierarchy => {

    const headOfs = employeeData.filter(item => item.reportingLine == "company")
    const deps: string[] = [];
    headOfs.forEach(headOf => {
        const dep = headOf.department;
        deps.push(dep)

        const depEmps = employeeData.filter(item => item.department == dep);
        depEmps.map(emp => {
            hierarchy[emp.id].numTotalSubEmployees = countChildren(hierarchy[emp.id], hierarchy);
        })

        const subEmployees: Array<string> = [];
        Object.keys(hierarchy).map(empId => {
            if(hierarchy[empId].reportingLine == headOf.name){
                subEmployees.push(empId)
            }
        })
        const depObject = {
            status: meanBy(
                depEmps.filter(emp => emp.id != headOf.id), emp => emp.status
            ),
            name: dep,
            numTotalSubEmployees: depEmps.length - 1,
            reportingLine: "company",
            id: dep,
            subEmployees: [headOf.id],
            type: "department"
        } as unknown as HierarchicalEmployeeData;
        Object.keys(triggerTitles).forEach(triggerTitle => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            depObject[triggerTitle] = Number(
                meanBy(
                    depEmps.filter(emp => emp.id != headOf.id), emp => emp[triggerTitle as keyof EmployeeData]
                ).toFixed(2)
            )
        })
        hierarchy[dep] = {
            ...departments.filter(item => item.name == dep)[0],
            ...depObject as  unknown as HierarchicalEmployeeData,
        }
        hierarchy[headOf.id] = {...hierarchy[headOf.id], subEmployees: subEmployees, reportingLine: dep}
        hierarchy = prepSubDepartments(depEmps, hierarchy, headOf.id, subDepartments);
    })
    hierarchy["company"].subEmployees = deps
    return hierarchy;
}