export const triggerTitles = {
    percentMaleTeam: {
        title: "Percentage of male team members",
        subTitle: "Percentage of male team members",
    },
    percentFemaleTeam: {
        title: "Percentage of female team members",
        subTitle: "Percentage of female team members",
    },
    teamAgeGap: {
        title: "Team age gap",
        subTitle: "Inequality in team age distribution",
    },
    percentQuittingUnderLeader: {
        title: "Percent quitting under Leader",
        subTitle: "Significant increase of terminations under leader",
    },
    salaryDevFromPeers: {
        title: "Below Average Salary compared to peers",
        subTitle: "Peer group salary inequality",
    },
    salaryGenderDevFromPeers: {
        title: "Below Average Salary (based on gender) compared to peers",
        subTitle: "Gender pay gap",
    },
    yearsUnpromoted: {
        title: "Years unpromoted",
        subTitle: "Peer group promotion inequality",
    },
    percentQuittingPeers: {
        title: "Percent peers quitting",
        subTitle: "Team churn",
    },
    sicknessDays: {
        title: "Absence",
        subTitle: "Physical or mental health change",
    }
}