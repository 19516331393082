import {Box, Grid, Typography} from "@mui/material";

import * as React from "react";
import EmployeeKPICard, {EmployeeKPICardProps} from "../../EmployeeKPICard";
import {getBorderColorByScore} from "../../../helper/colors";


export default function StatusCards() {

    const colorKPIs: EmployeeKPICardProps[] = [
        {
            name: "critical",
            lineChartData: [44, 46, 33, 35, 30, 25, 20],
            kpi: "red",
            status: 0,
            backgroundColor: "#fef4f5"
        },
        {
            name: "alright",
            lineChartData: [20, 25, 30, 35, 33, 46, 44],
            kpi: "yellow",
            status: 55,
            backgroundColor: "#fefbf6",
        },
        {
            name: "great",
            lineChartData: [15, 26, 23, 20, 29, 26, 26],
            kpi: "green",
            status: 100,
            backgroundColor: "#eafad8"
        }
    ]
    return (
        <Box
            sx={{
                border: 1,
                float: "center",
                marginRight: 1,
                borderRadius: 5,
                marginBottom: 4
            }}
        >
            <Typography
                variant="h3"
                style={{
                    color: "#0323f8",
                    fontSize: 26,
                    fontWeight: 900,
                    margin: 10,
                    marginLeft: 20,
                    marginTop: 20,
                }}
            >
                Employees by status
            </Typography>
            <Grid
                container
                spacing={0}
                sx={{marginTop: 2, marginBottom: 2}}
            >
                <Grid item sx={{marginRight: 2}}></Grid>
                    {colorKPIs.map(
                        item => <EmployeeKPICard
                            {...item}
                            sx={{
                                float: "center"
                            }}
                            disableHover={true}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            pathColor={getBorderColorByScore(item.status!)}
                        />
                    )}
            </Grid>
        </Box>
    )
}
