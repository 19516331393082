import {Box, Button, Dialog, DialogTitle, Grid, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import {EmployeeData} from "../../services/types";

export type MustStayDialog = {
    open: boolean,
    setOpen: (val: boolean) => void,
    handleSetMustStay: (mustStayInfo: string, mustStayState?: boolean | undefined) => void;
    employeeData: EmployeeData
}

export default function MustStayDialog(props: MustStayDialog){

    const info = props.employeeData.mustStayInfo;
    const [value, setValue] = React.useState<string>(info);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleSet = () => {
        props.setOpen(!props.open);
        props.handleSetMustStay(value);
    }

    return(
        <Dialog open={props.open}>
            <Box sx={{paddingLeft: 5, paddingTop: 5, paddingBottom: 5, paddingRight: -5}}>
                <DialogTitle>
                    <Typography sx={{marginTop: -4}}>
                        Add to Must Stay list
                    </Typography>
                </DialogTitle>
                <Stack direction={"column"}>
                <TextField
                    id="outlined-multiline-flexible"
                    label="Comment"
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                    sx={{marginRight: 5}}
                />
                <Grid container sx={{marginTop: 3}}>
                    <Grid item xs={6}>
                        <Button
                            //variant={"outlined"}
                            onClick={() => props.setOpen(!props.open)}
                        >Cancel</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            onClick={handleSet}
                            sx={{
                                backgroundColor: (
                                    !props.employeeData.mustStay ? "rgba(0,125,227,0.54)" : "rgba(197,39,39,0.6)"
                                ),
                            }}
                        >{!props.employeeData.mustStay ? "Add" : "Remove"}</Button>
                    </Grid>
                </Grid>
                </Stack>
            </Box>
        </Dialog>
    )
}