import { SET_FILTER_OPTIONS_TYPE } from "../constants/actionTypes";
import {FilterOptionsTypeState} from "../reducers/filterOptionsTypeReducer";

export type FilterOptionsTypeAction = {
    type: string;
    payload: FilterOptionsTypeState;
};

export const setFilterOptionsType = (
    filterOptionsType: FilterOptionsTypeState
): FilterOptionsTypeAction => {
    return {
        type: SET_FILTER_OPTIONS_TYPE,
        payload: filterOptionsType,
    };
};