import {Box, Grid} from "@mui/material";
import KPINumberCard from "../../../layouts/dashboard/dashboard/KPINumberCard";
import {ApexOptions} from "apexcharts";
import AreaChart, {ForecastData} from "../../../components/chart/AreaChart";
import {AppleStock} from "@visx/mock-data/lib/mocks/appleStock";
import {xaxisOptions} from "../../../services/lineCharts";
import React, {useEffect, useRef} from "react";

export type ScoreCardProps = {
    chartSeries: ApexAxisChartSeries,
    chartOptions: ApexOptions,
    title: string,
    valueTitle: string,
    lineColor: string,
    maxY?: number | undefined,
    forecastData: ForecastData[],
    backgroundTransitionColor?: string,
    accentColor?: string,
    baseline?: number | undefined,
    baselineName?: string | undefined,
}

export default function ScoreCard(props: ScoreCardProps){

    const chartSeries: AppleStock[] = props.chartSeries[0].data.map((item, index) => {
        return {
            close: item,
            date: xaxisOptions.categories[index]
        } as unknown as AppleStock
    })

    const ref = useRef(null);

    const [width, setWidth] = React.useState(300);

    // useEffect will run on stageCanvasRef value assignment
    useEffect( () => {

        const handleResize = () => {
            setWidth(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.innerWidth / 5
            )
        }
        window.addEventListener('resize', handleResize)

    });

    return (
        <Box
            ref={ref}
            sx={{
                backgroundColor: "#FFFFFF",
                border: 1,
                borderRadius: 2,
                borderColor: "rgba(0,0,0,0.3)",
                marginRight: 1,
                overflow: "auto"
            }}
        >
            <Grid container direction={"column"} margin={2}>
                <KPINumberCard
                    name={props.title}
                    value={props.valueTitle}
                    backgroundColor={"#f8f6ff"}
                    fontColor={props.lineColor}
                />
                <Box sx={{marginRight: 4, marginTop: 2}}>
                    {/*<ApexSpineChart
                         title={props.title}
                         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                         // @ts-ignore
                         options={props.chartOptions}
                         //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                         // @ts-ignore
                         height={props.chartOptions.chart.height}
                         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                         // @ts-ignore
                         series={props.chartSeries}

                     >*/}
                    <div style={{marginLeft: 0}}>
                        <AreaChart
                            width={width * 1.5}
                            height={300}
                            data={chartSeries}
                            forecastData={props.forecastData}
                            backgroundTransitionColor={props.backgroundTransitionColor}
                            maxY={props.maxY}
                            lineColor={props.lineColor}
                            accentColor={props.accentColor}
                            baseline={props.baseline}
                            baselineName={props.baselineName}
                        />
                    </div>
                </Box>
            </Grid>
        </Box>
    )
}