import {Button, Typography, Box, TextField, FormControl} from "@mui/material";
import { Page } from "../components/Page";
import React from "react";
import Papa, { ParseResult } from "papaparse"

type Data = {
  name: string;
  id: string;
}

type Values = {
  data: Data[]
}

export default function KeyFileUpload() {

  const [, setValues] = React.useState<Values | undefined>()

  const [fileDetails, setFileDetails] = React.useState<{name: string, file: File}>({
    name: "",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    file: null,
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.files){
      const uploadedFile = event.target.files[0];
      const data = {...fileDetails}
      data.file = uploadedFile;
      setFileDetails(data);
    }
  }

  // const handleFileDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   const data = {...fileDetails}
  //   data.name = event.target.value;
  //   setFileDetails(data);
  // }

  const getCSV = () => {
    Papa.parse(fileDetails.file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter: ",",
      complete: (results: ParseResult<Data>) => {
        setValues(results)
      },
    })
  }

  // const handleFileProcessing = () => {
  //   getCSV()
  // }

  return (
    <Page title="Key File">
      <Box sx={{
        maxWidth: 480,
        margin: "auto",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "top",
        flexDirection: "column",
      }}>
        <Typography
            variant="h4"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 20,
              marginTop: 10,
            }}
        >
          Upload a CSV File to decrypt your employees names
        </Typography>
        <Typography
            variant="h4"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 12,
            }}
        >
          This file will not be upload, it will be left on your computer as it is
        </Typography>
        <FormControl fullWidth>
          <TextField
              id="file"
              name="file"
              margin="dense"
              type="file"
              variant="outlined"
              onChange={handleFileChange}
              style={{marginTop: 100}}
          />
        </FormControl>
        <Button variant="contained" onClick={() => fileDetails.name == "juhu" ? getCSV() : null}>Use</Button>
      </Box>
    </Page>
  );
}
