import { filter } from "lodash";
import { EmployeeData } from "../services/types";
import { NumericFilterOptionListConfig } from "../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import { StringFilterOptionListConfig } from "../layouts/dashboard/filterOptions/StringFilterOptionCard";
import { StringQueryFilterOptionConfig } from "../sections/dashboard/employee/UserListToolbar";
import {ActionData} from "./types";
import {TriggerCardData} from "../sections/dashboard/employeeDetailsPopover/Overview";

type ItemAndIndex = {
  item: EmployeeData | ActionData | TriggerCardData;
  index: number;
};

function descendingComparator(a: object, b: object, orderBy: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: object, b: object) => descendingComparator(a, b, orderBy)
    : (a: object, b: object) => -descendingComparator(a, b, orderBy);
}

export function applySort(
  array: (EmployeeData | ActionData | TriggerCardData)[],
  comparator: (a: EmployeeData, b: EmployeeData) => number
): (EmployeeData | ActionData | TriggerCardData)[] {
  const stabilizedThis: ItemAndIndex[] = array.map((item, index) => {
    return {
      item: item,
      index: index,
    };
  });
  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const order = comparator(a.item, b.item);
    if (order !== 0) return order;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return a.index - b.index;
  });
  return stabilizedThis.map((itemAndIndex) => itemAndIndex.item);
}

export function applyFilter(
  array: EmployeeData[],
  stringQueryFilterConfig: StringQueryFilterOptionConfig
): EmployeeData[] {
  return filter(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    array,
    (_employee) => {
      return (
        _employee[stringQueryFilterConfig.key]
          .toString()
          .toLowerCase()
          .indexOf(stringQueryFilterConfig.queryString.toLowerCase()) !== -1
      );
    }
  );
}

export function applyNumericFilter(
  array: Array<EmployeeData>,
  filterOptionConfig: NumericFilterOptionListConfig
): Array<EmployeeData> {
  return array.filter(function (x) {
    return (
      x[filterOptionConfig.key] >= filterOptionConfig.min &&
      x[filterOptionConfig.key] <= filterOptionConfig.max
    );
  });
}

export function applyStringFilter(
  array: Array<EmployeeData>,
  filterOptionConfig: StringFilterOptionListConfig
): Array<EmployeeData> {
  if (!filterOptionConfig.checkedItems) {
    return array;
  }
  if (filterOptionConfig.checkedItems.length == 0) {
    return array;
  }
  return array.filter(function (item) {
    return filterOptionConfig.checkedItems.includes(
      item[filterOptionConfig.key] as string
    );
  });
}

export function applyMultipleFilter(
  array: Array<EmployeeData>,
  filterOptions: Array<
    | NumericFilterOptionListConfig
    | StringFilterOptionListConfig
    | StringQueryFilterOptionConfig
  >
): Array<EmployeeData> {
  for (let i = 0; i < filterOptions.length; i++) {
    if ((filterOptions.at(i) as NumericFilterOptionListConfig).max) {
      array = applyNumericFilter(
        array,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filterOptions.at(i)! as NumericFilterOptionListConfig
      );
    }
    if ((filterOptions.at(i) as StringFilterOptionListConfig).checkedItems) {
      array = applyStringFilter(
        array,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filterOptions.at(i)! as StringFilterOptionListConfig
      );
    }
    if ((filterOptions.at(i) as StringQueryFilterOptionConfig).queryString) {
      array = applyFilter(
        array,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filterOptions.at(i)! as StringQueryFilterOptionConfig
      );
    }
  }
  return array;
}

export function applySortFilter(
  array: EmployeeData[],
  comparator: (a: EmployeeData, b: EmployeeData) => number,
  filterOptions: Array<
    | NumericFilterOptionListConfig
    | StringQueryFilterOptionConfig
    | StringFilterOptionListConfig
  >
) {
  const result: EmployeeData[] = applySort(array, comparator) as EmployeeData[];
  return applyMultipleFilter(result, filterOptions);
}
