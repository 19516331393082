import React, { useMemo, useCallback } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { AppleStock } from '@visx/mock-data/lib/mocks/appleStock';
import { Group } from '@visx/group';
import {curveBasis} from '@visx/curve';
import { GridRows } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { withTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { LinePath } from '@visx/shape';
import { LineSubject, Annotation, Label } from '@visx/annotation'
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { AxisLeft, AxisBottom } from '@visx/axis';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { max, extent, bisector } from 'd3-array';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { timeFormat } from 'd3-time-format';
import { Threshold } from '@visx/threshold';
import {numberFormatter} from "../../helper/charts";

type TooltipData = AppleStock;

// const stock = appleStock.slice(800);
export const background = '#FFFFFF';
export const accentColor = '#edffea';
export const accentColorDark = '#75daad';
const tooltipStyles = {
    ...defaultStyles,
    background,
    border: '1px solid white',
    color: 'black',
};

// util
// const formatDate = timeFormat("%b %d, '%y");

// accessors
const getDate = (d: AppleStock) => new Date(d.date);
const getStockValue = (d: AppleStock) => d.close;
const bisectDate = bisector<AppleStock, Date>((d: AppleStock) => new Date(d.date)).left;

export type ForecastData = {
    upper: AppleStock,
    lower: AppleStock,
}

export type AreaProps = {
    width: number;
    height: number;
    data: AppleStock[];
    forecastData: ForecastData[];
    margin?: { top: number; right: number; bottom: number; left: number };
    backgroundTransitionColor?: string,
    lineColor?: string;
    maxY?: number;
    accentColor?: string;
    baseline?: number | undefined;
    baselineName?: string | undefined;
};

export default withTooltip<AreaProps, TooltipData>(
    ({
         width,
         height,
         margin = { top: 0, right: 0, bottom: 0, left: 0 },
         showTooltip,
         hideTooltip,
         tooltipData,
         tooltipTop = 0,
         tooltipLeft = 0,
         data,
         forecastData,
         backgroundTransitionColor = 'rgba(6,125,194,0.38)',
         lineColor = "#0323f8",
         maxY = 100,
         accentColor = '#edffea',
         baseline,
         baselineName
     }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
        if (width < 10) return null;

        // scales
        const xScale = scaleTime<number>({
            domain: [0, data.length - 1],
            nice: true,
        });
        const yScale = scaleLinear<number>({
            domain: [
                Math.min(
                ...data.map(
                        d => Math.min(
                            d.close, forecastData[forecastData.length - 1].lower.close
                        )
                    ),
                ),
                maxY ? maxY : Math.max(
                    ...data.map(
                        d => Math.max(
                            d.close, forecastData[forecastData.length - 1].upper.close
                        )
                    ),
                ),
            ],
            nice: true,
        });

        // bounds
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        xScale.range([0, innerWidth]);
        yScale.range([innerHeight, 0]);

        // scales
        const dateScale = useMemo(
            () =>
                scaleTime({
                    range: [margin.left, innerWidth + margin.left - 5],
                    domain: extent(data, getDate) as [Date, Date],
                }),
            [innerWidth, margin.left],
        );
        const stockValueScale = useMemo(
            () =>
                scaleLinear({
                    range: [innerHeight + margin.top, margin.top],
                    domain: [0, maxY ? maxY : (max(data, getStockValue) || 0)],
                    nice: true,
                }),
            [margin.top, innerHeight],
        );

        // tooltip handler
        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0 };
                const x0 = dateScale.invert(x);
                const index = bisectDate(data, x0, 1);
                const d0 = data[index - 1];
                const d1 = data[index];
                let d = d0;
                if (d1 && getDate(d1)) {
                    d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
                }
                showTooltip({
                    tooltipData: d,
                    tooltipLeft: x - 40,
                    tooltipTop: stockValueScale(getStockValue(d)),
                });
            },
            [showTooltip, stockValueScale, dateScale],
        );

        forecastData.push({
            lower: data[data.length - 2],
            upper: data[data.length - 2]
        })

        const id = `${Math.random()}`

        const positionForeCastStart = width - width / 5.8;

        return (
            <div>
                <svg width={width+60} height={height+50} id={`${Math.random()}`}>
                    <Group left={margin.left + 40} top={margin.top + 20}>
                        <rect
                            x={0}
                            y={0}
                            width={width - 4}
                            height={height}
                            fill={`url(#area-background-gradient${id})`}
                            rx={0}
                            id={`${Math.random()}`}
                        />
                        <LinearGradient
                            id={`area-background-gradient${id}`}
                            from={background}
                            to={backgroundTransitionColor}
                        />
                        <LinearGradient id={`area-gradient${id}`} from={accentColor} to={accentColor} toOpacity={0.1} />
                        <AxisBottom
                            top={innerHeight}
                            scale={dateScale}
                            numTicks={data.length}
                            left={0}
                        />
                        <AxisLeft
                            scale={stockValueScale}
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            tickFormat={(v: string) => numberFormatter(v)
                        }/>
                        <GridRows
                            left={margin.left}
                            scale={stockValueScale}
                            width={innerWidth}
                            strokeDasharray="1,3"
                            stroke={accentColor}
                            strokeOpacity={0.8}
                            pointerEvents="none"
                        />
                        {/*<GridColumns
                            top={margin.top}
                            scale={dateScale}
                            height={innerHeight}
                            strokeDasharray="1,3"
                            stroke={accentColor}
                            strokeOpacity={0.8}
                            pointerEvents="none"
                        />*/}
                        <Group left={margin.left + 1}>
                            <AreaClosed<AppleStock>
                                data={data}
                                x={(d) => dateScale(getDate(d)) ?? 0}
                                y={(d) => stockValueScale(getStockValue(d)) ?? 0}
                                yScale={stockValueScale}
                                strokeWidth={1}
                                stroke={`url(#area-gradient${id})`}
                                fill={`url(#area-gradient${id})`}
                                // curve={curveMonotoneX}
                            />
                            <Threshold<ForecastData>
                                id={`${Math.random()}`}
                                data={forecastData}
                                x={(d) => dateScale(getDate(d.lower)) ?? 0}
                                y0={(d) => stockValueScale(getStockValue(d.lower)) ?? 0}
                                y1={(d) => stockValueScale(getStockValue(d.upper)) ?? 0}
                                clipAboveTo={0}
                                clipBelowTo={innerWidth}
                                curve={curveBasis}
                                belowAreaProps={{
                                    fill: "#FFFFFF",
                                    fillOpacity: 0.6,
                                }}
                                aboveAreaProps={{
                                    fill: 'green',
                                    fillOpacity: 0.0,
                                }}
                            />
                        </Group>
                        <Group left={1}>
                            {/*<LinePath
                                stroke={lineColor}
                                strokeWidth={2}
                                data={data.slice(0, data.length - 1)}
                                x={(d) => dateScale(getDate(d)) ?? 0}
                                y={(d) => stockValueScale(getStockValue(d)) ?? 0}
                                // curve={curveMonotoneX}
                            />*/}
                            <LinePath
                                stroke={lineColor}
                                strokeWidth={2}
                                data={data.slice(data.length - 2, data.length)}
                                x={(d) => dateScale(getDate(d)) ?? 0}
                                y={(d) => stockValueScale(getStockValue(d)) ?? 0}
                                opacity={0.1}
                                // curve={curveMonotoneX}
                                strokeDasharray="0,1"
                            />
                        </Group>
                        <Bar
                            x={margin.left}
                            y={margin.top}
                            width={innerWidth}
                            height={innerHeight}
                            fill="transparent"
                            rx={14}
                            onTouchStart={handleTooltip}
                            onTouchMove={handleTooltip}
                            onMouseMove={handleTooltip}
                            onMouseLeave={() => hideTooltip()}
                        />
                        <Annotation x={350} y={0}>
                            <Label
                                title={"Forecast"}
                                x={positionForeCastStart}
                                y={200}
                                titleFontWeight={300}
                                verticalAnchor={"start"}
                                horizontalAnchor={"end"}
                                backgroundFill={accentColor}
                            />
                            <LineSubject
                                max={innerHeight + margin.top}
                                min={0}
                                x={positionForeCastStart}
                                stroke={"#fFffff"}
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="9,2"
                            />
                        </Annotation>
                        {baseline ? (
                            <Annotation x={100} y={0}>
                                <Label
                                    title={baselineName}
                                    x={100}
                                    y={stockValueScale(baseline)}
                                    titleFontWeight={100}
                                    verticalAnchor={"end"}
                                    horizontalAnchor={"end"}
                                    backgroundFill={"rgba(0,0,0,0.31)"}
                                    fontColor={"#FFFFFF"}
                                />
                                <LineSubject
                                    orientation={"horizontal"}
                                    max={0}
                                    min={innerWidth}
                                    y={stockValueScale(baseline)}
                                    stroke={"rgba(0,0,0,0.31)"}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                    strokeDasharray="9,2"
                                />
                            </Annotation>
                        ) : null}
                        {tooltipData && (
                            <g>
                                <Line
                                    from={{ x: tooltipLeft, y: margin.top }}
                                    to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                                    stroke={accentColorDark}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                    strokeDasharray="5,2"
                                />
                                <circle
                                    cx={tooltipLeft}
                                    cy={tooltipTop + 1}
                                    r={4}
                                    fill="black"
                                    fillOpacity={0.1}
                                    stroke="black"
                                    strokeOpacity={0.1}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                                <circle
                                    cx={tooltipLeft}
                                    cy={tooltipTop}
                                    r={4}
                                    fill={accentColorDark}
                                    stroke="white"
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                            </g>
                        )}
                    </Group>
                </svg>

                {tooltipData && (
                    <div>
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop + 50}
                            left={tooltipLeft + 24}
                            style={tooltipStyles}
                        >
                            {numberFormatter(getStockValue(tooltipData))}
                        </TooltipWithBounds>
                        {/*<Tooltip
                            top={innerHeight + margin.top - 14}
                            left={tooltipLeft}
                            style={{
                                ...defaultStyles,
                                minWidth: 72,
                                textAlign: 'center',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            {formatDate(getDate(tooltipData))}
                        </Tooltip>*/}
                    </div>
                )}
            </div>
        );
    },
);