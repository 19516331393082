import * as lsConstants from "../constants/localStorage";
import * as actionTypes from "../constants/actionTypes";

export type TriggerOption = {
    yellow: number;
    red: number;
}

export type TriggerOptionsState = {
    salaryDevFromPeers: TriggerOption
    salaryGenderDevFromPeers: TriggerOption
    yearsUnpromoted: TriggerOption
    percentQuittingUnderLeader: TriggerOption
    percentQuittingPeers: TriggerOption
    percentMaleTeam: TriggerOption
    percentFemaleTeam: TriggerOption,
    teamAgeGap: TriggerOption
    sicknessDays: TriggerOption
};

export const initialState: TriggerOptionsState = {
    ...{
        salaryDevFromPeers: {
            yellow: 0,
            red: 20,
        },
        salaryGenderDevFromPeers: {
            yellow: 0,
            red: 20,
        },
        yearsUnpromoted: {
            yellow: 0,
            red: 3,
        },
        percentQuittingUnderLeader: {
            yellow: 0,
            red: 25,
        },
        percentQuittingPeers: {
            yellow: 0,
            red: 25,
        },
        percentMaleTeam: {
            yellow: 0,
            red: 30,
        },
        percentFemaleTeam: {
            yellow: 0,
            red: 90,
        },
        teamAgeGap: {
            yellow: 0,
            red: 20,
        },
        sicknessDays: {
            yellow: 0,
            red: 50,
        },
    },
    ...Object(localStorage.getItem(lsConstants.TRIGGER_OPTIONS)),
};

type Action = {
    type: string;
    payload: TriggerOptionsState;
};

// ==============================|| PROJECT REDUCER ||============================== //
const triggerOptionsReducer = (
    state: TriggerOptionsState = initialState,
    action: Action
): TriggerOptionsState => {
    switch (action.type) {
        case actionTypes.SET_PROJECT: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default triggerOptionsReducer;
