import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {Link, Container, Typography, Box} from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import { Page } from "../components/Page";
import { LoginForm } from "../sections/auth/login";
import loginBackground from "../sections/auth/login/login_background.jpg";
import Logo from "../components/Logo";
import React from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: "100%",
//   maxWidth: 464,
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Login() {


  const smUp = useResponsive("up", "sm", "xs", "lg");

  const [signInError, setSignInError] = React.useState(false);

  // const mdUp = useResponsive("up", "md", "xs", "lg");
  return (
    <Page title="Login">
      <div style={{backgroundImage: `url(${loginBackground})`}}>
      <RootStyle>
        {/*mdUp && (
          <SectionStyle>
            <img src="/static/mock-images/avatars/loginin.png" alt="login" />
          </SectionStyle>
        )*/}
        <Container maxWidth="sm">
          <ContentStyle>
            <Logo sx={{marginLeft: 20, marginBottom: 1}}/>
            <Box sx={{backgroundColor: "#FFFFFF", padding: 5, borderRadius: 5,}}>
              <Typography
                variant="h4"
                gutterBottom
                style={{ color: "blue", fontWeight: 700, fontSize: 25 }}
              >
                Sign In
              </Typography>
              <LoginForm setLoginInError={setSignInError}/>

              {smUp && (
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Don’t have an account?{" "}
                  <Link variant="subtitle2" component={RouterLink} to="/register">
                    Get started
                  </Link>
                </Typography>
              )}
              {signInError && (
                  <Typography variant={"body2"} align={"center"} sx={{mt: 3, color: "red"}}>
                    Email or password incorrect.
                  </Typography>
                  )}
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
      </div>
    </Page>
  );
}
