import { FilterOptionCardProps } from "./types";
import StringFilterOptionCard from "../../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import * as React from "react";

export default function ReportingLineFilterOptionCard(
    props: FilterOptionCardProps
) {
    return (
        <StringFilterOptionCard
            key={"reportingLineFilterOptionCard"}
            name={"Reporting Line"}
            tableKey={"reportingLine"}
            employeeData={props.employeeData}
        />
    );
}
