import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import Iconify from "../../components/Iconify";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(0px)",
  WebkitBackdropFilter: "blur(0px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

type DashboardNavbarProps = {
  onOpenSidebar: () => void;
  dataProcessingType: "trigger" | "ai",
  setDataProcessingType:  React.Dispatch<React.SetStateAction<"ai" | "trigger">>;
};

export default function DashboardNavbar(props: DashboardNavbarProps) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={props.onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" sx={{ width: 20, height: 20 }} />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover
              dataProcessingType={props.dataProcessingType}
              setDataProcessingType={props.setDataProcessingType}
          />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
