import {EmployeeData} from "../../../services/types";
import {Avatar, Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import * as React from "react";
import {Controller, useForm} from "react-hook-form";
import MonetaryLossCard from "../employee/MonetaryLossCard";
import {MainTableState} from "../../../redux/reducers/mainTableReducer";
import {useSelector} from "react-redux";
import {MainTableReduxState} from "../../../redux/reducers";
import {moneyFormatter} from "../../../helper/charts";
import {AddCircle, AddCircleOutline} from "@mui/icons-material";

export type EmployeeInfoProps = {
  employeeData: EmployeeData;
  setData: (value: EmployeeData) => void;
  setMustStayEmployeeData: (value: EmployeeData) => void;
  setMustStayDialogOpen: (value: boolean) => void;
  MustStayDialogOpen: boolean,
  handleSetMustStay: (mustStayInfo: string, state?: undefined | boolean) => void;
};

export default function EmployeeInfo(props: EmployeeInfoProps) {
  const { control } = useForm({});

  const tableDataState = useSelector<MainTableReduxState, MainTableState>(
      (state: MainTableReduxState) => state.mainTable
  );

  const handleMustStayInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.employeeData.mustStayInfo = event.target.value;
      props.handleSetMustStay(event.target.value, true);
  };

  return (
    <Grid item xs={12} md={12} lg={12} padding={2}>
      <form>
        <Grid
          container
          spacing={0}
          alignItems="center"
          sx={{ marginBottom: 3 }}
        >
          <Avatar
            src={props.employeeData.avatarUrl}
            alt={props.employeeData.name}
            style={{
              borderRadius: "8px",
              width: "50px",
              height: "55px",
            }}
          />
          <Typography
            variant="h4"
            gutterBottom
            style={{
              color: "#0323f8",
              paddingLeft: 40,
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {props.employeeData.name}
          </Typography>
          <div>
              <IconButton
                  sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: "#0323f8",
                      borderColor: "#0323f8",
                      padding: "2px 15px",
                      borderRadius: "8px",
                      marginTop: -1,
                      marginLeft: 2
                  }}
                  onClick={(event) => {
                      event.stopPropagation();
                      props.setMustStayEmployeeData(props.employeeData);
                      props.setMustStayDialogOpen(true)
                  }}
              >
                  {props.employeeData.mustStay ? <AddCircle /> : <AddCircleOutline />}
              </IconButton>
          </div>
        </Grid>
        <MonetaryLossCard
            series={props.employeeData.monetaryLossData}
        />
        <Grid container spacing={1} alignItems="center">
            {/*<Grid item xs={12} md={2}>
                          <Typography variant="body2">Name:</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="Name"
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                size="small"
                                fullWidth
                                disabled
                                name="Name"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography variant="body2">City:</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="City"
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                size="small"
                                fullWidth
                                disabled
                                name="City"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </Grid> */}
            {props.employeeData.mustStay ? (
                <div style={{marginLeft: 10, marginTop: 10}}>
                    <TextField
                        id="standard-multiline-static"
                        label="Must Stay Info"
                        multiline
                        rows={2}
                        defaultValue={props.employeeData.mustStayInfo}
                        variant="standard"
                        sx={{width: 300, backgroundColor: "#FFFFFF"}}
                        onChange={handleMustStayInfoChange}
                    />
                </div>
            ) : null}
          <Grid item xs={12} md={3}>
            <Typography variant="body2">Email:</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              control={control}
              name="State"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <TextField
                  size="small"
                  fullWidth
                  disabled
                  name="State"
                  value={
                    props.employeeData.name.replace(" ", ".").toLowerCase() +
                    "@wetain.io"
                  }
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2">Phone:</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              control={control}
              name="phone"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <TextField
                  size="small"
                  fullWidth
                  disabled
                  name="phone"
                  value="+49 177 87 78 765"
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
                variant="body2"
            >Department:</Typography>
          </Grid>
          <Grid item xs={12} md={8.7} sx={{marginLeft: 1}}>
            <Button
                sx={{border: 1, width: 225}}
                onClick={() => {
                  const departmentData = tableDataState.departments.filter(
                      dep => dep.name == props.employeeData.department
                  )[0]
                  props.setData(departmentData);
                }}
            >
              <Typography>{props.employeeData.department}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
                variant="body2"
            >Sub Department:</Typography>
          </Grid>
          <Grid item xs={12} md={8.7} sx={{marginLeft: 1, flex: 1}}>
            <Button
                sx={{border: 1, width: 225}}
                onClick={() => {
                  const subDepartmentData = tableDataState.subDepartments.filter(
                      dep => dep.name == props.employeeData.subDepartment
                  )[0]
                  props.setData(subDepartmentData);
                }}
            >
              <Typography>{props.employeeData.subDepartment}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2">Reporting Line:</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              control={control}
              name="reportingLine"
              defaultValue={props.employeeData.reportingLine}
              render={({ field: { onChange } }) => (
                <TextField
                  size="small"
                  fullWidth
                  disabled
                  name="reportingLine"
                  value={props.employeeData.reportingLine}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2">Role:</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              control={control}
              name="role"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <TextField
                  size="small"
                  fullWidth
                  disabled
                  name="role"
                  value={props.employeeData.role}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2">Salary:</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              control={control}
              name="salary"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <TextField
                  size="small"
                  fullWidth
                  disabled
                  name="salary"
                  value={moneyFormatter(props.employeeData.salary)}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        <br />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography variant="body2" sx={{marginLeft: 1}}>Address:</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              control={control}
              name="Address"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: `Please Enter The Address`,
                },
              }}
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  sx={{marginTop: 1}}
                  name="Address"
                  disabled
                  value="Kensington street 25"
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        </Grid>

      </form>
    </Grid>
  );
}
