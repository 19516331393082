import { FilterOptionCardProps } from "./types";
import StringFilterOptionCard from "../../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import * as React from "react";

export default function RoleFilterOptionCard(
    props: FilterOptionCardProps
) {
    return (
        <StringFilterOptionCard
            key={"roleFilterOptionCard"}
            name={"Role"}
            tableKey={"role"}
            employeeData={props.employeeData}
        />
    );
}
