import NumericFilterOptionCard from "../../../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import { RangeSliderProps } from "../../../components/filter/RangeSlider";
import React from "react";
import {FilterOptionCardProps} from "./types";


export default function WeTainScoreFilterOptionCard(
  props: FilterOptionCardProps
) {
  const rangeSliderProps: RangeSliderProps = {
    marks: [
      {
        value: 25,
        label: "25%",
      },
      {
        value: 50,
        label: "50%",
      },
      {
        value: 75,
        label: "75%",
      },
    ],
    employeeData: props.employeeData,
    tableKey: "status"
  };
  return (
    <NumericFilterOptionCard
      key="weTainScoreFilterOptionCard"
      name="WeTain Score"
      tableKey={"status"}
      rangeSliderProps={rangeSliderProps}
      employeeData={props.employeeData}
    />
  );
}
