import * as React from "react";
import {EmployeeData, MaiLContent} from "../../../services/types";
import RecommendedActionHead from "../../../components/employee-details/RecommendActionHead";
import {Box, CircularProgress, Dialog} from "@mui/material";
import ActionTable from "../../../sections/dashboard/employeeDetailsPopover/ActionTable";
import RecommendedActionCard from "../../../components/employee-details/RecommendedActionCard";
import DialogContent from "@mui/material/DialogContent";
import ActionMailPopUp from "../../../sections/dashboard/employeeDetailsPopover/ActionMailPopUp";

export type RecommendedActionProps = {
    employeeData: EmployeeData,
    open: boolean,
    setOpen: (arg0: boolean) => void,
    kpi: string,
    setActionKpi: (value: string) => void;
}


export default function RecommendedAction(props: RecommendedActionProps) {

    const [mailContent, setMailContent] = React.useState<MaiLContent>({recipient: "", body: "", subject: ""})

    const [actions, setActions] = React.useState(
        props.employeeData.recommendedActions
    );

    const setTaskStatus = (value: "open" | "pending" | "done") => {
        props.employeeData.recommendedActions.forEach((action, index) => {
            if(action.kpi == props.kpi){
                props.employeeData.recommendedActions[index].status = value;
            }
        })
    }

    const [mailOpen, setMailOpen] = React.useState(false);

    React.useEffect(() => {
        setActions(props.employeeData.recommendedActions.filter(action => action.kpi as string == props.kpi))
    })

    return (
        <div>
            <ActionMailPopUp
                open={mailOpen}
                setOpen={setMailOpen}
                mailContent={mailContent}
                setTaskStatus={setTaskStatus}
            />
            <Dialog
                onClose={() => {
                    props.setActionKpi("");
                    props.setOpen(false)
                }}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogContent dividers sx={{padding: 5}}>
                    <Box sx={{
                        margin: 2,
                        marginBottom: 0,
                        border: 0,
                        borderColor: "#000000",
                        borderBlockWidth: 0.0,
                        borderRadius: 5,
                        marginTop: 0,
                    }}>
                        <RecommendedActionHead/>
                        <ActionTable actions={actions} setActions={setActions}/>
                        {actions.length > 0 ?
                            actions.map(action => {
                                    return (
                                        <RecommendedActionCard
                                            setOpen={setMailOpen}
                                            setMailContent={setMailContent}
                                            actionData={action}
                                            setTaskStatus={setTaskStatus}
                                        />
                                    )
                                }) : <CircularProgress sx={{marginLeft: 20}}/>
                        }
                        <div style={{paddingBottom: 30}}/>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}