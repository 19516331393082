// import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import "react-circular-progressbar/dist/styles.css";
import {Grid, Container, Typography, IconButton} from "@mui/material";
import { Page } from "../components/Page";
import CommentBox from "../sections/CommentBox";
import { EmployeeData } from "../services/types";
import EmployeeInfo from "../sections/dashboard/employeeDetailsPopover/EmployeeInfo";
import InsightTabs from "../layouts/dashboard/EmployeeDetails/InsightTabs";
import * as React from "react";
import MustStayDialog from "../layouts/dashboard/MustStayDialog";

type EmployeeDetailsProps = {
  isOpen: boolean;
  setModalWindow: (param: boolean) => void;
  employeeData: EmployeeData;
  setData: (value: EmployeeData) => void;
  handleSetMustStay: (mustStayInfo: string) => void;
};
export default function EmployeeDetails(props: EmployeeDetailsProps) {
  // Component is tried to be rendered without available employee-data
  if (!props.employeeData) {
    return <div />;
  }

  const [mustStayDialogOpen, setMustStayDialogOpen] = React.useState(false);

  const [, setMustStayEmployeeData] = React.useState<EmployeeData>(props.employeeData);

  const { isOpen, setModalWindow } = props;
  // const theme = useTheme();

  const handleClose = () => {
    setModalWindow(false);
  };

  return (
    <Page title="EmployeeDetails">
      {mustStayDialogOpen ? <MustStayDialog
          open={mustStayDialogOpen}
          setOpen={setMustStayDialogOpen}
          handleSetMustStay={props.handleSetMustStay}
          employeeData={props.employeeData}
      /> : <></>}
      <Dialog
        // fullScreen
        // sx={{ maxWidth: '100% !important' }}
        maxWidth={"lg"}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ background: "#f9f9fa", marginRight: 2, overflowX: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <Grid item xs={12} md={12} lg={12} padding={2}>
                    <Grid container >
                      <Grid item>
                        <IconButton onClick={() => {
                          handleClose();
                        }}>
                          <ArrowCircleLeftOutlinedIcon
                              sx={{
                                float: "left",
                                color: "#0323f8",
                                cursor: "pointer",
                              }}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                              color: "#0323f8",
                              fontSize: 16,
                              fontWeight: 700,
                              cursor: "pointer",
                              paddingTop: 1.5,
                            }}
                            onClick={handleClose}
                        >
                          <span style={{ paddingLeft: 8 }}>Employee Details</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <EmployeeInfo
                      employeeData={props.employeeData}
                      setData={props.setData}
                      setMustStayDialogOpen={setMustStayDialogOpen}
                      setMustStayEmployeeData={setMustStayEmployeeData}
                      MustStayDialogOpen={true}
                      handleSetMustStay={props.handleSetMustStay}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Grid item xs={12} md={12} lg={12} padding={2}>
                    <InsightTabs employeeData={props.employeeData} sx={{float: "left"}}/>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ float: "left", padding: 4.2, width: "100%" }}
                  >
                    <CommentBox employeeId={props.employeeData.id}/>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
