import * as React from "react";
import amplitude from "../../../helper/amplitude";
import {SortingOrder} from "../../../interfaces/interface.sortingOrder";
import {Table, TableContainer} from "@mui/material";
import EnhancedTableHead, {PossibleTableHeadColumns} from "../employee/EnhancedTableHead";
import {HeadCell} from "../types";
import {applySort, getComparator} from "../../../helper/employeeTable";
import {ActionData} from "../../../helper/types";



const headCells: readonly HeadCell[] = [
    // {
    //     id: "kpi",
    //     disablePadding: true,
    //     label: "KPI",
    //     numeric: false,
    // },
    { id: "costs", disablePadding: true, label: "Costs", numeric: false },
    // { id: "impact", disablePadding: true, label: "Impact", numeric: false },
];

type ActionTableProps = {
    actions: ActionData[],
    setActions: (arg0: ActionData[]) => void,
}

export default function ActionTable(props: ActionTableProps){

    const [order, setOrder] = React.useState<SortingOrder>("asc");

    const [orderBy, setOrderBy] =
        React.useState<PossibleTableHeadColumns>("impact");

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: PossibleTableHeadColumns
    ) => {
        amplitude().track(`Set Employee Table Order by ${property}`);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    React.useEffect(() => {
        props.setActions(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            applySort(
                props.actions,
                getComparator(order, orderBy),
            )
        );
    }, [order, orderBy])

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 200 }} aria-labelledby="actionTable">
                    <EnhancedTableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <div style={{margin: 1}}/>
                </Table>
            </TableContainer>
        </>
    )
}