import React from "react";
import CardContent from "@mui/material/CardContent";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { EmployeeData } from "../../../services/types";
import DeleteIcon from "@mui/icons-material/Delete";
import {useSelector} from "react-redux";
import {FilterOptionsReduxState} from "../../../redux/reducers";
import {FilterOptionsState} from "../../../redux/reducers/filterOptionsReducer";
import amplitude from "../../../helper/amplitude";

export type FilterOptionCardProps = {
  name: string;
  tableKey: keyof EmployeeData;
  employeeData: Array<EmployeeData>;
  component: JSX.Element;
};

export default function FilterOptionCard(props: FilterOptionCardProps) {
 const filterOptions = useSelector<FilterOptionsReduxState, FilterOptionsState>(
     (state: FilterOptionsReduxState) => state.filterOptions
 ).data;

  const handleReset = () => {
    amplitude().track(`Reset Filter Options for ${props.tableKey}`)
    for (let i = 0; i < filterOptions.length; i++) {
      if (filterOptions.at(i)?.key == props.tableKey) {
        filterOptions.splice(i, 1);
      }
    }
  };


  return (

      <Card
        sx={{
          textAlign: "center",
          background: "#f8f5f1",
          borderRadius: 4,
        }}
      >
        <Grid container spacing={0} paddingTop={2} paddingRight={1}>
          <Grid item xs={10} sm={8} md={8}>
            <div
              style={{
                textAlign: "left",
                marginLeft: "auto",
                marginRight: "auto",
                width: "80%",
                marginTop: 5,
              }}
            >
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                sx={{ fontWeight: 700 }}
              >
                {props.name}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <CardContent>{props.component}</CardContent>
      </Card>
  );
}
