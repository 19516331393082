export const numberFormatter = (value: number | string): string => {
  value = Number(value);
  if (!value) {
    return "";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(2) + "M";
  }
  if (value >= 1000) {
    return (value / 1000).toFixed(2) + " K";
  }
  return value.toFixed(2).toString();
};

export const moneyFormatter = (value: number | string): string => {
  value = Number(value);
  if (!value) {
    return value.toString();
  }
  let convertedString = value = value.toString();
  for(let i = value.length-3; i > 0; i--){
    if((value.length - i) % 3 == 0){
      convertedString = (
          convertedString.slice(0, i) + "." + convertedString.slice(i, convertedString.length)
      )
    }
  }
  return convertedString+"€";
};

