import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {useDispatch, useSelector} from "react-redux";
import {FilterOptionsTypeReduxState} from "../../redux/reducers";
import {
  StringFilterOptionConfig,
  StringFilterOptionListConfig
} from "../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import {EmployeeData} from "../../services/types";
import amplitude from "../../helper/amplitude";
import {FilterOption} from "../../sections/dashboard/filterOptions/types";
import {getFilterOptions, setReduxFilterOptions} from "../../helper/filter";
import {FilterOptionsTypeState} from "../../redux/reducers/filterOptionsTypeReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export type MultipleSelectProps = {
  name: string;
  tableKey: keyof EmployeeData;
  employeeData: EmployeeData[];
};

export default function MultipleSelect(props: MultipleSelectProps) {
  const theme = useTheme();

  const filterOptions: FilterOption[] = getFilterOptions();

  const dispatch = useDispatch();

  const filterOptionType = useSelector<FilterOptionsTypeReduxState, FilterOptionsTypeState>(
      (state: FilterOptionsTypeReduxState) => state.filterOptionsType
  ).filterOptionsType

  const setSelectedItemsDefault = (): Array<string> => {
    for (let i = 0; i < filterOptions.length; i++) {
      const item = filterOptions.at(
          i
      ) as StringFilterOptionListConfig;
      if (item.key == props.tableKey) {
        return (item as StringFilterOptionConfig).checkedItems;
      }
    }
    return [];
  };

  let selectedItems = setSelectedItemsDefault();

  const listItems: Array<string> = [
    ...new Set(props.employeeData.map((emp) => emp[props.tableKey] as string)),
  ];

  const setFilterOptionsConfig = (checkedItems: Array<string>) => {
    amplitude().track(`Set Filter For ${props.tableKey}`)
    const addItem = {
      key: props.tableKey,
      checkedItems: checkedItems,
    };

    for(let i = 0; i < filterOptions.length; i++){
      const item = filterOptions.at(i) as StringFilterOptionListConfig;
      if(item.key === props.tableKey){
        filterOptions[i] = addItem;
        return;
      }
    }
    filterOptions.push(addItem);
    setReduxFilterOptions(filterOptions, filterOptionType, dispatch);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    selectedItems = (
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
    setFilterOptionsConfig(value as Array<string>);
  };

  return (
    <div style={{ marginTop: 0, marginBottom: 0 }}>
      <FormControl sx={{ m: 0, width: 310 }}>
        <InputLabel id={"multiple-select-" + props.name} size={"small"}>
          {props.name}
        </InputLabel>
        <Select
          labelId={"multiple-select-" + props.name + "-label"}
          id={"multiple-select-" + props.name}
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<OutlinedInput label={props.name} />}
          MenuProps={MenuProps}
          sx={{height: 40}}
        >
          {listItems.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selectedItems, theme)}
              defaultChecked={false}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
