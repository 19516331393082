import http from "./httpService";
import * as lsConstants from "../redux/constants/localStorage";
import {logoutUser, setAuthUser} from "../redux/actions/authUserActions";
import axios from "axios";
import { AnyAction } from "redux";
import { Dispatch } from "react";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export type LoginCredentials = {
  email: string;
  password: string;
};

export type RegistrationData = {
  display_name: string;
} & LoginCredentials;

export type LoginResponseData = {
  idToken: string;
  refreshToken: string;
  displayName: string;
  email: string;
};

export type AuthTokenResponseData = {
  access_token: string;
  refresh_token: string;
};

export const refreshToken = async () => {
  const response = await axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/auth/token`, {
      grant_type: "refresh_token",
      refresh_token: localStorage.getItem(lsConstants.REFRESH_TOKEN),
    })
    .catch((error) => {
      console.log(
        "There has been an error with refreshing access token => ",
        error
      );
    });
  const data: AuthTokenResponseData = response?.data;
  localStorage.setItem(lsConstants.ID_TOKEN, data.access_token);
};

export const setAuthToken = async () => {
  axios.interceptors.request.use(function (config) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.Authorization = localStorage.getItem(lsConstants.ID_TOKEN);
    return config;
  });
};

export const login = async (
  dispatch: Dispatch<AnyAction>,
  LoginCredentials: LoginCredentials
) => {
  try {
    const response = await http.post(
      `${apiEndpoint}/auth/email`,
      LoginCredentials
    );
    const data: LoginResponseData = response.data;
    if (data.idToken) {
      localStorage.setItem(lsConstants.ID_TOKEN, data.idToken);
      localStorage.setItem(lsConstants.REFRESH_TOKEN, data.refreshToken);
      localStorage.setItem(lsConstants.NAME, data.displayName);
      localStorage.setItem(lsConstants.EMAIL, data.email);

      dispatch(setAuthUser(data));
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const register = async (registrationData: RegistrationData) => {
  try {
    const response = await http.post(`${apiEndpoint}/user`, registrationData);
    if (response.data.uid) {
      console.log("New user created successfully!");
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const logout = (dispatch: Dispatch<AnyAction>) => {
  localStorage.removeItem(lsConstants.REFRESH_TOKEN);
  localStorage.removeItem(lsConstants.NAME);
  localStorage.removeItem(lsConstants.EMAIL);
  localStorage.removeItem(lsConstants.ID_TOKEN);
  localStorage.removeItem(lsConstants.PROJECT);
  localStorage.removeItem(lsConstants.MAIN_TABLE);
  dispatch(logoutUser());
};
