import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import TableView from "./pages/TableView";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import { musStaySelector } from "./helper/employeeData";
import Organigram from "./pages/Organigram";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  CommentsReduxState,
  DataProcessingTypeReduxState,
  MainTableReduxState,
  TriggerOptionsReduxState
} from "./redux/reducers";
import {Comments, createComments} from "./services/commentDataService";
import {setComments} from "./redux/actions/commentsActions";
import {MainTableState} from "./redux/reducers/mainTableReducer";
import TriggerConfig from "./pages/TriggerConfig";
import KeyFileUpload from "./pages/KeyFileUpload";
import RequireAuth from "./components/RequireAuth";
import {DataProcessingTypeState} from "./redux/reducers/dataProcessingTypeReducer";
import {TriggerOptionsState} from "./redux/reducers/triggerOptionsReducer";
import TeamManagement from "./pages/TeamManagement";
import {setTriggerOrAiStatus} from "./services/employeeDataService";

// ----------------------------------------------------------------------

export default function Router() {

  const dispatch = useDispatch();

  const comments = useSelector<CommentsReduxState, Comments>(
      (state: CommentsReduxState) => state.comments
  )

  const [tableState, setTableState] = React.useState(useSelector<MainTableReduxState, MainTableState>(
      (state: MainTableReduxState) => state.mainTable
  ));

  const triggerOptionsState = useSelector<TriggerOptionsReduxState, TriggerOptionsState>(
      (state: TriggerOptionsReduxState) => state.triggerOptions
  )

  const dataProcessingTypeState = useSelector<DataProcessingTypeReduxState, DataProcessingTypeState>(
      (state: DataProcessingTypeReduxState) => state.dataProcessingType
  )

  const [dataProcessingType, setDataProcessingType] = React.useState<"ai" | "trigger">(dataProcessingTypeState.type);

  if(Object.keys(comments).length == 0){
    const dispatch = useDispatch();
    dispatch(setComments(createComments(tableState.employees)));
  }

  const applyTriggerToStatus = () => {
    setTableState(
        setTriggerOrAiStatus(
        dataProcessingTypeState,
        triggerOptionsState,
        tableState,
        dispatch,
    ));
  }

  React.useEffect(() => {
    applyTriggerToStatus()
  }, [dataProcessingType])

  return useRoutes([
    {
      path: "/main",
      element: <RequireAuth>
        <DashboardLayout
            dataProcessingType={dataProcessingType}
            setDataProcessingType={setDataProcessingType}
        />
      </RequireAuth>,
      children: [
        { path: "dashboard", element: <DashboardApp /> },
        {
          path: "employee",
          element: <TableView name={"All Employees"} tableState={tableState}/>,
        },
        {
          path: "must_stay",
          element: (
            <TableView
              name={"Must Stay"}
              employeeSelector={musStaySelector(true)}
              tableState={tableState}
            />
          ),
        },
        {
          path: "org_chart",
          element: <Organigram tableState={tableState}/>,
        },
        {
          path: "trigger",
          element: <TriggerConfig/>,
        },
        {
          path: "key_file",
          element: <KeyFileUpload/>
        },
        {
          path: "team",
          element: <TeamManagement/>
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
