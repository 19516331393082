import {EmployeeData} from "../../services/types";
import {Grid, Tooltip} from "@mui/material";

import {getBorderColorByScore} from "../../helper/colors";
import {useSelector} from "react-redux";
import {TriggerOptionsReduxState} from "../../redux/reducers";
import {TriggerOptionsState} from "../../redux/reducers/triggerOptionsReducer";
import {icons} from "../TriggerIcon";
import {triggerTitles} from "../../constants/triggerConfig/triggerInfo";

export default function TriggerIcons (employeeData: EmployeeData){

    const triggerOptionsState = useSelector<TriggerOptionsReduxState, TriggerOptionsState>(
        (state: TriggerOptionsReduxState) => state.triggerOptions
    )

    const iconColor = (dataKey: keyof TriggerOptionsState): string | undefined => {
        const value = parseFloat(String(employeeData[dataKey]));
        if(triggerOptionsState[dataKey] === undefined){
            return;
        }
        if(value > triggerOptionsState[dataKey].red){
            return getBorderColorByScore(0);
        }
        return;
    }

    return (
        <Grid container spacing={1} sx={{padding: 1}}>
            {
                icons.map(icon => {
                    const color = iconColor(icon.dataKey);
                    if(color){
                        return (
                            <Grid item xs={3}>
                                {<Tooltip title={triggerTitles[icon.dataKey].subTitle}>
                                    <icon.icon sx={{color: color}}/>
                                </Tooltip>}
                            </Grid>
                        )
                    }

                })
            }
        </Grid>
    )
}