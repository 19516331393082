import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { numberFormatter } from "../../../helper/charts";

export type KPINumberCardProps = {
  name: string;
  value: number | string;
  fontColor: string;
  backgroundColor: string;
  sx?: SxProps<Theme> | undefined;
};

export default function (props: KPINumberCardProps) {
  let value = props.value.toString();
  if (Number(value)) {
    value = numberFormatter(Number(value));
  }

  return (
      <Box
        sx={{
          p: 3,
          pb: -1,
          backgroundColor: "#FFFFFF", //props.backgroundColor,
          boxShadow: 1,
          borderRadius: 3,
          marginBottom: 2,
          marginRight: 4
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography
              variant="h4"
              sx={{
                color: props.fontColor,
                fontSize: 16,
                fontWeight: 700,
                // cursor: "pointer",
                padding: 1,
              }}
            >
              {props.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h4"
              sx={{
                color: props.fontColor,
                fontSize: 20,
                fontWeight: 700,
                padding: 1,
                float: "right",
              }}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Box>
  );
}
