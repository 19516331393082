import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import "react-circular-progressbar/dist/styles.css";
import { Grid, Container, Typography } from "@mui/material";
import { Page } from "../components/Page";
import { useSelector } from "react-redux";
import { MainTableReduxState } from "../redux/reducers";
import { MainTableState } from "../redux/reducers/mainTableReducer";
import { EmployeeData } from "../services/types";
import AgeFilterOptionCard from "../sections/dashboard/filterOptions/AgeFilterOptionCard";
import LossFilterOptionCard from "../sections/dashboard/filterOptions/LossFilterOptionCard";
import WeTainScoreFilterOptionCard from "../sections/dashboard/filterOptions/WeTainScoreFilterOptionCard";
import DepartmentFilterOptionCard from "../sections/dashboard/filterOptions/DepartmentFilterOptionCard";
import LocationFilterOptionCard from "../sections/dashboard/filterOptions/LocationFilterOptionCard";
import RoleFilterOptionCard from "../sections/dashboard/filterOptions/RoleFilterOptionCard";
import ReportingLineFilterOptionCard from "../sections/dashboard/filterOptions/ReportingLineFilterOptionCard";

// helper
import amplitude from "../helper/amplitude";
import {FilterOption} from "../sections/dashboard/filterOptions/types";

type FilterOptionsProps = {
  isOpen: boolean;
  setModalWindow: (param: boolean) => void;
  filterOptionConfigs: FilterOption[];
  callerName: string;
  employeeSelector?: (employee: EmployeeData[]) => EmployeeData[];
};

export const filterOptionCards = [
  {
    key: "department",
    component: DepartmentFilterOptionCard,
  },
  {
    key: "location",
    component: LocationFilterOptionCard,
  },
  {
    key: "role",
    component: RoleFilterOptionCard,
  },
  {
    key: "reportingLine",
    component: ReportingLineFilterOptionCard,
  },
  {
    key: "age",
    component: AgeFilterOptionCard,
  },
  {
    key: "projectedMonetaryLoss",
    component: LossFilterOptionCard,
  },
  {
    key: "status",
    component: WeTainScoreFilterOptionCard,
  },
];

export default function FilterOptions(props: FilterOptionsProps) {
  const reduxEmployeeData = useSelector<MainTableReduxState, MainTableState>(
    (state: MainTableReduxState) => state.mainTable
  ).employees;
  // if (props.employeeSelector) {
  //   reduxEmployeeData = props.employeeSelector(reduxEmployeeData);
  // }
  const handleClose = () => {
    amplitude().track("Closed Filter Option Popover")
    props.setModalWindow(false);
  };

  return (
    <Page title="EmployeeDetails">
      <Dialog
        // fullScreen
        // sx={{ maxWidth: '100% !important' }}
        maxWidth="lg"
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ background: "#f9f9fa", paddingBottom: 5 }}>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <Grid item xs={12} md={12} lg={12} padding={2}>
                    <ArrowCircleLeftOutlinedIcon
                      sx={{
                        float: "left",
                        color: "#0323f8",
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    />
                    <Typography
                      // variant="h4"
                      gutterBottom
                      sx={{
                        color: "#0323f8",
                        fontSize: 16,
                        fontWeight: 700,
                        cursor: "pointer",
                        paddingTop: 0.5,
                      }}
                      onClick={handleClose}
                    >
                      {props.callerName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <Grid item xs={12} md={12} lg={12} padding={2}>
                    <Button
                      variant="contained"
                      sx={{ fontSize: 10, background: "#0323f8" }}
                    >
                      Filter Options
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ float: "left", padding: 4.2, width: "100%" }}
                  ></Grid>
                </Grid>
              </Grid>
              <Grid container>
                {filterOptionCards.map(option => {
                  return (
                      <Grid
                          item
                          xs={8}
                          sm={4}
                          md={4}
                          sx={{
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: 1,
                          }}
                          key={option.key}
                      >{option.component({employeeData: reduxEmployeeData})}
                      </Grid>
                  )
                })}
              </Grid>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
