import ReactApexChart from "react-apexcharts";
import React from "react";
import { Card, Box } from "@mui/material";
import { ApexOptions } from "apexcharts";
// import { ApexOptions } from "apfexcharts";
// @typescript-eslint/no-explicit-any
export interface BarChartNegativeProps {
    options: ApexOptions;
    height: number;
    width: number;
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    title: string;
    fontColor?: string;
}

export default function BarChartNegative(props: BarChartNegativeProps) {
    const options: ApexOptions = {
        chart: {
            type: 'bar',
                height: 800,
                stacked: true
        },
        colors: ['#008FFB', '#FF4560'],
            plotOptions: {
            bar: {
                horizontal: true,
                    barHeight: '80%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1,
                colors: ["#fff"]
        },

        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
        yaxis: {
            min: -5,
                max: 5,
                title: {
                // text: 'Age',
            },
        },
        tooltip: {
            shared: false,
                x: {
                formatter: function (val: number) {
                    return val.toString()
                }
            },
            y: {
                formatter: function (val: number) {
                    return val + "%"
                }
            }
        },
        ...props.options
    }

    return (
        <Card>
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <ReactApexChart
                    height={props.height}
                    width={props.width}
                    options={options}
                    series={props.series}
                    type="bar"
                    toolbar={false}
                />
            </Box>
        </Card>
    );
}