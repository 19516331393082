import { SET_ORG_CHART_TRIGGER_ICONS } from "../constants/actionTypes";
import {OrgChartTriggerIconsAction} from "../actions/orgChartTriggerIconsActions";
import {SHOW_ORG_CHART_TRIGGER_ICONS} from "../constants/localStorage";


export type OrgChartTriggerIconsState = {
    show: boolean
};

export const initialState: OrgChartTriggerIconsState = {
    show: localStorage.getItem(SHOW_ORG_CHART_TRIGGER_ICONS) == "true"
};

// ==============================|| OrrChart Trigger Icons REDUCER ||============================== //
const orgChartTriggerIconsReducer = (
    state: OrgChartTriggerIconsState = initialState,
    action: OrgChartTriggerIconsAction
): OrgChartTriggerIconsState => {
    switch (action.type) {
        case SET_ORG_CHART_TRIGGER_ICONS: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default orgChartTriggerIconsReducer;