import * as React from "react";
import {
  Divider,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import {useSelector} from "react-redux";
import {CommentsReduxState} from "../redux/reducers";
import {Comments} from "../services/commentDataService";
import CommentEntry from "../layouts/dashboard/EmployeeDetails/CommentEntry";
import account from "../services/account";

export type CommentBoxProps = {
    employeeId: string,
}

export default function CommentBox(props: CommentBoxProps) {
  const comments = useSelector<CommentsReduxState, Comments>(
      (state: CommentsReduxState) => state.comments
  )

  const [commentsOnEmployee, setCommentsOnEmployee] = React.useState<JSX.Element[]>(
      comments[props.employeeId]?.map(comment => {
          return <CommentEntry {...comment}/>
      })
  );

  const [newComment, setNewComment] = React.useState("");

  const handleNewCommentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
          target: { value },
      } = event;
      setNewComment(value);
  }

  const handleAddComment = () => {
      const now = new Date().toLocaleString();
      comments[props.employeeId].push(
          {
              content: newComment,
              author: account.displayName,
              avatarUrl: account.photoURL,
              postedOn: now,
          }
      )
      setCommentsOnEmployee(
          comments[props.employeeId].map(comment => {
          return <CommentEntry {...comment}/>
        })
      )
  }

  return (
    <Paper style={{ margin: "-32px -20px", padding: "30px" }}>
      <Typography
        variant="h4"
        gutterBottom
        style={{
          color: "#0323f8",
          fontSize: 17,
          fontWeight: 700,
          marginBottom: 30,
        }}
      >
        Comments
      </Typography>
        {
            commentsOnEmployee
        }
      <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
      <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
      <Grid container wrap="nowrap" spacing={0}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="add comments"
          variant="outlined"
          onChange={handleNewCommentChange}
        />
        <Button sx={{ marginLeft: 1 }} variant="outlined" onClick={handleAddComment}>
          Add
        </Button>
      </Grid>
    </Paper>
  );
}
