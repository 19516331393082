import {SearchResultViewState} from "../reducers/searchResultViewReducer";

export type SearchResultViewAction = {
    type: string,
    payload: SearchResultViewState
}

export const setSearchResultView = (searchResultViewState: SearchResultViewState) => {
    return {
        type: "LOADED_MAIN_TABLE",
        payload: searchResultViewState,
    };
};