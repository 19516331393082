import {EmployeeData} from "./types";
import {faker} from "@faker-js/faker";

export type Comment = {
    author: string,
    content: string,
    avatarUrl: string,
    postedOn: string,
}

export type Comments = {
    [key: string]: Comment[]
}

const createRandomComment = (name: string, index: number): Comment | void => {
    return {
        author: faker.name.fullName(),
        content: `${name} is a wonderful teammate. But I have got the feeling a little more appreciation would not be bad.`,
        avatarUrl: `/static/mock-images/avatars/avatar_${index % 24}.jpg`,
        postedOn: '2022-11-03 12:53'
    }
}

export const createComments = (employeeData: EmployeeData[]): Comments => {
    const comments: Comments = {};
    employeeData.forEach((emp, ind) => {
        const comment = createRandomComment(emp.name, ind)
        if(comment){
            comments[emp.id] = [comment];
        }
    })
    return comments;
}