import { Link as RouterLink } from "react-router-dom";
import {Box, SxProps, Theme} from "@mui/material";

export interface Logoprop {
  disabledLink?: boolean;
  sx?: SxProps<Theme> | undefined;
}

const Logo: React.FC<Logoprop> = (props) => {
  const logo = (
    <Box
      component="img"
      src="/wetain_plain_blue.png"
      sx={{
        width: 2245 / 17,
        height: 1891 / 17,
        // marginLeft: 2,
        marginBottom: -3,
          ...props.sx
      }}
    />
  );

  if (props.disabledLink) {
    return <>{logo}</>;
  }
  return <RouterLink to="/main/dashboard">{logo}</RouterLink>;
};

export default Logo;
