import * as React from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";

import { SortingOrder } from "../../../interfaces/interface.sortingOrder";
import EmployeeTableColumns from "../../../interfaces/interface.employeeTableColumns";
import {HeadCell} from "../types";

export type PossibleTableHeadColumns = keyof EmployeeTableColumns |
    "impact" |
    "costs" |
    "kpi" |
    "icon" |
    "value" |
    "trigger" |
    "delta" |
    "created_at" |
    "permissions" |
    "admin" |
    "user_id" |
    "added_at"

export interface EnhancedTableProps {
  numSelected?: number | undefined;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: PossibleTableHeadColumns
  ) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  order: SortingOrder;
  orderBy: string;
  rowCount?: number | undefined;
  headCells: readonly HeadCell[]
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: PossibleTableHeadColumns) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{padding: 1}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
