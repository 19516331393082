import * as actionTypes from "../../redux/constants/actionTypes";
import { LoginResponseData } from "../../services/authService";
import {UserState} from "../reducers/authUserReducer";

export const setAuthUser = (authUser: LoginResponseData) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {authUser, isAuthenticated: true},
  };
};

export const loginUser = (userState: UserState) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {...userState, isAuthenticated: true},
  };
};

export const logoutUser = () => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {isAuthenticated: false, refreshToken: null},
  };
};


