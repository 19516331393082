import React from "react";
// mui
import CardContent from "@mui/material/CardContent";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
// pages
import EmployeeDetailsPopover from "../../pages/EmployeeDetailsPopover";
// sections
import TrafficStatusBar from "../TrafficStatusBar";
// services
import { EmployeeData } from "../../services/types";
// helper
import amplitude from "../../helper/amplitude";

// ---------------------------------------------------------------------------------- //

export default function EmployeeCard(props: EmployeeData) {
  const [employeePopoverOpen, setEmployeePopoverOpen] = React.useState(false);
  const handleDetailsClick = () => {
    setEmployeePopoverOpen(true);
      amplitude().track('Employee Details Clicked');
  };
  const CardDesign = {
    textAlign: "center",
    background: "#f8f5f1",
    borderRadius: 4,
  };
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: 0,
      }}
    >
      <Card sx={CardDesign}>
        <div
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
          }}
        >
          <img
            src={props.avatarUrl}
            alt={props.name}
            style={{
              borderRadius: "14px",
              width: "90px",
              marginTop: "20px",
            }}
          />
        </div>
        <CardContent>
          <div
            style={{
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 10,
              marginTop: -5,
              width: "35%",
            }}
          >
            <TrafficStatusBar status={props.status}/>
          </div>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            sx={{ fontWeight: 600 }}
          >
            {props.name}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            {props.role}
          </Typography>
          <Button
            variant="outlined"
            sx={{ fontWeight: 600 }}
            onClick={handleDetailsClick}
          >
            See details
          </Button>
          <EmployeeDetailsPopover
            employeeData={props}
            isOpen={employeePopoverOpen}
            setModalWindow={setEmployeePopoverOpen}
            setData={() => {return null}}
            handleSetMustStay={() => null}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
