import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import palette from "../theme/palette";

const RootStyle = styled("span", {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "variant",
})<{ variant: string; color: string }>(({ theme, variant, color }) => {
  const isLight = theme.palette.mode === "light";

  const styleFilled = (color: string) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color: palette[color].contrastText,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    backgroundColor: palette[color].main,
  });

  const styleOutlined = (color: string) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color: palette[color].main,
    backgroundColor: "transparent",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    border: `1px solid ${palette[color].main}`,
  });

  const styleGhost = (color: string) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color: palette[color][isLight ? "dark" : "light"],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    backgroundColor: alpha(palette[color].main, 0.16),
  });

  return {
    height: 16,
    minWidth: 16,
    lineHeight: 0,
    borderRadius: 20,
    cursor: "default",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    margin: "-4px 4px",
    padding: theme.spacing(0, 1),
    color: palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== "default"
      ? {
          ...(variant === "filled" && { ...styleFilled(color) }),
          ...(variant === "outlined" && { ...styleOutlined(color) }),
          ...(variant === "ghost" && { ...styleGhost(color) }),
        }
      : {
          ...(variant === "outlined" && {
            backgroundColor: "transparent",
            color: palette.text.primary,
            border: `1px solid ${palette.grey[500]}`, // _32
          }),
          ...(variant === "ghost" && {
            color: isLight ? palette.text.secondary : palette.common.white,
            backgroundColor: palette.grey[500], // _16
          }),
        }),
  };
});

type TrafficStatusProps = {
  children?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  color: "success" | "warning" | "error" | "custcolor";
  variant: "filled" | "outlined" | "ghost";
  sx?: object;
};

export default function TrafficStatus(props: TrafficStatusProps) {
  return (
    <RootStyle
      variant={props.variant}
      color={props.color}
      sx={{
        ...(props.startIcon && { pl: 0.75 }),
        ...(props.endIcon && { pr: 0.75 }),
        ...props.sx,
      }}
    ></RootStyle>
  );
}
