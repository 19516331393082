import { ReactNode } from "react";
// material
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

// ----------------------------------------------------------------------

interface PropsChildren {
  children?: ReactNode;
  // any props that come into the component
}

export default function ThemeProvider(Props: PropsChildren) {
  return (
    <StyledEngineProvider injectFirst>
      {/* <MUIThemeProvider> */}
      <CssBaseline />
      {Props.children}
      {/* </MUIThemeProvider> */}
    </StyledEngineProvider>
  );
}
