import * as React from "react";
import "../src/App.css";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import {Provider} from "react-redux";
import { store } from "./redux/store";
// tracking

// ----------------------------------------------------------------------


export default function App() {

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Provider store={store}>
        <Router />
      </Provider>
    </ThemeProvider>
  );
}

