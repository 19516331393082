// action - state management
import * as actionTypes from "../constants/actionTypes";
import * as lsConstants from "../constants/localStorage";

export type UserState = {
  isAuthenticated: boolean;
  refreshToken: string | null;
  name: string | null;
  email: string | null;
  project: string | null;
};

type AuthPayload = {
  displayName: string;
  email: string;
};

type Action = {
  type: string;
  payload: AuthPayload;
};

const initialState: UserState = {
  isAuthenticated: false,
  refreshToken: localStorage.getItem(lsConstants.REFRESH_TOKEN),
  name: localStorage.getItem(lsConstants.NAME),
  email: localStorage.getItem(lsConstants.EMAIL),
  project: localStorage.getItem(lsConstants.PROJECT),
};

// const setInitialState = async (initialState: UserState): Promise<UserState> => {
//   if (initialState.refreshToken) {
//     try{
//       await setAuthToken();
//     } catch (_) {
//       return initialState;
//     }
//     return {...initialState, isAuthenticated: true}
//   }
//   return initialState
// }
//
// initialState = await setInitialState(initialState);

// ==============================|| USER REDUCER ||============================== //

const authUserReducer = (
  state: UserState = initialState,
  action: Action
): UserState => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        name: action.payload.displayName,
        email: action.payload.email,
      };
    case actionTypes.SIGN_OUT_SUCCESS:
      return { ...state, isAuthenticated: false, name: "", email: "", refreshToken: "" };
    default: {
      return state;
    }
  }
};

export default authUserReducer;
