import {Badge, Box, Chip, Grid, Typography} from "@mui/material";
import * as React from "react";
import {Dispatch, SetStateAction} from "react";
import {MaiLContent} from "../../services/types";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {numberFormatter} from "../../helper/charts";
import {getActionBackgroundColorByStatus, getBorderColorByScore} from "../../helper/colors";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {ActionData} from "../../helper/types";

export type RecommendedActionCardProps = {
    setOpen: (arg0: boolean) => void,
    setMailContent: Dispatch<SetStateAction<MaiLContent>>,
    actionData: ActionData,
    setTaskStatus: (value: "open" | "pending" | "done") => void;
}

export default function RecommendedActionCard(props: RecommendedActionCardProps){

    const [cardBoxShadow, setCardBoxShadow] = React.useState(3);

    const [actionStatus, setActionStatus] = React.useState(props.actionData.status);

    const getStatusScore = (): number => {
        if(actionStatus == "open"){
            return 0;
        }
        if(actionStatus  == "pending"){
            return 60;
        }
        return 100
    }

    const rotateStatus = () => {
        const set = (value: "open" | "pending" | "done") => {
            props.setTaskStatus(value);
            setActionStatus(value)
        }

        if(actionStatus == "open"){
            set("pending")
        }
        if(actionStatus == "pending"){
            set("done");
        }
        if(actionStatus == "done"){
            set("open")
        }
    }

    return (
        <div style={{marginLeft: 20, marginBottom: 20}}>
        <Badge
            invisible={!props.actionData.costs}
            // style={{cursor: "pointer"}}
            color="secondary"
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            overlap="circular"
            badgeContent={`~${numberFormatter(numberFormatter(props.actionData.costs))}€`}
        >
            <Box style={{  transform: 'translate(-10px, 20px)'}}>
            <Card
                sx={{
                    display: 'flex',
                    border: 1,
                    borderRadius: 3,
                    // padding: 1,
                    // margin: 1,
                    boxShadow: cardBoxShadow,
                    backgroundColor: getActionBackgroundColorByStatus(actionStatus),
                    ':hover': {
                        cursor: "pointer"
                    }
                }}
                onMouseOver={() => setCardBoxShadow(20)}
                onMouseOut={() => setCardBoxShadow(3)}
                onClick={() => {
                    props.setMailContent(props.actionData.mailContent)
                    props.setOpen(true)
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={8} md={12}>
                        <Box>
                            <CardContent sx={{
                                // flex: '1 0 auto',
                                padding: 2,
                                paddingTop: 2,
                                paddingRight: 0,
                                marginBottom: 0,
                                marginRight: 2,
                                maxHeight: 150,
                                overflow: "auto",
                            }}>
                                <Chip 
                                    label={actionStatus}
                                    sx={{
                                        color: getBorderColorByScore(getStatusScore()), 
                                        backgroundColor: "#FFFFFF",
                                        border: 1,
                                        marginBottom: 2
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        rotateStatus()
                                    }}
                                    onDelete={() => rotateStatus()}
                                    deleteIcon={
                                        <KeyboardArrowRightIcon
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            color={getBorderColorByScore(getStatusScore())}
                                            sx={{
                                                margin: -2,
                                                height: 0.5,
                                            }}
                                        />
                                    }
                                />
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: "#000000",
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    {props.actionData.title}
                                </Typography>
                            </CardContent>
                        </Box>
                    </Grid>
                        {/*<Grid item xs={4}>
                            {<Grid
                                container
                                direction={"column"}
                                spacing={0}
                                sx={{
                                    color: "#000000",
                                    fontSize: 16,
                                    fontWeight: 500,
                                    marginTop: -2,
                                    marginLeft: 10,
                                    marginRight: -2,
                                    marginBottom: -2,
                                    flex:1,
                                    // alignItems:'center',
                                    alignSelf:'stretch'
                                }}
                            >
                                <Box
                                    sx={{
                                        // alignSelf:'stretch',
                                        flex:1,
                                        // alignItems:'center',
                                        // justifyContent:'right',
                                        alignSelf:'stretch'
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            alignContent: "center",
                                            borderRadius: 0,
                                            border: 0.2,
                                            marginRight: -4,
                                            backgroundColor: getColorByScore(100),
                                            alignSelf:'stretch'
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: "#FFFFFF",
                                                fontSize: 16,
                                                fontWeight: 500,
                                            }}
                                        >
                                            Retention Likeliness +{numberFormatter(props.impact)}%
                                        </Typography>
                                    </CardContent>
                                </Box>*/}
                                {/*<Box
                                    sx={{
                                        // alignSelf:'stretch',
                                        flex:1,
                                        // alignItems:'center',
                                        // justifyContent:'right',
                                        alignSelf:'stretch'
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            alignContent: "center",
                                            borderRadius: 0,
                                            border: 0.2,
                                            marginRight: -10,
                                            backgroundColor: "rgba(216,30,52,0.38)",
                                            alignSelf:'stretch'
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            gutterBottom
                                            style={{
                                                color: "#000000",
                                                fontSize: 16,
                                                fontWeight: 500,
                                                marginTop: 20,
                                            }}
                                        >
                                            ~{numberFormatter(numberFormatter(props.costs))}€
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Grid>}
                    </Grid>*/}
                </Grid>
            </Card>
            </Box>
        </Badge>
        </div>
    )
}