import React from "react";
import {Grid} from "@mui/material";
import { EmployeeData } from "../../../services/types";
import RangeSlider, {
  RangeSliderProps,
} from "../../../components/filter/RangeSlider";
import FilterOptionCard from "./FilterOptionCard";

export type NumericFilterOptionConfig = {
  min: number;
  max: number;
};

export type NumericFilterOptionListConfig = {
  key: keyof EmployeeData;
} & NumericFilterOptionConfig;

export type NumericFilterOptionCardProps = {
  name: string;
  tableKey: keyof EmployeeData;
  rangeSliderProps: RangeSliderProps;
  employeeData: Array<EmployeeData>;
};

export default function NumericFilterOptionCard(
  props: NumericFilterOptionCardProps
) {

  const component = (
    <Grid sx={{ marginRight: 10, marginBottom: -2.3 }}>
      <RangeSlider {...props.rangeSliderProps} />
    </Grid>
  );

  return <FilterOptionCard {...props} component={component} />;
}
