import {NumericFilterOptionListConfig} from "../../../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import {StringFilterOptionListConfig} from "../../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import {StringQueryFilterOptionConfig} from "./UserListToolbar";
import {EmployeeData} from "../../../services/types";
import {useSelector} from "react-redux";
import {MainTableReduxState} from "../../../redux/reducers";
import {MainTableState} from "../../../redux/reducers/mainTableReducer";
import * as React from "react";
import {applySortFilter, getComparator} from "../../../helper/employeeTable";
import {Grid} from "@mui/material";
import EmployeeCard from "../EmployeeCard";

export type ResultCardsViewProps = {
    employeeSelector?: (employee: EmployeeData[]) => EmployeeData[];
    filterOptions: Array<
        | NumericFilterOptionListConfig
        | StringFilterOptionListConfig
        | StringQueryFilterOptionConfig
    >;
}

export default function ResultCardsView(props: ResultCardsViewProps) {
    let reduxEmployeeData = useSelector<MainTableReduxState, MainTableState>(
        (state: MainTableReduxState) => state.mainTable
    ).employees;
    if (props.employeeSelector) {
        reduxEmployeeData = props.employeeSelector(reduxEmployeeData);
    }

    // const [filteredEmployees, setFilteredEmployees] = React.useState<
    //   EmployeeData[]
    // >([]);

    const filteredEmployees = applySortFilter(
        reduxEmployeeData,
        getComparator("asc", "status"),
        props.filterOptions
    )

    return (

        <Grid container spacing={3} sx={{paddingBottom: 5}}>
            {filteredEmployees.map((employee) => {
                return <EmployeeCard {...employee} />;
            })}
        </Grid>
    )
}