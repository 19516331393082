import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {SetStateAction} from "react";
import {SxProps, Theme} from "@mui/material";

export type BasicDropDownMenuProps = {
  setSelectedValueFunction: (value: SetStateAction<string | "employees" | "departments" | "sub departments">) => void;
  menuItems: string[];
  name: string,
  sx?: SxProps<Theme> | undefined
};

export default function BasicDropDownMenu(props: BasicDropDownMenuProps) {
  const [selectedValue, setSelectedValue] = React.useState(props.menuItems[0]);

  const handleChange = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    setSelectedValue(selected);
    props.setSelectedValueFunction(selected);
  };

  return (
    <Box sx={{ minWidth: 120, ...props.sx }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="select"
          onChange={handleChange}
        >
          {props.menuItems.map((itemName) => (
            <MenuItem key={itemName} value={itemName}>
              {itemName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
