import {Grid, Typography} from "@mui/material";
import EmployeeKPICard from "../../EmployeeKPICard";
import {EmployeeData} from "../../../services/types";
import RecommendedAction from "../../../layouts/dashboard/EmployeeDetails/RecommendetAction";
import * as React from "react";
import TriggerKPICard from "../../TriggerKPICard";
import {TriggerOptionsState} from "../../../redux/reducers/triggerOptionsReducer";
import {useSelector} from "react-redux";
import {DataProcessingTypeReduxState} from "../../../redux/reducers";
import {DataProcessingTypeState} from "../../../redux/reducers/dataProcessingTypeReducer";
import TriggerTable from "./TriggerTable";
import LabeledSwitch from "../../../components/LabeledSwitch";

export type OverviewProps = {
    employeeData: EmployeeData,
}

export type TriggerCardData = {
    name: string,
    value: number,
    key: keyof TriggerOptionsState,
    metric: string,
}

export default function Overview(props: OverviewProps) {

    const dataProcessingTypeState = useSelector<DataProcessingTypeReduxState, DataProcessingTypeState>(
        (state: DataProcessingTypeReduxState) => state.dataProcessingType
    )

    const [actionsOpen, setActionsOpen] = React.useState(false);

    const [actionKpi, setActionKpi] = React.useState("lol");

    const [tableView, setTableView] = React.useState(true);

    const triggerCards: TriggerCardData[] = [
        {
            name: "Peers Salary Gap",
            value: props.employeeData.salaryDevFromPeers,
            key: "salaryDevFromPeers",
            metric: "%",
        },
        {
            name: "Gender Pay Gap",
            value: props.employeeData.salaryGenderDevFromPeers,
            key: "salaryGenderDevFromPeers",
            metric: "%",
        },
        {
            name: "Years unpromoted",
            value: props.employeeData.yearsUnpromoted,
            key: "yearsUnpromoted",
            metric: "years",
        },
        {
            name: "Peers Quit under Leader",
            value: props.employeeData.percentQuittingUnderLeader,
            key: "percentQuittingUnderLeader",
            metric: "%",
        },
        {
            name: "Peers Quitting",
            value: props.employeeData.percentQuittingPeers,
            key: "percentQuittingPeers",
            metric: "%",
        },
        {
            name: "Male Team Members",
            value: props.employeeData.percentMaleTeam,
            key: "percentMaleTeam",
            metric: "%",
        },
        {
            name: "Female Team Members",
            value: props.employeeData.percentFemaleTeam,
            key: "percentFemaleTeam",
            metric: "%",
        },
        {
            name: "Days sick",
            value: props.employeeData.sicknessDays,
            key: "sicknessDays",
            metric: "days",
        },
    ]

    const cards: {name: string, score: number, lineChartData: number[], key: string}[] = [
        {
            name: "COMPENSATION SATISFACTION",
            score: props.employeeData.compensationScore,
            lineChartData: props.employeeData.compensationScoreHistory,
            key: "compensationScore"
        },
        {
            name: "ENGAGEMENT",
            score: props.employeeData.engagementScore,
            lineChartData: props.employeeData.engagementScoreHistory,
            key: "engagementScore",
        },
        {
            name: "PERSONAL DEVELOPMENT",
            score: props.employeeData.personalDevelopmentScore,
            lineChartData: props.employeeData.personalDevelopmentScoreHistory,
            key: "personalDevelopmentScore",
        },
        {
            name: "FEEDBACK",
            score: props.employeeData.feedbackScore,
            lineChartData: props.employeeData.feedbackScoreHistory,
            key: "feedbackScore"
        },
        {
            name: "PERSONAL DETAILS",
            score: props.employeeData.socialsScore,
            lineChartData: props.employeeData.socialsScoreHistory,
            key: "socialScore"
        },
        {
            name: "PEER GROUP",
            score: props.employeeData.peerGroupScore,
            lineChartData: props.employeeData.peerGroupScoreHistory,
            key: "peerGroupScore"
        },
    ];

    const handleKPICardClick = (kpi: string) => {
        setActionKpi(kpi);
        setActionsOpen(true);
    }

    const triggerCardComponents = triggerCards.map((card) => {
        // console.log(card.value)
        // console.log(props.employeeData.salaryDevFromPeers)
        return <TriggerKPICard
            {...card} dataKey={card.key}
            onClick={handleKPICardClick}
        />;
    })

    const triggerTable = <TriggerTable
        triggers={triggerCards}
        onClick={() => null}
    />

    const aiCardComponents = cards.map((card) => {
        return <EmployeeKPICard
            {...card} kpi={card.key}
            onClick={handleKPICardClick}
        />;
    })

    return(
        <Grid item xs={12} md={12} lg={12}>
            <Grid container>
                <Grid item xs={9}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            color: "#000000",
                            marginLeft: 20,
                            fontSize: 17,
                            fontWeight: 700,
                        }}
                    >
                        Employee Insights
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {
                        dataProcessingTypeState.type == "trigger" ?
                            <LabeledSwitch
                                label={tableView ? "Card View" : "Table View"}
                                onClick={() => setTableView(!tableView)}
                                defaultValue={tableView}
                            /> : null
                    }
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ float: "left", padding: 2 }}
            >
                {dataProcessingTypeState.type == "trigger" ? (
                    tableView ? triggerTable : triggerCardComponents
                ) : (
                    aiCardComponents
                )}
            </Grid>
            <Grid item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{marginTop: 10}}
            >
                <RecommendedAction
                    employeeData={props.employeeData}
                    setOpen={setActionsOpen}
                    open={actionsOpen}
                    kpi={actionKpi}
                    setActionKpi={setActionKpi}
                />
            </Grid>
        </Grid>
    )
}