import * as amplitude from '@amplitude/analytics-browser';


export default function (): {
    track: (eventInput: (string | import("@amplitude/analytics-types").BaseEvent),
            eventProperties?: (Record<string, never> | undefined),
            eventOptions?: (import("@amplitude/analytics-types").EventOptions | undefined)
    ) => import("@amplitude/analytics-types").AmplitudeReturn<import("@amplitude/analytics-types").Result>
} {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    amplitude.init(process.env.REACT_APP_AMPLITUDE_ID!);
    amplitude.setGroup("deployment", process.env.NODE_ENV ? "dev" : "prod")
    return {
        track: amplitude.track
    }
}
