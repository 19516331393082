import { Popover } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

export interface MenuPopoverprop {
  children: React.ReactNode;
  sx: object;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  other?: object;
}

export default function MenuPopover(props: MenuPopoverprop) {
  return (
    <Popover
      open={props.open}
      onClose={props.onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: "inherit",
          ...props.sx,
        },
      }}
      {...props.other}
    >
      <ArrowStyle className="arrow" />

      {props.children}
    </Popover>
  );
}
