import React, {LegacyRef} from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useDrop} from "react-dnd";
import {getBorderColorByScore} from "../../../helper/colors";
import {
    Avatar,
    Badge,
    Card,
    CardHeader,
    IconButton,
    Tooltip, Typography
} from "@mui/material";
import _ from "lodash";
import BusinessIcon from "@mui/icons-material/Business";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useStyles} from "./styles";
import {TeamLeadCardProps} from "./types";
import amplitude from "../../../helper/amplitude";
import TriggerIcons from "../../../components/organigram/TriggerIcons";

// Group icon -> https://www.freepik.com/


export default function TeamLeadCard(props: TeamLeadCardProps & {borderColor? : string | undefined}) {
    const ref = React.createRef();
    const classes = useStyles();

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // // @ts-ignore
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const [{canDrop, isOver}, drop] = useDrop({
        accept: "account",
        drop: () => ({name: props.org.name}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });
    const isActive = canDrop && isOver;
    let backgroundColor = getBorderColorByScore(props.org.status) // "white";
    if (isActive) {
        backgroundColor = "#ddffd2";
    } else if (canDrop) {
        backgroundColor = "#ffeedc";
    }
    const track = () => {
        let message = "";
        if(props.collapsed){
            message += "Unfolded "
        } else {
            message += "Collapsed "
        }
        if(props.org.type){
            message += `${props.org.type} ${props.org.name}`
        } else {
            message += "A Leading Employee"
        }
        amplitude().track(message);
    }
    return (
        <div ref={ref as unknown as LegacyRef<HTMLDivElement>}>
            <Card
                variant="outlined"
                className={classes.root}
                ref={drop}
                // style={{backgroundColor}}
                sx={{
                    border: 5,
                    borderColor: props.borderColor ? props.borderColor : backgroundColor,
                }}
            >
                <CardHeader
                    avatar={
                        <Tooltip
                            title={`${_.size(
                                props.org.leadingPositionChildRelationship
                            )} Sub Profile, ${_.size(props.org.account)} Sub Account`}
                            arrow
                        >
                            <Badge
                                style={{cursor: "pointer"}}
                                color="secondary"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                                showZero
                                invisible={!props.collapsed}
                                overlap="circular"
                                badgeContent={props.org.numTotalSubEmployees}
                                onClick={() => {
                                    // props.onCollapse(ref);
                                    // if(!["department", "subDepartment"].includes(String(props.org.type))){
                                    //     props.setDetailsEmployeeData(props.org)
                                    //     props.setDetailsOpen(true);
                                    // }
                                    props.setDetailsEmployeeData(props.org)
                                    props.setDetailsOpen(true);
                                }}
                            >
                                <Avatar className={classes.avatar} src={props.org.avatarUrl}>
                                    <BusinessIcon color="primary"/>
                                </Avatar>
                            </Badge>
                        </Tooltip>
                    }
                    title={props.org.name}
                    /*action=
                        <IconButton size="small" onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>
                    */
                />

                {/*<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <BusinessIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Add Sub Profile"/>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <AccountBalanceIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary="Add Sub Account"/>
                    </MenuItem>
                </Menu>*/}
                {props.org.role ? <Typography fontSize={12} marginBottom={2}>{props.org.role}</Typography> : null}
                {/*numberFormatter(props.org.status)*/}
                {props.showTriggerIcons ? <TriggerIcons {...props.org}/> : null}
                <IconButton
                    size="small"
                    onClick={() => {
                        props.onCollapse(ref)
                        track();
                    }
                }
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: !props.collapsed
                    })}
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </Card>
        </div>
    );
}