import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export type TabPanelProps = {
    children: JSX.Element,
    value: number,
    index: number,
    other?: object,
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, marginLeft: -5}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


export default TabPanel;