import { FilterOptionCardProps } from "./types";
import StringFilterOptionCard from "../../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import * as React from "react";

export default function DepartmentFilterOptionCard(
  props: FilterOptionCardProps
) {
  return (
    <StringFilterOptionCard
        key={"departmentFilterOptionCard"}
      name={"Department"}
      tableKey={"department"}
      employeeData={props.employeeData}
    />
  );
}
