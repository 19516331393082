import {Typography} from "@mui/material";
import * as React from "react";
// import {EmployeeData} from "../../services/types";

// export type RecommendedActionHeadProps = {
//     employeeData: EmployeeData
// }

export default function RecommendedActionHead(){
    return (
        // <Box
        //     color={"#0323f8"}
        //     sx={{backgroundColor: getBackgroundColorByScore(props.employeeData.status)}}
        //     borderRadius={1}
        //     border={1}
        //     borderColor={"#d56f6f"}
        // >
            <Typography
                variant="h4"
                gutterBottom
                style={{
                    color: "#000000",
                    fontSize: 17,
                    fontWeight: 700,
                    marginTop: 10,
                    marginLeft: 150
                }}
            >
                Recommended Action
            </Typography>
        // </Box>
    )
}