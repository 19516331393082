import React from "react";
import { Grid } from "@mui/material";
import { EmployeeData } from "../../../services/types";
import MultipleSelect from "../../../components/filter/MultipleSelect";
import FilterOptionCard from "./FilterOptionCard";

export type StringFilterOptionConfig = {
  checkedItems: Array<string>;
};

export type StringFilterOptionListConfig = {
  key: keyof EmployeeData;
} & StringFilterOptionConfig;

export type StringFilterOptionCardProps = {
  name: string;
  tableKey: keyof EmployeeData;
  employeeData: Array<EmployeeData>;
};

export default function StringFilterOptionCard(
  props: StringFilterOptionCardProps
) {

  const component = (
    <Grid sx={{ marginRight: 10 }}>
      <MultipleSelect
        name={props.name}
        employeeData={props.employeeData}
        tableKey={props.tableKey}
      />
    </Grid>
  );

  return <FilterOptionCard {...props} component={component}/>;
}
