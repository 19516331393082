import { SET_SEARCH_RESULT_VIEW } from "../constants/actionTypes";
import {SearchResultViewAction} from "../actions/searchResultViewActions";


export type SearchResultViewState = {
    tableView: boolean
};

export const initialState: SearchResultViewState = {
    tableView: true
};
//     JSON.parse(
//   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//   localStorage.getItem(lsConstants.MAIN_TABLE)!
// );

// ==============================|| PROJECT REDUCER ||============================== //
const mainTableReducer = (
    state: SearchResultViewState = initialState,
    action: SearchResultViewAction
): SearchResultViewState => {
    switch (action.type) {
        case SET_SEARCH_RESULT_VIEW: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default mainTableReducer;