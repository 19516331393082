import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Grid, SxProps, Tabs} from '@mui/material';
import TabPanel from "../../../components/TabPanel";
import {EmployeeData} from "../../../services/types";
import Overview from "../../../sections/dashboard/employeeDetailsPopover/Overview";
import {Theme} from "@emotion/react";
import Insights from "../../../sections/dashboard/employeeDetailsPopover/Insights";
import amplitude from "../../../helper/amplitude";
import BasicTrafficLight from "../../../components/TrafficLight";


const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export type InsightTabsProps = {
    employeeData: EmployeeData,
    sx?:  SxProps<Theme>
}

const indexName: {[key: number]: string} = {
    0: "Overview",
    1: "Insights",
}

export default function InsightTabs(props: InsightTabsProps){
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: React.SetStateAction<number>) => {
        amplitude().track(`Employee Details ${indexName[newValue as number]} Tab Clicked`);
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '110%', ...props.sx}}>
            <Grid container spacing={53}>
                <Grid item>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Overview" {...a11yProps(0)} />
                            {/*<Tab label="Insights" {...a11yProps(1)} />*/}
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item>
                    <div style={{marginTop: 10, transform: 'rotate(180deg)'}}>
                        <BasicTrafficLight
                            status={props.employeeData.status}
                        />
                    </div>
                </Grid>
            </Grid>
            <TabPanel value={value} index={0}>
               <Overview employeeData={props.employeeData}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Insights employeeData={props.employeeData}/>
            </TabPanel>
        </Box>
    );
}


