import { combineReducers } from "redux";
import authUserReducer, { UserState } from "./authUserReducer";
import projectReducer, { ProjectState } from "./projectReducer";
import mainTableReducer, { MainTableState } from "./mainTableReducer";
import {
  filterOptionsReducer, FilterOptionsState, mustStayFilterOptionsReducer,
} from "./filterOptionsReducer";
import searchResultViewReducer, {SearchResultViewState} from "./searchResultViewReducer";
import {Comments} from "../../services/commentDataService";
import commentsReducer from "./commentsReducer";
import filterOptionsTypeReducer, {FilterOptionsTypeState} from "./filterOptionsTypeReducer";
import triggerOptionsReducer, {TriggerOptionsState} from "./triggerOptionsReducer";
import dataProcessingTypeReducer, {DataProcessingTypeState} from "./dataProcessingTypeReducer";
import orgChartTriggerIconsReducer, {OrgChartTriggerIconsState} from "./orgChartTriggerIconsReducer";

export interface UserReduxState {
  authUser: UserState;
}

export interface ProjectReduxState {
  project: ProjectState;
}

export interface TriggerOptionsReduxState {
  triggerOptions: TriggerOptionsState;
}

export interface OrgChartTriggerIconsReduxState {
  orgChartTriggerIcons: OrgChartTriggerIconsState
}


export interface MainTableReduxState {
  mainTable: MainTableState;
}

export type FilterOptionsReduxState = {
  filterOptions: FilterOptionsState;
};

export type MustStayFilterOptionsReduxState = {
  mustStayFilterOptions: FilterOptionsState;
};

export type FilterOptionsTypeReduxState = {
  filterOptionsType: FilterOptionsTypeState;
};

export type SearchResultViewReduxState = {
  searchResultView: SearchResultViewState
}

export type CommentsReduxState = {
  comments: Comments
};

export type DataProcessingTypeReduxState = {
  dataProcessingType: DataProcessingTypeState
}

// ==============================|| COMBINE REDUCER ||============================== //

const reducers = combineReducers({
  authUser: authUserReducer,
  project: projectReducer,
  mainTable: mainTableReducer,
  filterOptions: filterOptionsReducer,
  mustStayFilterOptions: mustStayFilterOptionsReducer,
  filterOptionsType: filterOptionsTypeReducer,
  searchResultView: searchResultViewReducer,
  comments: commentsReducer,
  triggerOptions: triggerOptionsReducer,
  dataProcessingType: dataProcessingTypeReducer,
  orgChartTriggerIcons: orgChartTriggerIconsReducer,
});

export default reducers;
