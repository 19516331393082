import http from "./httpService";
import * as lsConstants from "../redux/constants/localStorage";
import { setProject } from "../redux/actions/projectActions";
import { Dispatch } from "react";
import { AnyAction } from "redux";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const projectEndpoint = "/project";

export type ProjectData = {
  id: string;
  name: string;
  description: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
};

export type ProjectPostBody = {
  name: string;
  description: string;
};

export const getProjects = async (): Promise<ProjectData[]> => {
  const res = await http.get(`${apiEndpoint}${projectEndpoint}`);
  return res.data["projects"];
};

export const createProject = async (
  projectPostBody: ProjectPostBody
): Promise<ProjectData> => {
  const res = await http.post(
    `${apiEndpoint}${projectEndpoint}`,
    projectPostBody
  );
  return res.data;
};

export const getMainProject = async (dispatch: Dispatch<AnyAction>) => {
  try {
    const projects = await getProjects();
    let projectData: ProjectData;
    if (projects.length > 0) {
      projectData = projects[0];
    } else {
      const projectPostBody: ProjectPostBody = {
        name: "first_project",
        description: "A first project.",
      };
      projectData = await createProject(projectPostBody);
    }
    localStorage.setItem(lsConstants.PROJECT, projectData.id);
    dispatch(setProject(projectData));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
