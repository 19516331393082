import * as actionTypes from "../constants/actionTypes";

export type DataProcessingTypeState = {
    type: "trigger" | "ai";
};

export const initialState: DataProcessingTypeState = {
    type: "trigger"
};

type Action = {
    type: string;
    payload: DataProcessingTypeState;
};

// ==============================|| PROJECT REDUCER ||============================== //
const dataProcessingTypeReducer = (
    state: DataProcessingTypeState = initialState,
    action: Action
): DataProcessingTypeState => {
    switch (action.type) {
        case actionTypes.SET_DATA_PROCESSING_TYPE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default dataProcessingTypeReducer;