import Iconify from "../../components/Iconify";

const getIcon = (name: string) => (
  <Iconify icon={name} sx={{ width: 22, height: 22 }} />
);

const navConfig = [
  {
    title: "Org Chart",
    path: "/main/org_chart",
    icon: getIcon("material-symbols:lan"),
  },
  {
    title: "All Employees",
    path: "/main/employee",
    icon: getIcon("clarity:employee-line"),
  },
  {
    title: "Must Stay",
    path: "/main/must_stay",
    icon: getIcon("material-symbols:add-circle"),
  },
  {
    title: "Dashboard",
    path: "/main/dashboard",
    icon: getIcon("bytesize:home"),
  },
  {
    title: "Trigger",
    path: "/main/trigger",
    icon: getIcon("material-symbols:warning-outline-rounded"),
  },
];

export default navConfig;
