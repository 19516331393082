import React from "react";
import {Tree, TreeNode, TreeProps} from "react-organizational-chart";
import _ from "lodash";
import {NodeProps} from "./types";
import LeadCard from "../../layouts/dashboard/organigram/LeadCard";
import {TeamLeadType, TeamMemberType} from "../../layouts/dashboard/organigram/types";
import TeamMemberCard from "../../layouts/dashboard/organigram/TeamMemberCard";

export default function Node(props: NodeProps) {
    const [collapsed, setCollapsed] = React.useState(props.o.collapsed);
    const handleCollapse = () => {
        setCollapsed(!collapsed);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // console.log("set", ref.current.offsetLeft, ref.current.offsetTop)
        // console.log(props.mapState)
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // // @ts-ignore
        // const x = ref.current?.offsetLeft;
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // // @ts-ignore
        // const y = ref.current?.offsetTop;
        // props.setMapState({
        //     scale: props.mapState.scale,
        //     translation: {
        //         x: x, // props.mapState.translation.x + (!collapsed ? (x) : -x),
        //         y: y, // props.mapState.translation.x + (!collapsed ? (y) : -y),
        //     }
        // })
    };
    React.useEffect(() => {
        props.o.collapsed = collapsed;
    });
    const T = props.parent
        ? TreeNode
        : (props: TreeProps) => (
            <Tree
                {...props}
                lineWidth={"2px"}
                lineColor={"#bbc"}
                lineBorderRadius={"12px"}
            >
                {props.children}
            </Tree>
        );
    return collapsed ? (
        <T
            label={
                <LeadCard
                    org={props.o}
                    onCollapse={handleCollapse}
                    collapsed={collapsed}
                    setDetailsEmployeeData={props.setDetailsEmployeeData}
                    setDetailsOpen={props.setDetailsOpen}
                    showTriggerIcons={props.showTriggerIcons}
                />
            }
            children={null}
        />
    ) : (
        <T
            label={
                <LeadCard
                    org={props.o}
                    onCollapse={handleCollapse}
                    collapsed={collapsed}
                    setDetailsEmployeeData={props.setDetailsEmployeeData}
                    setDetailsOpen={props.setDetailsOpen}
                    showTriggerIcons={props.showTriggerIcons}
                />
            }
        >
            {_.map(props.o.account, (a: TeamMemberType) => (
                <TreeNode
                    label={
                        <TeamMemberCard
                            {...a}
                            setDetailsOpen={props.setDetailsOpen}
                            setEmployeeDetails={props.setDetailsEmployeeData}
                            showTriggerIcons={props.showTriggerIcons}
                        />
                    }
                >
                    {/*<TreeNode label={<Product {...a.product} />} />*/}
                </TreeNode>
            ))}
            {_.map(props.o.leadingPositionChildRelationship, (c: TeamLeadType) => (
                <Node
                    o={c}
                    parent={props.o}
                    setMapState={props.setMapState}
                    mapState={props.mapState}
                    setDetailsEmployeeData={props.setDetailsEmployeeData}
                    setDetailsOpen={props.setDetailsOpen}
                    showTriggerIcons={props.showTriggerIcons}
                />
            ))}
        </T>
    );
}