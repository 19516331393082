export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SET_PROJECT = "SET_PROJECT";
export const LOADED_MAIN_TABLE = "LOADED_MAIN_TABLE";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_MUST_STAY_FILTER_OPTIONS = "SET_MUST_STAY_FILTER_OPTIONS";
export const SET_SEARCH_RESULT_VIEW = "SET_SEARCH_RESULT_VIEW";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_FILTER_OPTIONS_TYPE = "SET_FILTER_OPTIONS_TYPE";
export const SET_TRIGGER_OPTIONS = "SET_TRIGGER_OPTIONS";
export const SET_DATA_PROCESSING_TYPE = "SET_DATA_PROCESSING_TYPE";
export const SET_ORG_CHART_TRIGGER_ICONS = "SHOW_ORG_CHART_TRIGGER_ICONS";
