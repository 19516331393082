import { FilterOptionCardProps } from "./types";
import StringFilterOptionCard from "../../../layouts/dashboard/filterOptions/StringFilterOptionCard";
import * as React from "react";

export default function LocationFilterOptionCard(
    props: FilterOptionCardProps
) {
    return (
        <StringFilterOptionCard
            key={"locationFilterOptionCard"}
            name={"Location"}
            tableKey={"location"}
            employeeData={props.employeeData}
        />
    );
}
