import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { SxProps, Theme } from "@mui/material";
import {
  NumericFilterOptionConfig,
  NumericFilterOptionListConfig
} from "../../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import {EmployeeData} from "../../services/types";

// helper
import amplitude from "../../helper/amplitude";
import {FilterOption} from "../../sections/dashboard/filterOptions/types";
import {getFilterOptions, setReduxFilterOptions} from "../../helper/filter";
import {useDispatch, useSelector} from "react-redux";
import {FilterOptionsTypeReduxState} from "../../redux/reducers";
import {FilterOptionsTypeState} from "../../redux/reducers/filterOptionsTypeReducer";

function valuetext(value: number) {
  return `${value}°C`;
}

export type RangeSliderProps = {
  sx?: SxProps<Theme> | undefined;
  marks: Array<{ label?: string; value: number }> | boolean;
  min?: number | undefined;
  max?: number | undefined;
  employeeData: EmployeeData[];
  tableKey: keyof EmployeeData;
};

export default function RangeSlider(props: RangeSliderProps) {

  const dispatch = useDispatch();

  const filterOptionType = useSelector<FilterOptionsTypeReduxState, FilterOptionsTypeState>(
      (state: FilterOptionsTypeReduxState) => state.filterOptionsType
  ).filterOptionsType

  const sx = { ...{ width: "120%", marginLeft: 2.5, marginRight: 2.5 }, ...props.sx };

  const filterOptions: FilterOption[] = getFilterOptions();

  const setSliderMinValue = (): number => {
    if (props.min) {
      return props.min;
    }
    return Math.min(
        ...props.employeeData.map(function (emp) {
          return emp[props.tableKey] as number;
        })
    );
  };

  const setSliderMaxValue = (): number => {
    if (props.max) {
      return props.max;
    }
    return Math.max(
        ...props.employeeData.map(function (emp) {
          return emp[props.tableKey] as number;
        })
    );
  };

  const max = setSliderMaxValue();
  const min = setSliderMinValue();

  const setDefaultValues = (): Array<number> => {
    for(let i = 0; i < filterOptions.length; i++){
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      let item = filterOptions.at(i)!
      if (item.key === props.tableKey) {
        item = item as NumericFilterOptionListConfig;
        return [item.min, item.max];
      }
    }
    return [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      min,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      max,
    ];
  };


  const setNumericFilterOptionConfig = (
      conf: NumericFilterOptionConfig
  ) => {
    amplitude().track(`Set Filter For ${props.tableKey}`)
    const addItem = {
      key: props.tableKey,
      min: conf.min,
      max: conf.max,
    }
    for(let i = 0; i < filterOptions.length; i++){
      const item = filterOptions.at(i) as NumericFilterOptionListConfig;
      if(item.key === props.tableKey){
        filterOptions[i] = addItem;
        return;
      }
    }
    filterOptions.push(addItem)
  };

  let value = setDefaultValues();

  const handleChange = (event: Event, newValue: number | number[]) => {

    value = newValue as number[];
    if (typeof newValue !== "number") {
      setNumericFilterOptionConfig({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        min: newValue.at(0)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        max: newValue.at(1)!,
      });
    }
  };

  React.useEffect(() => {
    setReduxFilterOptions(filterOptions, filterOptionType, dispatch);
  }, [filterOptions])



  return (
    <Box sx={sx}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        marks={props.marks}
        min={min}
        max={max}
      />
    </Box>
  );
}
