import {Box, Grid, SxProps, Theme, Tooltip, Typography} from "@mui/material";
// import AppEmployeeHeatMap from "../components/app/AppEmployeHeatMap";
// import { xaxisOptions } from "../services/lineCharts";
import {getBackgroundColorByScore, getBorderColorByScore} from "../helper/colors";
import * as React from "react";
import {useSelector} from "react-redux";
import {TriggerOptionsReduxState} from "../redux/reducers";
import {TriggerOptionsState} from "../redux/reducers/triggerOptionsReducer";
import {icons} from "../components/TriggerIcon";
import {triggerTitles} from "../constants/triggerConfig/triggerInfo";

export type TriggerKPICardProps = {
    name: string;
    value: number;
    dataKey: keyof TriggerOptionsState;
    onClick?: ((arg0: string) => void) | undefined;
    sx?: SxProps<Theme> | undefined,
};

export default function TriggerKPICard(props: TriggerKPICardProps) {

    const triggerOptionsState = useSelector<TriggerOptionsReduxState, TriggerOptionsState>(
        (state: TriggerOptionsReduxState) => state.triggerOptions
    )

    if(triggerOptionsState[props.dataKey] === undefined){
        return <div/>;
    }

    const triggerDelta = props.value - triggerOptionsState[props.dataKey].red;

    const [cardBoxShadow, setCardBoxShadow] = React.useState(3);

    const disableHover = triggerDelta <= 0;

    const backgroundColor = getBackgroundColorByScore(disableHover ? 100 : 0)

    const fontColor = getBorderColorByScore(disableHover ? 100 : 0);

    const icon = icons.filter(icon => icon.dataKey == props.dataKey)[0]; //iconsByKey[props.dataKey];

    return (
        <Grid
            item
            key={"kpi-"+props.dataKey}
            xs={12}
            md={4}
            lg={3.8}
            sx={{
                float: "left",
                background: backgroundColor,
                height: 180,
                margin: 0.5,
                padding: 1,
                borderRadius: 1,
                boxShadow: cardBoxShadow,
                ':hover': !disableHover ? {
                    cursor: "pointer"
                } : undefined,
                ...props.sx
            }}
            onMouseOver={() => !disableHover ? setCardBoxShadow(10) : null}
            onMouseOut={() => !disableHover ? setCardBoxShadow(3) : null}
            onClick={!disableHover && (props.onClick) ? () => {
                props.onClick?.(props.dataKey)
            } : undefined}
        >
            <Typography
                variant="h4"
                gutterBottom
                style={{
                    color: "#0323f8",
                    marginLeft: 1,
                    fontWeight: 600,
                    fontSize: 13,
                }}
            >
                {props.name.toUpperCase()}
            </Typography>
            <Grid
                item
                xs={12}
                md={5}
                lg={8}
                sx={{
                    float: "left",
                    padding: 2,
                    marginLeft: 0,
                }}
            >
                <Grid container padding={3}>
                    <Grid item xs={6}>
                        <Box>
                            <Tooltip title={triggerTitles[icon.dataKey].subTitle}>
                                <icon.icon
                                    sx={{
                                        color: fontColor,
                                        height: 80,
                                        width: 80,
                                        marginLeft: -3,
                                        marginBottom: -1.8,
                                        marginTop: -1.8
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    style={{
                                        color: fontColor,
                                        fontWeight: 600,
                                        fontSize: 20,
                                    }}
                                    sx={{
                                        marginLeft: 8,
                                        width: 70,
                                        marginRight: 0
                                    }}
                                >
                                    {`${props.value} / ${triggerOptionsState[props.dataKey].red}`}
                                </Typography>
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        style={{
                                            color: fontColor,
                                            fontWeight: 600,
                                            fontSize: 9,
                                        }}
                                        sx={{
                                            marginLeft: 8,
                                            width: 70,
                                            marginRight: 0
                                        }}
                                    >
                                        actual    /   trigger
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
