import { faker } from "@faker-js/faker";
import { min, sample } from "lodash";
import {
  createHierarchy,
  getDistributionByDepartment,
  getHistChartData,
  getHistoricalMean,
  getMinMax, getRecommendedActions,
  getStatusScore,
  removeNameAddOns
} from "../helper/employeeData";
import { setMainTable } from "../redux/actions/mainTableActions";
import { EmployeeData, MainTableDoc } from "./types";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import {DataProcessingTypeState} from "../redux/reducers/dataProcessingTypeReducer";
import {TriggerOptionsState} from "../redux/reducers/triggerOptionsReducer";
import {MainTableState} from "../redux/reducers/mainTableReducer";

export const getTriggerStatus = (emp: EmployeeData, triggerOptionsState: TriggerOptionsState): number => {
  const triggered = Object.keys(triggerOptionsState).filter(
      triggerKey =>
          emp[triggerKey as keyof TriggerOptionsState] >
          triggerOptionsState[triggerKey as keyof TriggerOptionsState].red
  ).length

  if(triggered < 4){
    emp.status = 51
  }
  if(triggered >= 4){
    emp.status = 0
  }
  if(triggered < 3){
    emp.status = 100
  }
  return emp.status
}

export const setTriggerOrAiStatus = (
    dataProcessingTypeState: DataProcessingTypeState,
    triggerOptionsState: TriggerOptionsState,
    tableState: MainTableState,
    dispatch: Dispatch<AnyAction>
): MainTableState => {
  if(dataProcessingTypeState.type == "trigger"){
    ["employees", "departments", "subDepartments"].forEach((tableKey) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableState[tableKey].forEach(emp => {
        emp.status = getTriggerStatus(emp, triggerOptionsState);
      })
    })
  } else {
    ["employees", "departments", "subDepartments"].forEach((tableKey) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableState[tableKey].forEach(emp => {
        emp.status = emp.aiStatus
      })
    })
  }
  dispatch(setMainTable(tableState))
  return tableState
}

const createEmployee = (): EmployeeData => {
  const monetaryLossData = {
    investedTrainings: faker.datatype.number({ min: 15000, max: 30000 }),
    absence: faker.datatype.number({ min: 15000, max: 30000 }),
    replacement: faker.datatype.number({ min: 20000, max: 40000 }),
  };
  monetaryLossData.investedTrainings = Number(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      min([
    (monetaryLossData.absence + monetaryLossData.replacement) * 0.2,
    monetaryLossData.investedTrainings,
  ])!.toFixed(0));
  const employee: EmployeeData = {
    id: faker.datatype.uuid(),
    avatarUrl: "",
    name: removeNameAddOns(faker.name.fullName()),
    aiStatus: 0,
    status: 0, // sample(["active", "inactive", "paused"]),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    department: sample(["IT", "IT", "Sales", "Sales", "Marketing", "Finance", "HR"])!,
    subDepartment: "",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    role: sample([
      "Leader",
      "Hr Manager",
      "UI Designer",
      "UX Designer",
      "UI/UX Designer",
      "Project Manager",
      "Backend Developer",
      "Full Stack Designer",
      "Front End Developer",
      "Full Stack Developer",
    ])!,
    age: faker.datatype.number({ min: 30, max: 55 }),
    reportingLine: faker.name.findName(),
    salary: faker.datatype.number({ min: 35000, max: 80000 }),
    location: faker.address.cityName(),
    compensationScore: faker.datatype.number({ min: 20, max: 100 }),
    compensationScoreHistory: getHistChartData({ length: 7, ...getMinMax() }),
    personalDevelopmentScore: faker.datatype.number({ min: 20, max: 100 }),
    personalDevelopmentScoreHistory: getHistChartData({
      length: 7,
      ...getMinMax(),
    }),
    mustStay: faker.datatype.boolean(),
    engagementScore: faker.datatype.number({ min: 20, max: 100 }),
    engagementScoreHistory: getHistChartData({ length: 7, ...getMinMax() }),
    feedbackScore: faker.datatype.number({ min: 20, max: 100 }),
    feedbackScoreHistory: getHistChartData({ length: 7, ...getMinMax() }),
    socialsScore: faker.datatype.number({ min: 20, max: 100 }),
    socialsScoreHistory: getHistChartData({ length: 7, ...getMinMax() }),
    peerGroupScore: faker.datatype.number({ min: 20, max: 100 }),
    peerGroupScoreHistory: getHistChartData({ length: 7, ...getMinMax() }),
    monetaryLossData: monetaryLossData,
    projectedMonetaryLoss: 0,
    historicalScore: [],
    mustStayInfo: "",
    recommendedActions: [],
    salaryDevFromPeers: faker.datatype.number({ min: 3, max: 30 }),
    salaryGenderDevFromPeers: faker.datatype.number({ min: 3, max: 30 }),
    yearsUnpromoted: faker.datatype.number({ min: 0, max: 10 }),
    percentQuittingUnderLeader: faker.datatype.number({ min: 3, max: 30 }),
    percentQuittingPeers: faker.datatype.number({ min: 3, max: 30 }),
    percentMaleTeam: faker.datatype.number({ min: 3, max: 30 }),
    percentFemaleTeam: 0,
    teamAgeGap: faker.datatype.number({ min: 3, max: 30 }),
    sicknessDays: faker.datatype.number({ min: 3, max: 30 }),
  };
  employee.percentFemaleTeam = 100 - employee.percentMaleTeam;
  employee.historicalScore = getHistoricalMean(employee);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee.compensationScore = employee.compensationScoreHistory.at(6)!;
  employee.personalDevelopmentScore =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee.personalDevelopmentScoreHistory.at(6)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee.engagementScore = employee.engagementScoreHistory.at(6)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee.feedbackScore = employee.feedbackScoreHistory.at(6)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee.socialsScore = employee.socialsScoreHistory.at(6)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee.peerGroupScore = employee.peerGroupScoreHistory.at(6)!;
  employee.status = getStatusScore(employee);
  employee.projectedMonetaryLoss = Number(
    (
      (monetaryLossData.investedTrainings +
        monetaryLossData.replacement +
        monetaryLossData.absence) // * (1 - employee.status / 100)
    ).toFixed(2)
  );
  employee.recommendedActions = getRecommendedActions(employee);
  return employee;
};

export const createEmployeeData = (dispatch?: Dispatch<AnyAction>): MainTableDoc => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let data: EmployeeData[] = [...Array(2000)].map(() => createEmployee());
  data.forEach((item) => {
    item.status = getStatusScore(item);
  });
  data = getDistributionByDepartment(data);
  data.forEach(emp => {
    emp.aiStatus = emp.status
  })
  const hierarchyLeveledData = createHierarchy(data);
  hierarchyLeveledData.employees = hierarchyLeveledData.employees.sort((a, b) => {
    return a.status - b.status;
  });
  const mainTableDoc: MainTableDoc = {
    created_at: "2020-01-01",
    updated_at: "2020-01-01",
    ...hierarchyLeveledData,
  };
  if (dispatch) {
    dispatch(setMainTable(mainTableDoc));
  }
  return mainTableDoc;
  // localStorage.setItem(localStorage.MAIN_TABLE, JSON.stringify(mainTableDoc));
};