import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import {createEmployeeData} from "../../../services/employeeDataService";
import {useDispatch} from "react-redux";
import {login, setAuthToken} from "../../../services/authService";
import {getProjects} from "../../../services/projectService";
import {setProject} from "../../../redux/actions/projectActions";
import * as lsConstants from "../../../redux/constants/localStorage";

export type LoginFormProps = {
    setLoginInError: (arg0: boolean) => void;
}

export default function LoginForm(props: LoginFormProps) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const setReduxProject = async () => {
      await setAuthToken();
      const projects = await getProjects();
      dispatch(setProject(projects[0]))
      localStorage.setItem(lsConstants.PROJECT, projects[0].id);
  }

  const onSubmit = async () => {
    try{
        await login(dispatch, {email: methods.getValues("email"), password: methods.getValues("password")})
        await setReduxProject();
        await createEmployeeData(dispatch);
        navigate("/main/org_chart", { replace: true });
    } catch(e){
        console.log(e)
        props.setLoginInError(true);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" other={{}} />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    sx={{ width: 20, height: 20 }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me"/>
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ color: "#0323f8", fontWeight: 700 }}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        sx={{ background: "#0323f8" }}
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
