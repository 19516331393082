import {OrgChartTriggerIconsState} from "../reducers/orgChartTriggerIconsReducer";

export type OrgChartTriggerIconsAction = {
    type: string,
    payload: OrgChartTriggerIconsState
}

export const setOrgChartTriggerIcons = (orgChartTriggerIconsState: OrgChartTriggerIconsState) => {
    return {
        type: "LOADED_MAIN_TABLE",
        payload: orgChartTriggerIconsState,
    };
};