import {Box, Button, CircularProgress, Dialog, DialogTitle, Grid, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import {createInvite} from "../../../services/inviteService";
import {useSelector} from "react-redux";
import {ProjectReduxState} from "../../../redux/reducers";
import {ProjectState} from "../../../redux/reducers/projectReducer";

export type AddTeamMemberDialogProps = {
    open: boolean,
    setOpen: (arg0: boolean) => void;
}

export default function AddTeamMemberDialog (props: AddTeamMemberDialogProps){

    const projectState = useSelector<ProjectReduxState, ProjectState>(
        (state: ProjectReduxState) => state.project
    )

    const [email, setEmail] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const sendInvite = async () => {
        setIsLoading(true);
        try{
            await createInvite({
                project_id: projectState.id,
                target_email: email,
            })
            props.setOpen(!props.open)
        } catch(e){
            console.log(e)
        }
        setIsLoading(false);
    }

    return(
        <Dialog open={props.open}>
            <Box sx={{paddingLeft: 5, paddingTop: 5, paddingRight: -5}}>
                <DialogTitle>
                    <Typography sx={{marginTop: -4}}>
                        Invite Team Member
                    </Typography>
                </DialogTitle>
            </Box>
            <Stack direction={"column"} sx={{margin: 5}}>
                <TextField
                    id="outlined-multiline-flexible"
                    label="email"
                    multiline
                    maxRows={4}
                    value={email}
                    onChange={handleEmailChange}
                />
                <Grid container sx={{marginTop: 3}}>
                    <Grid item xs={8}>
                        <Button
                            //variant={"outlined"}
                            onClick={() => props.setOpen(!props.open)}
                        >Cancel</Button>
                    </Grid>
                    <Grid item xs={4}>
                        {isLoading ? <CircularProgress/> : <Button
                            variant={"contained"}
                            onClick={sendInvite}
                        >Send</Button>}
                    </Grid>
                </Grid>
            </Stack>
        </Dialog>
    )
}