export const getColorByScore = (score: number) => {
    if (score < 50) {
        return "#d81e34";
    }
    if (score > 75) {
        return "#0323f8";
    }
    return "#e0a950";
};

export const getBorderColorByScore = (score: number) => {
    if (score < 50) {
        return "#d81e34";
    }
    if (score > 75) {
        return "#54D62C";
    }
    return "#e0a950";
};

export const getBackgroundColorByScore = (score: number) => {
    if (score < 50) {
        return "#fef4f5";
    }
    if (score > 75) {
        return "#f8f6ff";
    }
    return "#fefbf6";
};

export const getActionBackgroundColorByStatus = (status: "open" | "pending" | "done") => {
    let score = 60;
    if(status == "open"){
        score = 0;
    }
    if(status == "done"){
        score = 100
    }


    if (score < 50) {
        return "#fef4f5";
    }
    if (score > 75) {
        return "rgba(234,250,216,0.44)";
    }
    return "#fefbf6";
};