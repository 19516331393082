import {TriggerOptionsState} from "../redux/reducers/triggerOptionsReducer";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {SvgIconTypeMap} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Female from "@mui/icons-material/Female";
import Male from "@mui/icons-material/Male";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

export type TriggerIconProps = {
    dataKey: string,
    color: string,
}

export const icons: Array<{dataKey: keyof TriggerOptionsState, icon: OverridableComponent<SvgIconTypeMap>}> = [
    {
        dataKey: "salaryDevFromPeers",
        icon: PaidIcon
    },
    {
        dataKey: "salaryGenderDevFromPeers",
        icon: CurrencyExchangeIcon
    },
    {
        dataKey: "yearsUnpromoted",
        icon: ArrowCircleUpIcon
    },
    {
        dataKey: "percentQuittingUnderLeader",
        icon: CancelPresentationIcon
    },
    {
        dataKey: "percentQuittingPeers",
        icon: HighlightOffIcon
    },
    {
        dataKey: "percentMaleTeam",
        icon: Male
    },
    {
        dataKey: "percentFemaleTeam",
        icon: Female
    },
    {
        dataKey: "teamAgeGap",
        icon: DateRangeIcon
    },
    {
        dataKey: "sicknessDays",
        icon: LocalHospitalIcon
    },
]

const iconsByKeyObj: {[key: string]: OverridableComponent<SvgIconTypeMap>} = {}
icons.map(icon => iconsByKeyObj[icon.dataKey] = icon.icon)

export const iconsByKey = iconsByKeyObj;