import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import account from "../../services/account";
import DataProcessingTypeSwitch from "../../components/DataProcessingTypeSwitch";
import {logout} from "../../services/authService";
import {useDispatch} from "react-redux";

export type AccountPopoverProps = {
  dataProcessingType: "trigger" | "ai",
  setDataProcessingType:  React.Dispatch<React.SetStateAction<"ai" | "trigger">>;
}

export default function AccountPopover(props: AccountPopoverProps) {

  const anchorRef = useRef(null);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const MENU_OPTIONS = [
    {
      label: "Home",
      icon: "eva:home-fill",
      linkTo: "/main/dashboard",
      onClick: undefined
    },
    {
      label: "Profile",
      icon: "eva:person-fill",
      linkTo: "#",
      onClick: undefined,
    },
    {
      label: "Settings",
      icon: "eva:settings-2-fill",
      linkTo: "#",
      onClick: undefined,
    },
    {
      label: "Team",
      icon: "eva:settings-2-fill",
      linkTo: "/main/team",
      onClick: undefined,
    },
    {
      label: "Decryption File",
      icon: "eva:settings-3-fill",
      linkTo: "/main/key_file",
      onClick: undefined,
    },
    {
      label: "Logout",
      icon: "eva:settings-3-fill",
      linkTo: "/login",
      onClick: () => logout(dispatch),
    },
  ];

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "21px",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src="/static/mock-images/avatars/avatar_3.jpg" alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 8,
          ml: -4,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={option.onClick ? option.onClick : handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: "dashed" }} />
        <DataProcessingTypeSwitch
          dataProcessingType={props.dataProcessingType}
          setDataProcessingType={props.setDataProcessingType}
        />
        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
    </>
  );
}
