import { FilterOptionsAction } from "../actions/filterOptionActions";
import {SET_FILTER_OPTIONS, SET_MUST_STAY_FILTER_OPTIONS} from "../constants/actionTypes";
import {FilterOption} from "../../sections/dashboard/filterOptions/types";

export type FilterOptionsState = {
  data: Array<FilterOption>;
};

export const initialState: FilterOptionsState = {
  data: [],
};

// ==============================|| FILTER OPTION REDUCER ||============================== //
export const filterOptionsReducer = (
  state: FilterOptionsState = initialState,
  action: FilterOptionsAction
): FilterOptionsState => {
  switch (action.type) {
    case SET_FILTER_OPTIONS: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export type MustStayFilterOptionsState = {
  data: Array<FilterOption>;
};

export const mustStayInitialState: MustStayFilterOptionsState = {
  data: [],
};

// ==============================|| FILTER OPTION REDUCER ||============================== //
export const mustStayFilterOptionsReducer = (
    state: FilterOptionsState = mustStayInitialState,
    action: FilterOptionsAction
): MustStayFilterOptionsState => {
  switch (action.type) {
    case SET_MUST_STAY_FILTER_OPTIONS: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};
