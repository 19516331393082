import * as lsConstants from "../constants/localStorage";
import * as actionTypes from "../constants/actionTypes";

export type ProjectState = {
  id: string;
};

export const initialState: ProjectState = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: localStorage.getItem(lsConstants.PROJECT)!,
};

type Action = {
  type: string;
  payload: ProjectState;
};

// ==============================|| PROJECT REDUCER ||============================== //
const projectReducer = (
  state: ProjectState = initialState,
  action: Action
): ProjectState => {
  switch (action.type) {
    case actionTypes.SET_PROJECT: {
      return { ...state, id: action.payload.id };
    }
    default: {
      return state;
    }
  }
};

export default projectReducer;
