// Taken from https://codesandbox.io/s/metrics-color-threshold-slider-reversed-o58nu6?file=/demo.js:0-3681
import React, { useState } from "react";
import {Slider, Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {TriggerOption, TriggerOptionsState} from "../../redux/reducers/triggerOptionsReducer";
import {setTriggerOptions} from "../../redux/actions/triggerOptionsActions";

type DiscreteSliderProps = {
    title: string,
    reverse: boolean,
    values: number,
    min: number,
    max: number,
    thresholdMarks: number[],
    thresholdMarksTitles: string[],
    valueText?: ((value: number) => string) | undefined,
    style?: object | undefined,
    setTriggerOption: (triggerOption: TriggerOption) => void;
    avg: number
}

function DiscreteSlider(props: DiscreteSliderProps) {
    const {
        reverse,
        values,
        min,
        max,
        avg,
        thresholdMarks,
        // thresholdMarksTitles,
        style,
        ...rest
    } = props;

    const [value, setValue] = useState(values);
    const [, setMarks] = useState(
        reverse ? thresholdMarks.map((val) => -val) : thresholdMarks
    );
    const [perc, setPerc] = useState([values]);

    const onChange = (e: Event, tvalue: number[] | number) => {
        tvalue = tvalue as number
        const [minVal, maxVal] = [0, tvalue];
        if (maxVal > minVal && maxVal !== minVal) {
            setValue(tvalue);
            if (!reverse) {
                setMarks([
                    parseInt(String((min + minVal) / 2), 10),
                    parseInt(String((minVal + maxVal) / 2), 10),
                    parseInt(String((maxVal + max) / 2), 10)
                ]);
                setPerc([tvalue].map((val) => (val / max) * 100));
            } else {
                setMarks([
                    parseInt(String((-max + minVal) / 2), 10),
                    parseInt(String((minVal + maxVal) / 2), 10),
                    parseInt(String((maxVal + -min) / 2), 10)
                ]);
                setPerc(
                    [tvalue].map((val) => parseInt(String((1 - Math.abs(val / max)) * 100)))
                );
            }
        }
        props.setTriggerOption({yellow: tvalue, red: tvalue})
    };

    return (
            <Slider
                sx={{
                    "& .MuiSlider-track": {
                        background: "green",
                        borderColor: "white"
                    },
                    "& .MuiSlider-thumb": {
                        // [`&:nth-of-type(${1}n)`]: {
                        //     background: "green",
                        //     "& span": {
                        //         background: "green"
                        //     },
                        //     fontColor: "black"
                        // },
                        [`&:nth-of-type(${1}n)`]: {
                            background: "red",
                            "& span": {
                                background: "red",
                                fontColor: "black"
                            },
                        }
                    },
                    "& .MuiSlider-mark": {
                        background: "none"
                    },
                    "& .MuiSlider-rail": {
                        background: `linear-gradient(to left, red 0% ${perc[0]}%, red ${perc[0]}% 100%)`
                    },
                    "& .MuiSlider-valueLabel": {},
                    ...style
                }}
                valueLabelDisplay="on"
                valueLabelFormat={props.valueText}
                value={value}
                min={reverse ? -max : min}
                max={reverse ? -min : max}
                scale={(x) => (reverse ? -x : x)}
                marks={[
                    { value: reverse ? -max : min, label: reverse ? max : min },
                    // ...marks.map((val, idx) => ({
                    //     value: val,
                    //     label: thresholdMarksTitles[idx]
                    // })),
                    {
                        value: parseInt(avg.toString()),
                        label: (
                            props.valueText ?
                                props.valueText(Number(parseInt(avg.toString()))) :
                                Number(parseInt(avg.toString()))
                        ) +" avg"
                    },
                    { value: reverse ? -min : max, label: reverse ? min : max }
                ]}
                onChange={onChange}
                // disabled
                {...rest}
            />
    );
}

export type ColorSliderProps = {
    marks: number[],
    valueText?: ((value: number) => string) | undefined,
    defaultValues: number[],
    min: number,
    max: number,
    dataKey: keyof TriggerOptionsState;
    reverse?: boolean | undefined,
    avg: number,
}

export default function ColorSlider(props: ColorSliderProps) {

    const dispatch = useDispatch();

    const setTriggerOption = (triggerOption: TriggerOption): void => {
        const set: {[key: string]: TriggerOption} = {}
        set[String(props.dataKey)] = triggerOption
        dispatch(setTriggerOptions(set))
    }

    return (
        <Grid item xs={12} sx={{paddingTop: 3}}>
            <DiscreteSlider
                title="Reverse custom range"
                reverse={Boolean(props.reverse)}
                avg={props.avg}
                // step={1}
                values={props.defaultValues[1]}
                min={props.min}
                max={props.max}
                thresholdMarks={props.marks}
                thresholdMarksTitles={["G", "Y", "R"]}
                setTriggerOption={setTriggerOption}
                valueText={props.valueText}
            />
        </Grid>
    );
}