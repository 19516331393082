import {Button, CircularProgress, Container, Stack} from "@mui/material";
import {Page} from "../components/Page";
import Typography from "@mui/material/Typography";
import * as React from "react";
import TeamTable from "../sections/dashboard/teamManagement/teamTable";
import {useSelector} from "react-redux";
import {ProjectReduxState} from "../redux/reducers";
import {ProjectState} from "../redux/reducers/projectReducer";
import {getPermissions, Permission} from "../services/permissionService";
import AddTeamMemberDialog from "../sections/dashboard/teamManagement/AddTeamMemberDialog";

export default function TeamManagement(){

    const projectState = useSelector<ProjectReduxState, ProjectState>(
        (state: ProjectReduxState) => state.project
    )

    const [userPermissions, setUserPermissions] = React.useState<Array<Permission>>([]);

    const [addTeamMemberDialogOpen, setAddTeamMemberDialogOpen] = React.useState(false);

    const retrieveProjectPermissions = async() => {
        const permissions = await getPermissions({project_id: projectState.id,})
        setUserPermissions(
            permissions
        )
    }

    React.useEffect( () => {
        retrieveProjectPermissions();
    }, [projectState])

    return(
        <>
            <Page title="Home">
                <Container maxWidth="xl">
                    <AddTeamMemberDialog
                        open={addTeamMemberDialogOpen}
                        setOpen={setAddTeamMemberDialogOpen}
                    />
                    <Stack
                        direction="row"
                        alignItems="center"
                        // justifyContent="space-between"
                    >
                        <Typography
                            variant="h4"
                            gutterBottom
                            style={{
                                color: "#0323f8",
                                marginLeft: 20,
                                fontWeight: 600,
                                fontSize: 16,
                            }}
                        >
                            Team
                        </Typography>
                        <Button
                            sx={{
                                marginLeft: 140,
                                marginBottom: 0.5
                            }}
                            onClick={() => setAddTeamMemberDialogOpen(true)}
                        >Add</Button>
                    </Stack>
                    {
                        userPermissions.length > 0 ? <TeamTable teamMembers={userPermissions}></TeamTable>
                            : <CircularProgress/>
                    }
                </Container>
            </Page>
        </>
    )
}