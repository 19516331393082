import { format, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------
type Props = {
  date: Date;
};

export function fDate(props: Props) {
  return format(new Date(props.date), "dd MMMM yyyy");
}

export function fDateTime(props: Props) {
  return format(new Date(props.date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(props: Props) {
  return format(new Date(props.date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(props: Props) {
  return formatDistanceToNow(new Date(props.date), {
    addSuffix: true,
  });
}
