import {Container, Grid, List} from "@mui/material";
import {Page} from "../components/Page";
import Typography from "@mui/material/Typography";
import * as React from "react";
import SliderSet from "../sections/dashboard/triggerConfig/SliderSet";
import {SliderListItemProps} from "../layouts/dashboard/triggerConfig/SliderListItem";
import {useSelector} from "react-redux";
import {MainTableReduxState, TriggerOptionsReduxState} from "../redux/reducers";
import {TriggerOptionsState} from "../redux/reducers/triggerOptionsReducer";
import {MainTableState} from "../redux/reducers/mainTableReducer";
import {meanBy} from "lodash";

export default function TriggerConfig() {

    const tableState = useSelector<MainTableReduxState, MainTableState>(
        (state: MainTableReduxState) => state.mainTable
    );

    const triggerOptionsState = useSelector<TriggerOptionsReduxState, TriggerOptionsState>(
        (state: TriggerOptionsReduxState) => state.triggerOptions
    )

    const getDefaults = (dataKey: keyof TriggerOptionsState): number[] | undefined => {
        if(triggerOptionsState[dataKey]){
            return [triggerOptionsState[dataKey].yellow, triggerOptionsState[dataKey].red]
        }
        return;
    }

    const departmentTriggers: SliderListItemProps[] = [
        {
            title: "Team gender diversity",
            dataKey: "percentMaleTeam",
            subTitle: "What is the maximum percentage of men you allow to be in a team?",
            defaultValues: getDefaults("percentMaleTeam") || [30, 10],
            marks: [10, 20, 30, 50, 75],
            min: 0,
            max: 100,
            avg: meanBy(tableState.employees, "percentMaleTeam"),
            valueText: (value: number) => `${value}%`,
            // infoLeft: "Female",
            // infoRight: "Male"
        },
        {
            title: "Team gender diversity",
            dataKey: "percentMaleTeam",
            subTitle: "What is the maximum percentage of women you allow to be in a team?",
            defaultValues: getDefaults("percentMaleTeam") || [30, 10],
            marks: [10, 20, 30, 50, 75],
            min: 0,
            max: 100,
            avg: meanBy(tableState.employees, "percentMaleTeam"),
            valueText: (value: number) => `${value}%`,
            // infoLeft: "Female",
            // infoRight: "Male"
        },
        {
            title: "Team age gap",
            subTitle: "How much deviation from the average team age do you allow?",
            dataKey: "teamAgeGap",
            defaultValues: getDefaults("teamAgeGap") || [10, 20],
            marks: [10, 20, 30, 40],
            min: 0,
            max: 40,
            valueText: (value: number) => `${value} years`,
            avg: meanBy(tableState.employees, "teamAgeGap"),
        },
    ]

    const leaderTriggers: SliderListItemProps[] = [
        {
            title: "Percent quitting under Leader",
            subTitle: "What's the maximum percentage of quitters under a leader?",
            dataKey: "percentQuittingUnderLeader",
            defaultValues: getDefaults("percentQuittingUnderLeader") || [10, 20],
            marks: [10, 20, 30, 50, 75],
            min: 0,
            max: 100,
            valueText: (value: number) => `${value}%`,
            avg: meanBy(tableState.employees, "percentQuittingUnderLeader"),
        },
    ]

    const peopleTriggers: SliderListItemProps[] = [
        {
            title: "Below Average Salary compared to peers",
            subTitle: "What is the maximum percentage a individual can earn less than " +
                "someone in a comparable position before you want to be alerted?",
            dataKey: "salaryDevFromPeers",
            defaultValues: getDefaults("salaryDevFromPeers") || [10, 20],
            marks: [10, 20, 30, 50, 75],
            min: 0,
            max: 100,
            valueText: (value: number) => `${value}%`,
            avg: meanBy(tableState.employees, "salaryDevFromPeers"),
        },
        {
            title: "Below Average Salary (based on gender) compared to peers",
            subTitle: "How much pay gap between male and female employees in " +
                "comparable positions do you consider acceptable?",
            dataKey: "salaryGenderDevFromPeers",
            defaultValues: getDefaults("salaryGenderDevFromPeers") || [10, 20],
            marks: [10, 20, 30, 50, 75],
            min: 0,
            max: 100,
            valueText: (value: number) => `${value}%`,
            avg: meanBy(tableState.employees, "salaryGenderDevFromPeers"),
        },
        {
            title: "Years unpromoted",
            subTitle: "How many years without a promotion of a single employee do " +
                "you consider acceptable before you would like to be notified?",
            dataKey: "yearsUnpromoted",
            defaultValues: getDefaults("yearsUnpromoted") || [1, 3],
            marks: [1, 2, 3, 4, 5, 10],
            min: 0,
            max: 10,
            valueText: (value: number) => `${value} years`,
            avg: meanBy(tableState.employees, "yearsUnpromoted"),
        },
        {
            title: "Percent peers quitting",
            subTitle: "How big a percentage of a team can quit before we should flag it?",
            dataKey: "percentQuittingPeers",
            defaultValues: getDefaults("percentQuittingPeers") || [10, 20],
            marks: [10, 20, 30, 50, 75],
            min: 0,
            max: 100,
            valueText: (value: number) => `${value}%`,
            avg: meanBy(tableState.employees, "percentQuittingPeers"),
        },
        {
            title: "Sickness days",
            subTitle: "How many sick days can a single employee have before you would like to be notified?",
            dataKey: "sicknessDays",
            defaultValues: getDefaults("sicknessDays") || [30, 70],
            marks: [50, 100, 150, 200],
            min: 0,
            max: 200,
            valueText: (value: number) => `${value} days`,
            avg: meanBy(tableState.employees, "sicknessDays"),
        }
    ]

    return (
        <>
            <Page title="Home">
                <Container maxWidth="xl">
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            color: "#0323f8",
                            marginLeft: 20,
                            fontWeight: 600,
                            fontSize: 20,
                        }}
                    >
                        Specify the thresholds you want to flag
                    </Typography>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            color: "#0323f8",
                            marginLeft: 20,
                            fontWeight: 600,
                            fontSize: 12,
                        }}
                    >
                        You will see the results in the org-chart and individual profiles
                    </Typography>
                    <Grid container sx={{padding: 2}}>
                        <List>
                            <SliderSet title={"People"} triggers={peopleTriggers}/>
                            <SliderSet title={"Leaders"} triggers={leaderTriggers}/>
                            <SliderSet title={"Department/Team"} triggers={departmentTriggers}/>
                        </List>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}


