import TrafficStatus from "../components/TrafficStatus";
import Box from "@mui/material/Box";
import * as React from "react";
import { getStatusFromScore } from "../helper/employeeData";
import { Grid } from "@mui/material";

export type TrafficStatusBarProps = {
  status: number;
  sx?: object;
};

export default function TrafficStatusBar(props: TrafficStatusBarProps) {
  const status = getStatusFromScore(props.status);
  return (
    <Box
      sx={{
        width: "fit-content",
        border: "solid 1px #989696",
        borderRadius: 10,
        padding: 0.2,
        backgroundColor: "#ffffff",
        ...props.sx,
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TrafficStatus
            variant="filled"
            color={(status === "active" && "success") || "custcolor"}
          />
        </Grid>
        <Grid item xs={4}>
          <TrafficStatus
            variant="filled"
            color={(status === "paused" && "warning") || "custcolor"}
          />
        </Grid>
        <Grid item xs={4}>
          <TrafficStatus
            variant="filled"
            color={(status === "inactive" && "error") || "custcolor"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
