import { Page } from "../components/Page";
import {useDispatch, useSelector} from "react-redux";
import { MainTableState } from "../redux/reducers/mainTableReducer";
import {buildHierarchy, Hierarchy} from "../helper/hierarchy";
import HierarchicalOrganigram from "../sections/dashboard/organigram/HierarchicalOrganigram"
import React from "react";
import {prepDepartments} from "../helper/organigram";
import {EmployeeData} from "../services/types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../src/css/toastify.css";
import {setMainTable} from "../redux/actions/mainTableActions";
import {Grid} from "@mui/material";
import LabeledSwitch from "../components/LabeledSwitch";
import {
    OrgChartTriggerIconsReduxState,
} from "../redux/reducers";
import {OrgChartTriggerIconsState} from "../redux/reducers/orgChartTriggerIconsReducer";
import {setOrgChartTriggerIcons} from "../redux/actions/orgChartTriggerIconsActions";
import {SHOW_ORG_CHART_TRIGGER_ICONS} from "../redux/constants/localStorage";

const setHierarchicalData = (
    hierarchicalEmployeeData: Hierarchy,
    tableState: MainTableState,
    level: string,
): Hierarchy => {
    if(level == "overview"){
        hierarchicalEmployeeData = prepDepartments(
            tableState.employees,
            tableState.departments,
            tableState.subDepartments,
            hierarchicalEmployeeData,
        )
    }
    // if(dataProcessingTypeState.type == "trigger"){
    //     Object.keys(hierarchicalEmployeeData).forEach(key => {
    //         if(key == "HR"){
    //             console.log("lol", hierarchicalEmployeeData[key])
    //             console.log(triggerOptionState)
    //             hierarchicalEmployeeData[key].status = getTriggerStatus(hierarchicalEmployeeData[key], triggerOptionState)
    //         }
//
    //     })
    // }
    return hierarchicalEmployeeData;
}

export type OrganigramProps = {
    tableState: MainTableState
}

export default function Organigram(props: OrganigramProps) {

    const dispatch = useDispatch()

    const level = "overview";

    const hierarchicalEmployeeData = setHierarchicalData(
        buildHierarchy(props.tableState.employees),
        props.tableState,
        level,
    )

    const [toastedInfo, setToastedInfo] = React.useState(false);

    const [showTriggerIcons, setShowTriggerIcons] = React.useState(
        useSelector<OrgChartTriggerIconsReduxState, OrgChartTriggerIconsState>(
            (state: OrgChartTriggerIconsReduxState) => state.orgChartTriggerIcons
        ).show
    );

    React.useEffect(() => {
        if(!toastedInfo){
            toast.info("Drag and pull, don't move and scroll! ;)");
            setToastedInfo(true);
        }
    }, [toastedInfo])

    const handleSetMustStay = (emp: EmployeeData) => {
        return (mustStayInfo: string, mustStayState?: boolean | undefined) => {
            props.tableState.employees.forEach((employee, index) => {
                if (employee.id === emp.id) {

                    let newState;
                    if(mustStayState !== undefined) {
                        newState = mustStayState;
                    } else {
                        newState = !emp.mustStay;
                    }

                    emp.mustStay = newState;
                    props.tableState.employees[index].mustStay = newState;
                    hierarchicalEmployeeData[employee.id].mustStay = newState;

                    emp.mustStayInfo = mustStayInfo;
                    props.tableState.employees[index].mustStayInfo = mustStayInfo;
                    hierarchicalEmployeeData[employee.id].mustStayInfo = mustStayInfo;
                    return;
                }
            });
            dispatch(setMainTable(props.tableState))
        }
    }
    
    return (
        <>
            <Page title="Home">
                <Grid container>
                    <Grid item>
                        <LabeledSwitch
                            label={showTriggerIcons ? "show triggers" : "hide triggers"}
                            onClick={() => {
                                setShowTriggerIcons(!showTriggerIcons)
                                dispatch(setOrgChartTriggerIcons({show: !showTriggerIcons}))
                                localStorage.setItem(SHOW_ORG_CHART_TRIGGER_ICONS, (!showTriggerIcons).toString())
                            }}
                            defaultValue={showTriggerIcons}
                        />
                    </Grid>
                </Grid>
               <HierarchicalOrganigram
                   hierarchy={hierarchicalEmployeeData}
                   handleSetMustStay={handleSetMustStay}
                   showTriggerIcons={showTriggerIcons}
               />
                <ToastContainer
                    position="top-center"
                    autoClose={5000} theme={"light"}
                />
            </Page>
        </>
    );
}
