import * as React from "react";
// mui
import {
  Stack,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// helper
import amplitude from "../helper/amplitude";
// -------------------------------------------------------- //

import SearchNotFound from "../components/SearchNotFound";
import EmployeeDetails from "../pages/EmployeeDetailsPopover";
import EnhancedTableHead, {PossibleTableHeadColumns} from "./dashboard/employee/EnhancedTableHead";
import { SortingOrder } from "../interfaces/interface.sortingOrder";
import TrafficStatusBar from "../sections/TrafficStatusBar";
import { EmployeeData } from "../services/types";
import { applySortFilter, getComparator } from "../helper/employeeTable";
import { useSelector } from "react-redux";
import { MainTableReduxState } from "../redux/reducers";
import { MainTableState } from "../redux/reducers/mainTableReducer";
import { NumericFilterOptionListConfig } from "../layouts/dashboard/filterOptions/NumericFilterOptionCard";
import { StringFilterOptionListConfig } from "../layouts/dashboard/filterOptions/StringFilterOptionCard";
import { StringQueryFilterOptionConfig } from "./dashboard/employee/UserListToolbar";
import {moneyFormatter} from "../helper/charts";
import {HeadCell} from "./dashboard/types";
import MustStayDialog from "../layouts/dashboard/MustStayDialog";
import {AddCircle, AddCircleOutline} from "@mui/icons-material";
import {getActionBackgroundColorByStatus} from "../helper/colors";



const headCells: readonly HeadCell[] = [
  { id: "name", disablePadding: true, label: "Name", numeric: false },
  { id: "age", disablePadding: true, label: "Age", numeric: false },
  {
    id: "reportingLine",
    disablePadding: true,
    label: "Reporting Line",
    numeric: false,
  },
  {
    id: "department",
    disablePadding: true,
    label: "Department",
    numeric: false,
  },
  { id: "role", disablePadding: true, label: "Role", numeric: false },
  {
    id: "projectedMonetaryLoss",
    disablePadding: true,
    label: "Replacement Cost",
    numeric: false,
  },
  { id: "status", disablePadding: true, label: "WeTain Score", numeric: false },
  { id: "mustStay", disablePadding: true, label: "Must Stay", numeric: false },
];

export type EmployeeTableProps = {
  employeeSelector?: (employee: EmployeeData[]) => EmployeeData[];
  hierarchyLevel: "employees" | "departments" | "sub departments";
  filterOptions: Array<
    | NumericFilterOptionListConfig
    | StringFilterOptionListConfig
    | StringQueryFilterOptionConfig
  >;
};

export default function EmployeeTable(props: EmployeeTableProps) {
  const tableDataState = useSelector<MainTableReduxState, MainTableState>(
    (state: MainTableReduxState) => state.mainTable
  );

  const tableData = () => {
    switch (props.hierarchyLevel){
      case "departments": {
        return tableDataState.departments;
      }
      case "sub departments": {
        return tableDataState.subDepartments;
      }
      default: {
        if (props.employeeSelector) {
          return(props.employeeSelector(tableDataState.employees));
        }
        return tableDataState.employees;
      }
    }
  }

  const [order, setOrder] = React.useState<SortingOrder>("asc");

  const [orderBy, setOrderBy] =
    React.useState<PossibleTableHeadColumns>("status");

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [employeeOpen, setEmployeeOpen] = React.useState(false);

  const [mustStayDialogOpen, setMustStayDialogOpen] = React.useState(false);

  const [employeeDetailsData, setEmployeeDetailsData] =
    React.useState<EmployeeData>(tableDataState.employees[0]);

  const clickRow = (employeeData: EmployeeData) => {
    amplitude().track('Employee Details Clicked');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setEmployeeOpen(true);
    setEmployeeDetailsData(employeeData);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: PossibleTableHeadColumns
  ) => {
    amplitude().track(`Set Employee Table Order by ${property}`);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const filteredEmployees = applySortFilter(
      tableData(),
      getComparator(order, orderBy),
      props.filterOptions
  )

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredEmployees.length)
      : 0;

  const isUserNotFound = filteredEmployees.length === 0;

  const handleSetMustStay = (row: EmployeeData) => {
    return (mustStayInfo: string, mustStayState?: boolean | undefined) => {
      tableData().forEach((employee) => {
        if (employee.id === row.id) {
          if(mustStayState){
            row.mustStay = mustStayState;
          } else {
            row.mustStay = !row.mustStay;
          }
          row.mustStayInfo = mustStayInfo;
          return;
        }
      });
    }
  }

  return (
    <>
      {mustStayDialogOpen ? <MustStayDialog
          open={mustStayDialogOpen}
          setOpen={setMustStayDialogOpen}
          handleSetMustStay={handleSetMustStay(employeeDetailsData)}
          employeeData={employeeDetailsData}
      /> : null}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {filteredEmployees
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const {
                  id,
                  name,
                  role,
                  department,
                  avatarUrl,
                  reportingLine,
                  age,
                  projectedMonetaryLoss,
                } = row;
                const isItemSelected = selected.indexOf(name) !== -1;
                const actionStateColor = () => {
                  if(row.status > 75){
                    return undefined;
                  }
                  const available = row.recommendedActions.filter(act => act.status != "open")
                  let actionState: "open" | "pending" | "done";
                  if(available.length > 0){
                    actionState = available[0].status
                  } else {
                    actionState = "open"
                  }
                  return getActionBackgroundColorByStatus(actionState);
                }
                return (
                  <TableRow
                    hover={true}
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    onClick={() => clickRow(row)}
                    sx={{"&:hover": { cursor: "pointer"}, backgroundColor: actionStateColor()}}
                  >
                    <TableCell padding="checkbox" />
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} src={avatarUrl} />
                        <Typography variant="subtitle2" noWrap sx={{padding: 3,}}>
                          {name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{age}</TableCell>
                    <TableCell align="left">{reportingLine}</TableCell>
                    <TableCell align="left">{department}</TableCell>
                    <TableCell align="left">{role}</TableCell>
                    <TableCell align="left">{moneyFormatter(projectedMonetaryLoss)}</TableCell>
                    <TableCell align="left">
                      <TrafficStatusBar status={row.status}/>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "#0323f8",
                          borderColor: "#0323f8",
                          padding: "2px 15px",
                          borderRadius: "8px",
                          marginLeft: -2
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setEmployeeDetailsData(row);
                          setMustStayDialogOpen(true)
                        }}
                      >
                        {row.mustStay ? <AddCircle /> : <AddCircleOutline />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={filteredEmployees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <EmployeeDetails
        isOpen={employeeOpen}
        setModalWindow={setEmployeeOpen}
        employeeData={
          employeeDetailsData ? employeeDetailsData : tableData()[0]
        }
        setData={setEmployeeDetailsData}
        handleSetMustStay={handleSetMustStay(employeeDetailsData)}
      />
    </>
  );
}
