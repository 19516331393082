export const xaxisOptions: ApexXAxis = {
  type: "datetime",
  categories: [
    "2022-01-01",
    "2022-04-01",
    "2022-07-01",
    "2022-10-01",
    "2023-01-01",
    "2023-04-01",
    "2023-07-01",
  ],
};
