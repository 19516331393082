// import * as lsConstants from "../constants/localStorage";
// import * as actionTypes from "../constants/actionTypes";
import { MainTableDoc } from "../../services/types";
import { MainTableAction } from "../actions/mainTableActions";
import { LOADED_MAIN_TABLE } from "../constants/actionTypes";
import { createEmployeeData } from "../../services/employeeDataService";

export type MainTableState = MainTableDoc;

export const initialState: MainTableState = {
  created_at: "now",
  updated_at: "now",
  employees: [],
  departments: [],
  subDepartments: [],
};
//     JSON.parse(
//   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//   localStorage.getItem(lsConstants.MAIN_TABLE)!
// );

// ==============================|| PROJECT REDUCER ||============================== //
const mainTableReducer = (
  state: MainTableState = initialState,
  action: MainTableAction
): MainTableState => {
  switch (action.type) {
    case LOADED_MAIN_TABLE: {
      return { ...state, ...action.payload };
    }
    default: {
      if (state.employees.length === 0) {
        return createEmployeeData();
      }
      return state;
    }
  }
};

export default mainTableReducer;
