import {EmployeeData} from "../services/types";
import {sum} from "lodash";

export type HierarchicalEmployeeData = {
    subEmployees: Array<string>,  // Array<HierarchicalEmployeeData>
    numTotalSubEmployees: number,
    type?: string | undefined
} & EmployeeData

export type Hierarchy = {
    [id: string]: HierarchicalEmployeeData
}

export const countChildren = (item: HierarchicalEmployeeData, hierarchicalEmployeeData: Hierarchy): number => {
    if(item.subEmployees){
        return (
            item.subEmployees.length
            + sum(item.subEmployees.map(
                empId => countChildren(
                    hierarchicalEmployeeData[empId], hierarchicalEmployeeData
                ))
            )
        )
    }
    return 0;
}

export const populateChildren = (currentItem: HierarchicalEmployeeData, hierarchicalEmployeeData: Hierarchy) => {
    const { name } = currentItem;
    Object.keys(hierarchicalEmployeeData).forEach((empId) => {
        const item = hierarchicalEmployeeData[empId];
        if (item.reportingLine === name) {
            if (!(currentItem).subEmployees) {
                (currentItem).subEmployees = [];
            }
            currentItem.subEmployees.push(empId);
        }
    });
    currentItem.subEmployees = Array.from(new Set(currentItem.subEmployees));
}

export function buildHierarchy(
    employeeData: EmployeeData[],
): Hierarchy {
    const hierarchicalEmployeeData: Hierarchy = {}
    employeeData.forEach(emp => {
        hierarchicalEmployeeData[emp.id as typeof emp.id] = emp as HierarchicalEmployeeData;
    })
    hierarchicalEmployeeData["company"] = {name: "company", id: "company"} as HierarchicalEmployeeData;

    Object.keys(hierarchicalEmployeeData).forEach((empId: string) => {
        populateChildren(hierarchicalEmployeeData[empId], hierarchicalEmployeeData);
        hierarchicalEmployeeData[empId].numTotalSubEmployees = countChildren(
            hierarchicalEmployeeData[empId], hierarchicalEmployeeData
        );
    });
    return hierarchicalEmployeeData;
}