import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export interface RHFTextFieldProp {
  name: string;
  label: string;
  type?: string;
  InputProps?: object;
  other: object;
  showPassword?: boolean;
}

export default function RHFTextField(props: RHFTextFieldProp) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          label={props.label}
          type={props.type}
          value={
            typeof field.value === "number" && field.value === 0
              ? ""
              : field.value
          }
          error={!!error}
          InputProps={props.InputProps}
          helperText={error?.message}
          {...props.other}
        />
      )}
    />
  );
}
