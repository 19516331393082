import {Box, Grid, Typography} from "@mui/material";
import * as React from "react";
import ColorSlider from "../../../components/triggerConfig/ColorSlider";
import {TriggerOptionsState} from "../../../redux/reducers/triggerOptionsReducer";

export type SliderListItemProps = {
    title: string,
    subTitle?: string | undefined,
    defaultValues: number[],
    valueText?: ((value: number) => string) | undefined,
    dataKey: keyof TriggerOptionsState,
    marks: number[],
    min: number,
    max: number,
    infoLeft?: string | undefined,
    infoRight?: string | undefined,
    avg: number,
}

// const subTitleLines = (subTitle: string | undefined) => {
//     if(subTitle === undefined){
//         return;
//     }
//     const subTitles = [];
//     let start = 0;
//     for(let i = 0; i < subTitle.length; i++){
//         if(i % 40 == 0){
//             subTitles.push(
//                 <Typography
//                     variant="h5"
//                     gutterBottom
//                     style={{
//                         color: "#0323f8",
//                         marginLeft: 20,
//                         fontWeight: 600,
//                         fontSize: 12,
//                     }}
//                 >
//                     {subTitle.slice(start, start+ 40)}
//                 </Typography>
//             )
//             start = i;
//         }
//     }
//     return subTitles;
// }

export default function SliderListItem(props: SliderListItemProps) {

    return (
        <Box sx={{padding: 5, border: 1, borderRadius: 3, marginBottom: 2}}>
            {/*<Typography
                variant="h4"
                gutterBottom
                style={{
                    color: "#0323f8",
                    marginLeft: 0,
                    fontWeight: 600,
                    fontSize: 14,
                    marginBottom: 4
                }}
            >
                {props.title}
            </Typography>*/}
            <Box width={600} sx={{marginLeft: 0,}}>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{wordBreak: "break-word"}}
                    style={{
                        color: "#0323f8",
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    {props.subTitle}
                </Typography>
            </Box>

            <Box sx={{ width: 700, paddingTop: 3, marginLeft: 0}}>
                <Grid container spacing={0}>
                    <Grid item xs={1.5} sx={{paddingRight: 0}}>
                        {props.infoLeft}
                    </Grid>
                    <ColorSlider
                        {...props}
                    />
                    <Grid item xs={1.5} sx={{marginLeft: 2}}>
                        {props.infoRight}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}