import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TrafficLight from 'react-trafficlight';
import {getBorderColorByScore} from "../helper/colors";
import {Box} from "@mui/material";

export type TrafficLightProps = {
    status: number
}

const BasicTrafficLight = (props: TrafficLightProps) => {
    const greenOn = props.status >= 75;
    const redOn = props.status < 50;
    const yellowOn = (props.status < 75) && (props.status >= 50)

    return (
        <Box sx={{border: 0.3, borderWidth: 1, borderRadius: 2, paddingTop: 1}} >
            <div style={{marginTop: -8, marginBottom: -8}}>
                <TrafficLight
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    GreenColor={getBorderColorByScore(props.status)}
                    YellowColor={getBorderColorByScore(props.status)}
                    RedColor={getBorderColorByScore(props.status)}
                    GreenOn={greenOn}
                    RedOn={redOn}
                    YellowOn={yellowOn}
                    BlackColor={"#ffffff"}
                    DisabledColor={"#d2d2d2"}
                    Horizontal
                />
            </div>
        </Box>
    );
}


export default BasicTrafficLight;